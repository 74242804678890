<div class="gradient-slider__wrapper">
    <div class="gradient-slider__bg"></div>
    <div #slider class="gradient-slider">
        <div
            class="gradient-slider__container"
            [class.gradient-slider__container--drag]="drag"
            (click)="jumpToValue($event)"
        >
            <div class="gradient-slider__bar"></div>
            <div #stub class="gradient-slider__stub"></div>
            <div
                #handle
                class="gradient-slider__handle"
                (mousedown)="startHandle($event)"
                (touchstart)="startTouchHandle($event)"
                (click)="$event.stopPropagation()"
            ></div>
        </div>
    </div>
</div>
