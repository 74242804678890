import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { SourceImpact } from '@cityair/modules/impact/service/api-model-impact';
import {
    ImpactActions,
    selectActiveRun,
    selectActiveSources,
} from '@cityair/modules/impact/store/impact.feature';
import { MAIN_PAGES } from '@libs/common';
import { TEXTS } from '@libs/common';
import { Store } from '@ngrx/store';

@Component({
    selector: 'ca-impact-sources',
    templateUrl: 'impact-sources.component.html',
    styleUrls: ['impact-sources.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactSourcesComponent {
    sources$ = this.store.select(selectActiveSources);
    textImpact = TEXTS.IMPACT;
    activeRun$ = this.store.select(selectActiveRun);

    readonly mainComponentUrl = `/${MAIN_PAGES.impact}/`;

    constructor(public store: Store, private router: Router) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.sources }));
    }

    goToSource(source: SourceImpact) {
        this.router.navigate([`${this.mainComponentUrl}${IMPACT_PAGES.sources}/${source.id}`]);
    }
}
