export enum GroupExtConfigName {
    startModule = 'startModule',
    defaultMo = 'defaultMo',
    mapSettings = 'mapSettings',
    featuresLayer = 'featuresLayer',
    plumeMeasure = 'plumeMeasure',
    chartMinMax = 'chartMinMax',
    chartSingleSelect = 'chartSingleSelect',
    tilePlayerSettings = 'tilePlayerSettings',
    showQRCode = 'showQRCode',
    pinsDataFormat = 'pinsDataFormat',
    defaultAqi = 'defaultAqi',
    enableTSP = 'enableTSP',
    commonMaxPDK = 'commonMaxPDK',
    disablePlumesRunNavigation = 'disablePlumesRunNavigation', // possibly a temporary setting
    showNewAnalityc = 'showNewAnalityc',
    showForecastModule = 'showForecastModule',
    forecastMapSettings = 'forecastMapSettings',
    forecastConfig = 'forecastConfig',
    indoorConfig = 'indoorConfig',
    ComfortConditionSettings = 'ComfortConditionSettings',
    plumesStationShowFullChartData = 'plumesStationShowFullChartData',
    chartTooltipByMeasurements = 'chartTooltipByMeasurements',
    dataQualityPercent = 'dataQualityPercent',
    showAnalysisModule = 'showAnalysisModule',
    showAnalysisEventsModule = 'showAnalysisEventsModule',
    analysisModuleSettings = 'analysisModuleSettings',
    indoorWidgetPostId = 'indoorWidgetPostId',
    isDemoModeForEvents = 'isDemoModeForEvents',
    excludeIndexes = 'excludeIndexes',
    showSettingsModule = 'showSettingsModule',
}
