export enum MAIN_PAGES {
    default = '',
    settings = 'settings',
    analytics = 'analytics',
    notifications = 'notifications',
    plumes = 'plumes',
    dashboard = 'dashboard',
    networks = 'networks',
    indoor = 'indoor',
    reports = 'reports',
    forecast = 'forecast',
    global = 'global',
    indoorWidget = 'indoor-widget',
    analysis = 'analysis',
    analysisEvents = 'analysis-events',
    impact = 'impact',
    network = 'network',
    events = 'events',
}
