import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleAccessInfo } from '@libs/common';
import { BasicRolesResponse } from '@libs/common';
import { LANGUAGE, TEXTS } from '@libs/common';
import { CrossButtonModule } from '@libs/shared-ui';
import { PipesModule } from '@libs/shared-ui';
import { SidebarModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';
import { InfoIconModule } from '@libs/shared-ui';

@Component({
    selector: 'shared-ui-access-info',
    standalone: true,
    imports: [
        CommonModule,
        CrossButtonModule,
        PipesModule,
        SidebarModule,
        TooltipsModule,
        InfoIconModule,
    ],
    templateUrl: './access-info.component.html',
    styleUrl: './access-info.component.less',
})
export class AccessInfoComponent {
    @Input() moduleAccessInfo: ModuleAccessInfo[];
    @Input() roles: BasicRolesResponse[];

    @Output() cancel = new EventEmitter();

    currentLang = LANGUAGE;
    tooltipInfoText: string;
    texts = TEXTS.LIST_USERS;

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        this.closePopup();
    }

    closePopup() {
        this.cancel.emit();
    }
}
