import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { TEXTS } from '@libs/common';

import { CheckboxItem } from '@libs/common';
import { TabModel } from '@libs/common';
import { Metrika } from 'ng-yandex-metrika';
import { declOfNum, isFalseNumber } from '@libs/common';
import * as moment from 'moment-timezone';
import { ChartDataset } from 'chart.js';

import { DistributionSummaryEnum, Locality, LocalitySummary } from '@libs/common';
import { Subject } from 'rxjs';

export enum CHART_BAR_NAME {
    allHistory = 'AllHistory',
    dayHour = 'DayHour',
    weekDay = 'WeekDay',
}
export const AQI_IN_COLORS = ['#008000', '#99cc00', '#ffff00', '#ffcc00', '#ff0000', '#993300'];
export const LABELS_AQI_IN = [
    `0ー50 ${declOfNum(100, TEXTS.AQI_DECL2)}`,
    `51ー100 ${declOfNum(100, TEXTS.AQI_DECL2)}`,
    `101ー200 ${declOfNum(100, TEXTS.AQI_DECL2)}`,
    `201ー300 ${declOfNum(100, TEXTS.AQI_DECL2)}`,
    `301ー400 ${declOfNum(100, TEXTS.AQI_DECL2)}`,
    `401ー500 ${declOfNum(100, TEXTS.AQI_DECL2)}`,
];
@Component({
    selector: 'shared-ui-analytic',
    templateUrl: './analytic.component.html',
    styleUrl: './analytic.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticComponent implements OnChanges {
    @Input() cities: Locality[];
    @Input() selectedCity: Locality;
    @Input() distributionSummary: LocalitySummary;
    @Input() isDemo: boolean;
    @Input() isLoading: boolean;
    @Input() showCitySelector: boolean = true;

    @Output() setCityCard = new EventEmitter<Locality>();

    textsAdminPanel = TEXTS.ADMIN_PANEL;
    textsAnalytics = TEXTS.ANALYTICS_PAGE;
    textsCityCard = TEXTS.CITY_CARD;
    textsNoSelect = TEXTS.LIST_USERS.noSelect;
    textsNames = TEXTS.NAMES;
    isShowDropdownForCities = false;
    public citiesList: CheckboxItem[] = [];
    CHART_BAR_NAME = CHART_BAR_NAME;
    chartCount: number[];
    chartHourAvg: ChartDataset<'bar', number[]>[];
    chartDayOfWeek: ChartDataset<'bar', number[]>[];
    tooltipDescription: string;
    noDataForSelectPeriod = true;
    isCityLoading = true;
    isInit = true;
    tabsSeasons: TabModel[] = [
        {
            title: TEXTS.CITY_CARD.seasons[0],
            type: DistributionSummaryEnum.year,
        },
        {
            title: TEXTS.CITY_CARD.seasons[1],
            type: DistributionSummaryEnum.winter,
        },
        {
            title: TEXTS.CITY_CARD.seasons[2],
            type: DistributionSummaryEnum.spring,
        },
        {
            title: TEXTS.CITY_CARD.seasons[3],
            type: DistributionSummaryEnum.summer,
        },
        {
            title: TEXTS.CITY_CARD.seasons[4],
            type: DistributionSummaryEnum.autumn,
        },
    ];

    currentTabsSeasons = this.tabsSeasons[0];
    public ngDestroyed$ = new Subject<void>();

    constructor(private metrika: Metrika, private _changeDetectorRef: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.cities?.currentValue?.length) {
            if (this.isInit && this.selectedCity === null) {
                this.isInit = false;
                this.selectedCity = this.cities[0];
                this.setCityCard.emit(this.cities[0]);
            }
            this.citiesList = this.cities.map((v) => ({
                id: v.id,
                label: v.name,
                selected: this.selectedCity ? this.selectedCity.id === v.id : false,
            }));

            this.isCityLoading = false;
        }
        if (!changes?.showCitySelector) {
            this.isCityLoading = false;
        }
        if (changes?.selectedCity?.currentValue) {
            this.citiesList.sort((a, b) => {
                const value = Number(b.selected) - Number(a.selected);
                if (value) {
                    return value;
                } else {
                    if (b.label < a.label) {
                        return 1;
                    }
                    if (b.label > a.label) {
                        return -1;
                    }
                }
            });
            this._changeDetectorRef.markForCheck();
        }
        if (changes?.distributionSummary?.currentValue) {
            this.fillCharts();
        }
    }

    selectCity(list: CheckboxItem[]) {
        const selectedCity = this.citiesList.find(
            (dl) => dl.id === list.find((s) => s.selected == true).id
        );
        const currentCity = selectedCity ? this.cities.find((v) => selectedCity.id === v.id) : null;
        this.setCityCard.emit(currentCity);
    }

    selectSeason(tab: TabModel) {
        this.metrika.fireEvent('environmental_profile_seasons_buttons');
        this.currentTabsSeasons = tab;
        this.fillCharts();
    }

    fillCharts() {
        if (!this.distributionSummary) return (this.noDataForSelectPeriod = true);
        const dataCartAqi: number[] = this.distributionSummary[
            this.currentTabsSeasons.type
        ].distribution_aqi.map((d) => d.percent);
        const dataCartDayHour: number[] = this.distributionSummary[
            this.currentTabsSeasons.type
        ].distribution_day_hour.map((d) => d.aqi);
        const dataCartWeekDay: number[] = this.distributionSummary[
            this.currentTabsSeasons.type
        ].distribution_week_day.map((d) => d.aqi);

        this.noDataForSelectPeriod = isFalseNumber(
            [...dataCartAqi, ...dataCartDayHour, ...dataCartDayHour].find((x) => !isFalseNumber(x))
        );

        const startDate = moment(
            this.distributionSummary[this.currentTabsSeasons.type].first_packet_date
        ).format('DD.MM.YYYY');
        this.tooltipDescription = TEXTS.CITY_CARD.since2[0] + startDate + TEXTS.CITY_CARD.since2[1];

        this.chartCount = dataCartAqi;
        this.chartHourAvg = [{ data: dataCartDayHour }];
        this.chartDayOfWeek = [{ data: dataCartWeekDay }];
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }
}
