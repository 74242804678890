<div class="loading" *ngIf="store.select(selectIsLoadingControlPoints) | async as loading else pointsTemp">
     <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
</div>
<ng-template #pointsTemp>
    <div class="impact-points"  *ngIf="store.select(selectControlPoints) | async as controlPoints">
        <ng-container *ngIf="controlPoints.length === 0 else content">
            <non-data-screen
                class="screen-placeholders"
                [text]="textsPlumes.noControlPoints"
            ></non-data-screen>
        </ng-container>
        <ng-template #content>
            <div class="impact_control_points__list scroll">
                <div class="wrapper" >
                     <ca-impact-control-point
                        *ngFor="
                            let point of controlPoints
                                | sorting : sortingControlPoint : sortingDirection
                        "
                        [currentPointValues]="
                            store.select(getControlPointImpactValue(point.id)) | async
                        "
                        [measureUnit]="
                            textsScheme[store.select(selectMeasureScheme) | async][
                                currentMeasure
                            ]
                        "
                        [point]="point"
                        [zone]="zone"
                        [isActive]="point.id?.toString() === activeControlPoint"
                        [numberAfterDot]="
                            getDigitsAfterDot(
                                store.select(selectMeasureScheme) | async,
                                currentMeasure
                            )
                        "
                        (click)="openControlPointChart(point)"
                        (openActionsPopup)="openActionsPopup($event, point)"
                    ></ca-impact-control-point>
                </div>
            </div>
       </ng-template>
    </div>
</ng-template>
<ng-template #popupDeleteOutlet>
    <stnd-ask-popup
        *ngIf="isDeletePopupOpen"
        [accept]="deleteControlPointAccept"
        [close]="closeDeleteControlPointDialog"
        [texts]="popupDeleteTexts"
    ></stnd-ask-popup>
</ng-template>

<ng-template #actionsOutlet>
    <card-actions-list [position]="popupPositionTop" (clickOutside)="closeActionsPopup($event)">
        <card-actions-item
            [text]="editStation.edit"
            (click)="editControlPoint(selectedPoint?.id)"
        ></card-actions-item>

        <card-actions-separator></card-actions-separator>

        <card-actions-item
            type="danger"
            [text]="editStation.delete"
            (click)="deleteControlPointDialog($event, selectedPoint)"
        ></card-actions-item>
    </card-actions-list>
</ng-template>
