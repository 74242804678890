import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    catchError,
    delay,
    filter,
    map,
    switchMap,
    take,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { ImpactApiService } from './../service/impact-api.service';

import { iif, of } from 'rxjs';
import { HttpErrorResponse, HttpParamsOptions, HttpStatusCode } from '@angular/common/http';
import { getActionBasicError, SharedCoreActions, SharedPostActions } from '@libs/shared-store';
import {
    ImpactStatActions,
    selectStatsRegionParams,
    selectDateRangeRegion,
    selectRegionMmt,
} from '@cityair/modules/impact/store/statistic.feature';
import { removeFromComparison } from '@cityair/modules/core/store/actions';
import { USE_IMPACT_API } from '@cityair/modules/impact/consts';
import {
    ImpactActions,
    selectMockDataStatRegion,
} from '@cityair/modules/impact/store/impact.feature';

@Injectable()
export class ImpactStatsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private impactService: ImpactApiService
    ) {}

    loadStatByRegion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ImpactStatActions.setRegion,
                ImpactStatActions.updateRegionMmt,
                ImpactStatActions.updateDateRangeRegion
            ),
            withLatestFrom(
                this.store.select(selectStatsRegionParams),
                this.store.select(selectMockDataStatRegion)
            ),
            filter(([_, params]) => params !== null),
            tap(() =>
                this.store.dispatch(ImpactStatActions.loadingStatForRegion({ payload: true }))
            ),
            switchMap(([_, { id, params }, data]) =>
                iif(
                    () => USE_IMPACT_API,
                    this.impactService.getStatsRegion(id, params).pipe(
                        switchMap((res: any) => [
                            ImpactStatActions.setExcessStatistics({ payload: res?.data ?? null }),
                            ImpactStatActions.loadingStatForRegion({ payload: false }),
                        ]),
                        catchError((errorResponse: HttpErrorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(
                                errorAction,
                                ImpactStatActions.loadingStatForRegion({ payload: false })
                            );
                        })
                    ),
                    of(ImpactStatActions.setMockDataRegion({ payload: data })).pipe(delay(700))
                )
            )
        )
    );
    initRegionMmt = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActiveRun),
            withLatestFrom(this.store.select(selectRegionMmt)),
            filter(([_, mmt]) => mmt === null),
            switchMap(([action, mmt]) => {
                if (action?.payload?.species_list?.length) {
                    const mmt = action.payload.species_list[0];
                    return [ImpactStatActions.initRegionMmt({ payload: mmt })];
                }
                return [];
            })
        )
    );
}
