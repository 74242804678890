<div class="item" *ngIf="item">
    <div class="date-row">
        <div class="period">{{item.start | shortDateFormat }} - {{item.end | shortDateFormat}}</div>
        <div class="duration">{{item.start | durationDateRangeFormat : item.end }}</div>
    </div>
    <div class="header" *ngIf="item.events?.length">
        <div class="posts_count">{{texts.postsHeader}}</div>
        <div class="mmts">{{texts.mmtHeader}}</div>
        <div class="max">{{texts.maxHeader}}</div>
    </div>
    <div class="values" *ngIf="item.events | postIdsByEvents as posts">
        <div class="posts_count">{{ posts.length }}</div>
        <div class="mmts">
            <div class="mmt" [innerHtml]="textNames[item.param]"></div>
        </div>
        <div class="max" *ngIf="item | maxValueEvent : null as maxData">
            <span class="value">
                {{maxData.value | number: '1.0-0'}}
            </span>
            <span class="unit" [innerHtml]="textsScheme[schema][item.param]"></span>
            <span class="time"> {{maxData.time | shortDateFormat}}</span>
        </div>
    </div>
</div>
