<div class="popup-wrapper">
    <div class="access-level-popup">
        <cross-button
            class="close"
            size="large"
            (click)="closePopup()"
        ></cross-button>
        <div class="title" [innerHTML]="">{{texts.accessPopUp.title}}</div>
        <div class="roles" *ngIf="roles">
            <div class="empty"></div>
            <div class="roles-item" *ngFor="let role of roles | orderBy: 'id': -1">
                <ng-container *ngIf="currentLang === 'ru' else enName"
                >
                    {{role?.name_ru}}
                </ng-container>
                <ng-template #enName>{{role?.name}}</ng-template>
            </div>
        </div>
        <div class="hr-line"></div>
        <div class="content">
            <div class="content-item" *ngFor="let item of moduleAccessInfo">
                <div class="modules">
                    <ca-menu-button
                        name="{{item.icon}}"
                        class="active"
                    ></ca-menu-button>
                    <div class="name">{{item.name}}</div>
                </div>
                <div class="access-info">
                    <div class="level" [ngClass]="item.adminAccess.level">{{texts.accessPopUp[item.adminAccess.level]}}</div>
                    <div class="details" *ngIf="item.adminAccess.details"
                        caTooltip
                        caTooltipPlacement="top-right"
                        [caTooltipTemplate]="detailsAdminTooltip"
                    >
                        <ca-info-icon class="info-icon" [withGradient]="true"></ca-info-icon>
                    </div>
                    <ng-template #detailsAdminTooltip>
                        <ca-tooltip class="access-info"
                            [text]="item.adminAccess.details"
                        ></ca-tooltip>
                    </ng-template>
                </div>
                <div class="access-info">
                    <div class="level" [ngClass]="item.operatorAccess.level">{{texts.accessPopUp[item.operatorAccess.level]}}</div>
                    <div class="details" *ngIf="item.operatorAccess.details"
                        caTooltip
                        caTooltipPlacement="top-right"
                        [caTooltipTemplate]="detailsOperatorTooltip"
                    >
                        <ca-info-icon class="info-icon" [withGradient]="true"></ca-info-icon>
                    </div>
                    <ng-template #detailsOperatorTooltip>
                        <ca-tooltip class="access-info"
                            [text]="item.operatorAccess.details"
                        ></ca-tooltip>
                    </ng-template>
                </div>
                <div class="access-info">
                    <div class="level"  [ngClass]="item.observerAccess.level">{{texts.accessPopUp[item.observerAccess.level]}}</div>
                    <div class="details" *ngIf="item.observerAccess.details"
                        caTooltip
                        caTooltipPlacement="top-right"
                        [caTooltipTemplate]="detailsTooltip"
                    >
                        <ca-info-icon class="info-icon" [withGradient]="true"></ca-info-icon>
                    </div>
                    <ng-template #detailsTooltip>
                        <ca-tooltip class="access-info"
                            [text]="item.observerAccess.details"
                        ></ca-tooltip>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="hr-line"></div>
    </div>
</div>
