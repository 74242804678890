import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllPostsDic, selectPostNameById } from '@libs/shared-store';

import {
    ImpactActions,
    selectEvents,
    selectCorrelationData,
    selectEventDetailData,
} from '@cityair/modules/impact/store/impact.feature';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { LANGUAGE, TEXTS } from '@libs/common';
import { MeasureScheme } from '@libs/common';
import { EventImpact } from '@cityair/modules/impact/service/api-model-impact';
import { ActivatedRoute, Router } from '@angular/router';
import { MAIN_PAGES } from '@libs/common';
import { BehaviorSubject, filter, fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil, throttleTime } from 'rxjs/operators';
import { ChartConfiguration, ChartType } from 'chart.js';
import { Dictionary } from '@ngrx/entity';
import { CorrelationData, Post } from '@libs/common';
import { Feature } from '@libs/common';
import { NgLocalization } from '@angular/common';

@Component({
    selector: 'ca-impact-event-detail',
    templateUrl: 'impact-event-detail.component.html',
    styleUrls: ['impact-event-detail.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactEventDetailComponent implements OnDestroy {
    selectPostNameById = selectPostNameById;
    textsScheme = TEXTS.MEASURES_SCHEME;
    schema = MeasureScheme.default;
    textNames = TEXTS.NAMES;
    texts = TEXTS.IMPACT.eventList.details;
    public ngDestroyed$ = new Subject<void>();
    currentId: string;
    private isInit = true;
    private initEvent: string;
    currentEvent: EventImpact;
    public doughnutChartType: ChartType = 'doughnut';
    options: ChartConfiguration['options'] = {
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                borderWidth: 1,
                bodyColor: '#000',
                titleColor: '#000',
                displayColors: true,
                borderColor: '#B8BFCC',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                callbacks: {
                    label: (tooltipItem) => {
                        let label = tooltipItem.label;
                        if (label.length >= 9) {
                            label = label.substring(0, 8) + '...';
                        }
                        return `${label} ${tooltipItem.formattedValue}`;
                    },
                    title: (tooltipItem) => ``,
                },
            },
        },
    };

    posts: Dictionary<Post>;
    showDetails = false;
    isShowInfoPopupCorrelation = false;
    correlationData: CorrelationData;
    chartEventData: { [key: string]: Feature[] };
    charSize$ = new BehaviorSubject<{ width: number; left: number }>(null);
    currentWidthTimeline;
    @ViewChild('timelineTrack', { read: ElementRef }) timelineTrack: ElementRef<HTMLElement>;
    constructor(
        readonly store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private ngLocalization: NgLocalization
    ) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.list }));
        fromEvent(window, 'resize')
            .pipe(throttleTime(500), debounceTime(500), takeUntil(this.ngDestroyed$))
            .subscribe(() => {
                this.updateTrackPanel();
            });
        this.route.params.pipe(takeUntil(this.ngDestroyed$)).subscribe((params) => {
            if (this.isInit) {
                this.initEvent = params?.id;
            } else {
                this.currentId = params?.id;
                if (this.currentId) {
                    this.store.dispatch(ImpactActions.setActiveEvent({ payload: this.currentId }));
                } else {
                    this.store.dispatch(ImpactActions.setActiveEvent({ payload: null }));
                }
            }
            _changeDetectorRef.markForCheck();
        });
        this.store
            .select(selectEvents)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => !!v?.length && this.isInit && this.initEvent !== null)
            )
            .subscribe((events) => {
                const currentEvent = events?.find((v) => v.id === this.initEvent);
                this.currentEvent = currentEvent;
                this.isInit = false;
                if (currentEvent) {
                    this.currentId = this.initEvent;
                    this.store.dispatch(ImpactActions.setActiveEvent({ payload: this.currentId }));
                    setTimeout(() => {
                        this.store.dispatch(
                            ImpactActions.getEventDetailsData({ payload: this.currentEvent })
                        );
                    }, 500);
                } else {
                    this.back();
                }
            });
        this.store
            .select(selectAllPostsDic)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((posts) => {
                this.posts = posts;
                _changeDetectorRef.markForCheck();
            });
        this.store
            .select(selectCorrelationData)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.correlationData = data;
                _changeDetectorRef.markForCheck();
            });
        this.store
            .select(selectEventDetailData)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.chartEventData = data;
                _changeDetectorRef.markForCheck();
            });
    }

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        if (this.isShowInfoPopupCorrelation) {
            this.isShowInfoPopupCorrelation = false;
        } else if (this.showDetails) {
            this.showDetails = false;
        }
    }

    ngOnDestroy() {
        this.store.dispatch(ImpactActions.setActiveEvent({ payload: null }));
        this.store.dispatch(ImpactActions.setEventDetailsData({ payload: {} }));
        this.ngDestroyed$.next();
    }

    updateChartSize(value: { width: number; left: number }) {
        this.charSize$.next(value);
        this.currentWidthTimeline = value?.width;
        this._changeDetectorRef.detectChanges();
    }

    updateTrackPanel(): void {
        if (this.timelineTrack) {
            this.currentWidthTimeline =
                this.timelineTrack.nativeElement.getBoundingClientRect().width;
            this._changeDetectorRef.detectChanges();
        }
    }
    selectEvent(event: EventImpact) {
        this.router.navigate([`${MAIN_PAGES.events}/${event.id}`]);
    }

    back() {
        this.router.navigate([`/${MAIN_PAGES.events}/${IMPACT_PAGES.list}`]);
    }

    setDetails() {
        this.showDetails = true;
    }

    closeDetail() {
        this.showDetails = false;
    }

    public postText(num: number = 0) {
        const { posts } = TEXTS.IMPACT.eventList.details;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        return [num, posts[category]].join(' ');
    }
}
