<ng-container *ngIf="isInit else contentTemp">
     <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
</ng-container>
<ng-template #contentTemp>
    <div *ngIf="source else noDataTemp" class="impact-source scroll">
        <div class="back-btn" (click)="backToSources()">
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.71599 8.00027L8.6705 2.12309C9.10983 1.68946 9.10983 0.986399 8.6705 0.552765C8.23116 0.119131 7.51884 0.119131 7.0795 0.552765L0.329505 7.2151C-0.109835 7.64874 -0.109835 8.3518 0.329505 8.78543L7.0795 15.4478C7.51884 15.8814 8.23116 15.8814 8.6705 15.4478C9.10983 15.0141 9.10983 14.3111 8.6705 13.8774L2.71599 8.00027Z" fill="#6097F3"/>
            </svg>
            <span>{{ textImpact.sources.backToSources }}</span>
        </div>
        <div class="source-header">
            <div class="source-header__title">
                <source-type-icon [type]="sourceType"></source-type-icon>
                <span>{{ source?.name }}</span>
            </div>
            <div class="source-header__image" [attr.data-source-id]="source.id"></div>
        </div>
        <div class="section">
            <div class="section__title">{{ textImpact.sources.sourceCard.titleSource }}</div>
            <div class="section__params">
                <div class="section__param">
                    <div class="label">{{ textImpact.sources.labels.objTitle }}</div>
                    <div class="value">{{ sourceMock?.objectParams.title }}</div>
                </div>
                <div class="section__param">
                    <div class="label">{{ textImpact.sources.sourceCard.type }}</div>
                    <div class="value" *ngIf="source | impactSourceType as type"
                    >{{ textImpact.sources.sourceCard.sourceType[type]}}</div>
                </div>
                <div class="section__param">
                    <div class="label">{{ textImpact.sources.sourceCard.height }}</div>
                    <div class="value">{{ sourceMock?.objectParams.height }} м</div>
                </div>
                <div class="section__param">
                    <div class="label">{{ textImpact.sources.sourceCard.square }}</div>
                    <div class="value" [innerHtml]="source | impactSourceSquare : currentLang"></div>
                </div>
            </div>
        </div>
        <div class="section" *ngIf="activeRun$ | async as run">
            <div class="section__title">{{ textImpact.sources.sourceCard.titleParams }}</div>
            <div class="section__params">
                <div class="section__param">
                    <div class="label">{{ textImpact.sources.sourceCard.formTube }}</div>
                    <div class="value">{{ sourceMock?.pdvParams.form ?? ' - ' }}</div>
                </div>
                <div class="section__param">
                    <div class="label">{{ textImpact.sources.sourceCard.numberPDV }}</div>
                    <div class="value">{{ sourceMock?.pdvParams.pdv ?? ' - '}}</div>
                </div>
                <div class="section__param">
                    <div class="label label-mmt">{{ textImpact.sources.sourceCard.estimatedEmissions }}</div>
                    <ul class="mmt-list">
                        <li *ngFor="let item of source.emissions; index as i"
                            class="mmt-list__item"
                        >
                            <span class="mmt-label" [innerHtml]="textNames[item.emissions_species]"></span>
                            <span class="value">{{ source | impactSourceEmission : run : item.emissions_species : true | valueFormatNumber : 1 }} {{ textImpact.sources.sourceCard.estimatedEmissionsUnit}}</span>
                        </li>
                    </ul>
                </div>
                <div class="section__param">
                    <div class="label label-mmt">{{ textImpact.sources.sourceCard.modelingParams }}</div>
                    <ul class="mmt-list">
                         <li *ngFor="let item of source.emissions; index as i"
                            class="mmt-list__item"
                        >
                            <span class="mmt-label" [innerHtml]="textNames[item.emissions_species]"></span>
                            <span class="value">{{ source | impactSourceEmission : run : item.emissions_species : false | valueFormatNumber : 4 }} {{ textImpact.sources.sourceCard.modelingParamsUnit}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section__title">{{ textImpact.sources.sourceCard.influenceStatistics }}</div>
            <div class="filters">
                <div class="calendar-filter"
                    (clickOutside)="calendarIsOpened = false"
                >
                    <div (click)="toggleCalendar()">
                        <input-for-dropdown
                            [label]="textImpact.sources.filters.period"
                            [inputValue]="dateRange | dateRangeText"
                            [(isOpenDropdown)]="calendarIsOpened"
                        ></input-for-dropdown>
                    </div>
                    <div class="plumes_calc__calendar">
                        <calendar-months
                            [show]="calendarIsOpened"
                            [timeBegin]="dateTimeBegin"
                            [timeEnd]="dateTimeEnd"
                            [applyCb]="updateDateRange"
                            [monthsVisible]="1"
                            [updateDays]="updateDays"
                            [columns]="1"
                            [numberOfMonths]="12"
                        ></calendar-months>
                    </div>
                </div>
                <div class="mmt-filter">
                    <input-dropdown
                        [inputText]="selectedSourceMmt ? selectedSourceMmt?.label : 'Не выбрано'"
                        [label]="textImpact.sources.filters.measure"
                        [(showDropdown)]="isShowDropdownForMmtList"
                        (clickOutside)="isShowDropdownForMmtList = false"
                    >
                        <selectbox-radio
                            [listItems]="mmtList"
                            (listItemsChange)="selectSourceMmt($event); isShowDropdownForMmtList = false"
                        ></selectbox-radio>
                    </input-dropdown>
                </div>
            </div>
            <div class="widgets">
                <div class="section-widget">
                    <div class="section-widget__title">{{ textImpact.sources.sourceCard.avarageContribution }} {{ dateRange | dateRangeText }}</div>
                    <div class="switch-item-wrapper">
                        <switch-item
                            class="switch-item"
                            [currentTab]="currentTabDuration"
                            [tabs]="tabsDuration"
                            (action)="changeTabDuration($event)"
                        >
                        </switch-item>
                    </div>
                    <div *ngIf="currentTabDuration.id === 'regions'" class="impact-region-chart">
                        <div *ngFor="let region of impactToRegion" class="item">
                            <div class="item__title">{{ region.name }}</div>
                            <div class="item__line">
                                <span [style.backgroundColor]="region.color" [style.width]="getRegionChartWidth(region.value)"></span>
                            </div>
                            <div class="item__value">{{ region.value }} ч/день</div>
                        </div>
                    </div>
                    <div *ngIf="currentTabDuration.id === 'points'" class="impact-region-chart">
                        <div *ngFor="let region of impactToControlPoints" class="item">
                            <div class="item__title">{{ region.name }}</div>
                            <div class="item__line">
                                <span [style.backgroundColor]="region.color" [style.width]="getRegionChartWidth(region.value)"></span>
                            </div>
                            <div class="item__value">{{ region.value }} ч/день</div>
                        </div>
                    </div>
                </div>
                <div class="section-widget">
                    <div class="section-widget__title">
                        Средняя концентрация по <span [innerHTML]="selectedSourceMmt.label"></span> от источника за  {{ dateRange | dateRangeText }}
                    </div>
                    <div class="switch-item-wrapper">
                        <switch-item
                            class="switch-item"
                            [currentTab]="currentTabConcentration"
                            [tabs]="tabsConcentration"
                            (action)="changeTabConcentration($event)"
                        >
                        </switch-item>
                    </div>
                    <div *ngIf="currentTabConcentration.id === 'regions'" class="concentration-chart">
                        <div *ngFor="let region of impactToRegion" class="item">
                            <div class="item__title">{{ region.name }}</div>
                            <div class="item__value">
                                <span class="color" [style.backgroundColor]="region.color"></span>
                                {{ region.concentration.pm25 }}
                                <span class="unit">ПДКмр</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="currentTabConcentration.id === 'points'" class="concentration-chart">
                        <div *ngFor="let region of impactToControlPoints" class="item">
                            <div class="item__title">{{ region.name }}</div>
                            <div class="item__value">
                                <span class="color" [style.backgroundColor]="region.color"></span>
                                {{ region.concentration.pm25 }}
                                <span class="unit">ПДКмр</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section-widget">
                    <div class="section-widget__title">% вклада по <span [innerHTML]="selectedSourceMmt.label"></span> от всех влияющих источников за  {{ dateRange | dateRangeText }}:</div>
                    <div class="switch-item-wrapper">
                        <switch-item
                            class="switch-item"
                            [currentTab]="currentTabContribution"
                            [tabs]="tabsContribution"
                            (action)="changeTabContribution($event)"
                        >
                        </switch-item>
                    </div>
                    <city-district-chart
                        [data]="dataChart"
                        [labels]="labelChart"
                        [colors]="colorsChart"
                    ></city-district-chart>
                </div>
            </div>
        </div>
    </div>
    <ng-template #noDataTemp>
        <non-data-screen
            class="screen-placeholders"
            [text]="textImpact.sources.noFoundSource"
        ></non-data-screen>
    </ng-template>
</ng-template>
