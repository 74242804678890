import { createAction, props } from '@ngrx/store';
import { IMapClick } from '@cityair/namespace';
import {
    IntervalEnum,
    DataMarker,
    DataQuality,
    DataQualityInfo,
    Locality,
    BasicDataResponse,
    IBasicResponse,
    UserSettings,
} from '@libs/common';

export enum CommonActions {
    Noop = '[CommonAction] Do nothing',
    updateUserSettings = '[CommonActions] updateUserSettings',
    SetAllMmts = '[CommonActions] Set All Mmts',
    TimelineInfoLoad = '[CommonActions] TimelineInfoLoad',
    SelectDevice = '[CommonActions] SelectDevice',
    SelectMo = '[CommonActions] SelectMo',
    MapLoaded = '[CommonActions] MapLoaded',
    SetGlobalMeasurement = '[CommonActions] SetGlobalMeasurement',
    OpenInstantAqiFaq = '[CommonActions] OpenInstantAqiFaq',
    CloseInstantAqiFaq = '[CommonActions] CloseInstantAqiFaq',
    PinsValuesLoaded = '[CommonActions] PinsValuesLoaded',
    CurrentTimeUpdate = '[CommonActions] CurrentTimeUpdate',
    IntervalUpdate = '[CommonActions] IntervalUpdate',
    UpdateCurrentTimeWithNearestData = '[CommonActions] UpdateCurrentTimeWithNearestData',
    SetTimelineDateTimes = '[CommonActions] SetTimelineDateTimes',
    SetCityMode = '[CommonActions] SetCityMode (Legacy)',
    SetWorldMode = '[CommonActions] SetWorldMode (Legacy)',
    CityModeOff = '[CommonActions] CityModeOff (Legacy)',
    SetTzOffset = '[CommonActions] SetTzOffset',
    ResetTzOffset = '[CommonActions] ResetTzOffset',
    SetErrorMessage = '[CommonAction] SetErrorMessage',
    ClearErrorMessage = '[CommonAction] ClearErrorMessage',
    SetComparisonMode = '[CommonAction] SetComparisonMode',
    UpdateTimeRangeData = '[CommonAction] UpdateTimeRangeData',
    SetTypeInterval = '[CommonAction] SetTypeInterval',
    RemoveFromComparison = '[CommonAction] RemoveFromComparison',
    UpdatePostData = '[CommonAction] UpdatePostData',
    InitMainMap = '[CommonAction] InitMainMap',
    DestroyMainMap = '[CommonAction] DestroyMainMap',
    SetMapClickState = '[CommonAction] SetMapClickState',
    LoadStationData = '[CommonActions] LoadStationData',
    UpdateStationData = '[CommonActions] UpdateStationData',
    updateCompareStations = '[CommonActions] UpdateCompareStations',
    loadMonitoringData = '[CommonActions] LoadMonitoringData',
    updateCitiesChart = '[CommonActions] updateCitiesChart',
    openCityCard = '[CommonActions] openCityCard',
    openCity = '[CommonActions] openCity',
    selectCity = '[CommonActions] selectCity',
    loadCity = '[CommonActions] loadCity',
    clickFromApToMo = '[CommonActions] clickFromApToMo',
    mapMarkerClick = '[CommonActions] mapMarkerClick',
    clickOnCityMarker = '[CommonActions] clickOnCityMarker',
    setAvailableModule = '[CommonActions] setAvailableModule',
    changeQualityDataMode = '[CommonActions] toggleQualityDataMode',
    toggleShowQualityDataInfo = '[CommonActions] toggleShowQualityDataInfo',
    setQualityDataMarkers = '[CommonActions] setQualityDataMarkers',
    loadQualityData = '[CommonActions] loadQualityData',
    setQualityData = '[CommonActions] setQualityData',
    setQualityDataTimeline = '[CommonActions] setQualityDataTimeline',
    timelineLoaded = '[CommonActions] timeline loaded',
    isLoadingTimeline = '[CommonActions] set is loading timeline',
    updateTimeIndex = '[CommonActions] update time index',
    setChartData = '[CommonActions] set chart Data',
    updateChartData = '[CommonActions] update chart data',
    togglePublicForecast = '[CommonActions] toggle public forecast',
}

export const doNothing = createAction(CommonActions.Noop);

export const setErrorMessage = createAction(
    CommonActions.SetErrorMessage,
    props<{ msg: string }>()
);

export const clearErrorMessage = createAction(CommonActions.ClearErrorMessage);

export const initMainMap = createAction(CommonActions.InitMainMap);

export const destroyMainMap = createAction(CommonActions.DestroyMainMap);

export const updateUserSettings = createAction(
    CommonActions.updateUserSettings,
    props<{ payload: UserSettings }>()
);

export const timelineInfoLoad = createAction(CommonActions.TimelineInfoLoad);

export const selectDevice = createAction(
    CommonActions.SelectDevice,
    props<{ serialNumber: string }>()
);

export const selectMo = createAction(CommonActions.SelectMo, props<{ id: string }>());

export const mapLoaded = createAction(CommonActions.MapLoaded);
export const setAllMmts = createAction(CommonActions.SetAllMmts, props<{ payload: string[] }>());
export const setGlobalMeasurement = createAction(
    CommonActions.SetGlobalMeasurement,
    props<{ mmt: string }>()
);

export const openInstantAqiFaq = createAction(CommonActions.OpenInstantAqiFaq);
export const closeInstantAqiFaq = createAction(CommonActions.CloseInstantAqiFaq);

export const setTimelineDateTimes = createAction(
    CommonActions.SetTimelineDateTimes,
    props<{ dateTimes: string[] }>()
);

export const setCityMode = createAction(CommonActions.SetCityMode);

export const setWorldMode = createAction(CommonActions.SetWorldMode);
export const cityModeOff = createAction(CommonActions.CityModeOff);

export const setTzMinutesOffset = createAction(CommonActions.SetTzOffset, props<{ tz: number }>());
export const resetTzMinutesOffset = createAction(CommonActions.ResetTzOffset);

export const setComparisonMode = createAction(
    CommonActions.SetComparisonMode,
    props<{ payload: boolean }>()
);

// ----------------------- TIME ---------------------------

export const currentTimeUpdate = createAction(
    CommonActions.CurrentTimeUpdate,
    props<{ time: number }>()
);

export const intervalUpdate = createAction(
    CommonActions.IntervalUpdate,
    props<{ begin: number; end: number }>()
);

export const updateCurrentTimeWithNearestData = createAction(
    CommonActions.UpdateCurrentTimeWithNearestData
);

export const updateTimeRangeData = createAction(CommonActions.UpdateTimeRangeData);

export const setTypeInterval = createAction(
    CommonActions.SetTypeInterval,
    props<{ payload: IntervalEnum }>()
);

export const removeFromComparison = createAction(
    CommonActions.RemoveFromComparison,
    props<{ id: string }>()
);

export const setMapClickState = createAction(CommonActions.SetMapClickState, props<IMapClick>());

export const loadStationData = createAction(CommonActions.LoadStationData, props<{ id: string }>());

export const loadMonitoringData = createAction(
    CommonActions.loadMonitoringData,
    props<{ markerId: string }>()
);

export const updateCitiesChart = createAction(CommonActions.updateCitiesChart);

export const selectCity = createAction(
    CommonActions.selectCity,
    props<{ cityId: string; centringMap: boolean }>()
);

export const loadCity = createAction(
    CommonActions.loadCity,
    props<{ cityId: string; centringMap: boolean }>()
);

export const clickFromApToMo = createAction(
    CommonActions.clickFromApToMo,
    props<{ moObjId: string }>()
);

export const mapMarkerClick = createAction(
    CommonActions.mapMarkerClick,
    props<{ markerId: string }>()
);

export const clickOnCityMarker = createAction(
    CommonActions.clickOnCityMarker,
    props<{ cityMarker: Locality }>()
);
export const changeQualityDataMode = createAction(
    CommonActions.changeQualityDataMode,
    props<{ payload: number }>()
);
export const toggleShowQualityDataInfo = createAction(
    CommonActions.toggleShowQualityDataInfo,
    props<{ payload: DataQualityInfo }>()
);
export const setQualityDataMarkers = createAction(
    CommonActions.setQualityDataMarkers,
    props<{ payload: DataMarker[] }>()
);
export const loadQualityData = createAction(CommonActions.loadQualityData, props<{ id: string }>());
export const setQualityData = createAction(
    CommonActions.setQualityData,
    props<{ payload: DataQuality[] }>()
);
export const timelineLoaded = createAction(
    CommonActions.timelineLoaded,
    props<{ payload: IBasicResponse }>()
);
export const isLoadingTimeline = createAction(
    CommonActions.isLoadingTimeline,
    props<{ payload: boolean }>()
);
export const updateTimeIndex = createAction(
    CommonActions.updateTimeIndex,
    props<{ payload: number }>()
);
export const setChartData = createAction(
    CommonActions.setChartData,
    props<{ payload: IBasicResponse; feature: BasicDataResponse; extConfig?: any }>()
);
export const updateChartData = createAction(
    CommonActions.updateChartData,
    props<{ payload: IBasicResponse[]; features: BasicDataResponse[]; extConfig?: any }>()
);
export const togglePublicForecast = createAction(
    CommonActions.togglePublicForecast,
    props<{ payload: boolean }>()
);
