<svg *ngIf="type === 'tube'" width="21" height="32" viewBox="0 0 21 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11.5572" cy="4.52307" r="1.50744" fill="#32353B"/>
    <circle cx="17.0826" cy="1.00496" r="1.00496" fill="#32353B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.01953 9.54651C4.01953 8.71397 4.69444 8.03906 5.52698 8.03906H14.5716C15.4042 8.03906 16.0791 8.71397 16.0791 9.54651C16.0791 10.379 15.4042 11.054 14.5716 11.054H5.52698C4.69444 11.054 4.01953 10.379 4.01953 9.54651ZM0 29.6453C0 28.8128 0.674906 28.1379 1.50744 28.1379H2.00915L5.53841 11.0553H14.1664L18.0878 28.1379H18.5918C19.4243 28.1379 20.0993 28.8128 20.0993 29.6453C20.0993 30.4779 19.4244 31.1528 18.5918 31.1528H1.50745C0.674907 31.1528 0 30.4779 0 29.6453ZM10.2637 16.138C10.315 15.5842 9.58397 15.3397 9.29181 15.813L6.74895 19.9325C6.53251 20.2831 6.78474 20.7352 7.19681 20.7352H9.47163C9.78147 20.7352 10.0243 21.0016 9.9957 21.3101L9.83412 23.0551C9.78284 23.609 10.5139 23.8534 10.806 23.3801L13.3489 19.2607C13.5653 18.9101 13.3131 18.4579 12.901 18.4579H10.6262C10.3164 18.4579 10.0736 18.1916 10.1022 17.8831L10.2637 16.138Z" fill="#32353B"/>
</svg>

<svg *ngIf="type === 'agro'" width="35" height="25" viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0545 4.28731L17.0118 4.43787V25H15.2788C14.5317 25 13.8528 24.5656 13.5397 23.8873C13.3026 23.3736 12.8505 22.991 12.3046 22.8421L12.2544 22.8284C11.0343 22.4956 10.4135 21.1392 10.9591 19.9983L11.2795 19.3285C11.45 18.972 11.5385 18.5819 11.5385 18.1867V17.9869C11.5385 17.5382 11.4528 17.0936 11.2862 16.677L9.78453 12.9229C9.57441 12.3976 9.44694 11.8429 9.40663 11.2786L9.31953 10.0592C7.26154 11.6884 4.33454 11.6191 2.35602 9.8942L0.370648 8.16337C0.180125 7.99727 0.19176 7.69765 0.39459 7.54682L1.82321 6.48452C4.10145 4.79044 7.26976 4.9883 9.31953 6.95266L8.88585 3.5917C8.78536 2.81289 9.02396 2.02841 9.54106 1.43744L10.5755 0.255194C10.6192 0.205244 10.6994 0.253969 10.6753 0.315779C9.94997 2.16927 10.4603 4.27845 11.9528 5.5953L11.9822 5.6213C12.7226 4.69582 13.8728 4.19641 15.0545 4.28731Z" fill="#32353B"/>
    <path d="M18.9689 4.28731L17.0116 4.43787V25H18.7447C19.4918 25 20.1707 24.5656 20.4837 23.8873C20.7208 23.3736 21.173 22.991 21.7188 22.8421L21.769 22.8284C22.9891 22.4956 23.6099 21.1392 23.0643 19.9983L22.744 19.3285C22.5735 18.972 22.485 18.5819 22.485 18.1867V17.9869C22.485 17.5382 22.5706 17.0936 22.7372 16.677L24.2389 12.9229C24.449 12.3976 24.5765 11.8429 24.6168 11.2786L24.7039 10.0592C26.7619 11.6884 29.6889 11.6191 31.6674 9.8942L33.6528 8.16337C33.8433 7.99727 33.8317 7.69765 33.6288 7.54682L32.2002 6.48452C29.922 4.79044 26.7537 4.9883 24.7039 6.95266L25.1376 3.5917C25.2381 2.81289 24.9995 2.02841 24.4824 1.43744L23.4479 0.255194C23.4042 0.205244 23.324 0.253969 23.3482 0.315779C24.0735 2.16927 23.5631 4.27845 22.0707 5.5953L22.0412 5.6213C21.3008 4.69582 20.1506 4.19641 18.9689 4.28731Z" fill="#32353B"/>
    <path d="M21.3323 20.6656C21.3323 21.3164 20.8048 21.8439 20.154 21.8439C19.5033 21.8439 18.9757 21.3164 18.9757 20.6656C18.9757 20.0149 19.5033 19.4873 20.154 19.4873C20.8048 19.4873 21.3323 20.0149 21.3323 20.6656Z" fill="white"/>
    <path d="M15.048 20.6656C15.048 21.3164 14.5205 21.8439 13.8697 21.8439C13.219 21.8439 12.6914 21.3164 12.6914 20.6656C12.6914 20.0149 13.219 19.4873 13.8697 19.4873C14.5205 19.4873 15.048 20.0149 15.048 20.6656Z" fill="white"/>
</svg>
    
<svg *ngIf="type === 'factory'" width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6209 7.27375C22.3881 7.14869 22.1049 7.16163 21.8821 7.30969L15.8129 11.3548V7.90769C15.8129 7.64175 15.6663 7.39881 15.4334 7.27375C15.2006 7.14869 14.9174 7.16163 14.6946 7.30969L7.88081 11.8507L7.24112 2.875H7.90668C8.30343 2.875 8.62543 2.553 8.62543 2.15625V0.71875C8.62543 0.322 8.30343 0 7.90668 0H0.719182C0.322432 0 0.000431593 0.322 0.000431593 0.71875V2.15625C0.000431593 2.553 0.322432 2.875 0.719182 2.875H1.38474L0.00186909 22.2309C-0.0125059 22.4293 0.0564941 22.6248 0.193057 22.7714C0.329619 22.9166 0.519369 23 0.719182 23C0.719182 23 5.47351 23 7.85067 23C12.661 23 22.2817 23 22.2817 23C22.6784 23 23.0004 22.678 23.0004 22.2813V7.90769C23.0004 7.64175 22.8538 7.39881 22.6209 7.27375ZM12.9361 17.25H10.0611V14.375H12.9361V17.25ZM20.1264 17.25H17.2514V14.375H20.1264V17.25Z" fill="#32353B"/>
</svg>

<svg *ngIf="type === 'private-sector'" width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7665 21.9199H4.61475V30.0586C4.61475 30.6611 5.10316 31.1495 5.70565 31.1495H10.3838V24.2275H14.9986V31.1495H19.6755C20.278 31.1495 20.7665 30.6611 20.7665 30.0586V21.9199Z" fill="#32353B"/>
    <path d="M11.9568 11.0509C12.3729 10.6726 13.0084 10.6726 13.4244 11.0509L24.3373 20.9717C24.7062 21.307 24.4689 21.9207 23.9704 21.9207H1.41088C0.912315 21.9207 0.675057 21.307 1.04396 20.9717L11.9568 11.0509Z" fill="#32353B"/>
    <rect x="16.1523" y="11.5371" width="4.61477" height="9.22955" fill="#32353B"/>
    <path d="M16.4697 8.20985L18.2212 9.74238C18.6304 10.1004 19.2542 9.70484 19.1048 9.18203L18.7842 8.05991C18.5768 7.3341 18.6795 6.55479 19.0679 5.9075L19.3679 5.40748C19.8754 4.56163 19.8867 3.50766 19.3973 2.6512L18.2899 0.713329C18.1789 0.519071 17.8823 0.59784 17.8823 0.821577V4.35261C17.8823 4.51831 17.807 4.67503 17.6776 4.77854L16.525 5.70058C15.7292 6.33726 15.7027 7.53871 16.4697 8.20985Z" fill="#32353B"/>
</svg>
    
<svg *ngIf="type === 'azs'" width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.35373 0C2.89647 0 1.71511 1.18137 1.71511 2.63862V21.2849H14.6883V2.63862C14.6883 1.18137 13.507 0 12.0497 0H4.35373ZM4.35373 1.75908C3.86801 1.75908 3.47419 2.15286 3.47419 2.63862V7.82792C3.47419 8.31368 3.86801 8.70746 4.35373 8.70746H12.0497C12.5354 8.70746 12.9293 8.31368 12.9293 7.82792V2.63862C12.9293 2.15286 12.5354 1.75908 12.0497 1.75908H4.35373Z" fill="#32353B"/>
    <path d="M0 21.5927C0 21.1556 0.354406 20.8011 0.791587 20.8011H15.5679C16.0051 20.8011 16.3595 21.1556 16.3595 21.5927V22.2084C16.3595 22.6456 16.0051 23 15.5679 23H0.791587C0.354406 23 0 22.6456 0 22.2084V21.5927Z" fill="#32353B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8805 2.00737C16.2412 1.68211 16.7974 1.71089 17.1227 2.07167L22.1522 7.65034C22.3047 7.81942 22.3875 8.03991 22.3841 8.26754L22.2389 17.9423C22.2147 19.5529 20.8727 20.8298 19.2629 20.774C17.8089 20.7236 16.6215 19.5956 16.4966 18.1462L16.1275 13.8652C16.0883 13.4104 15.7077 13.0612 15.2512 13.0612H14.5344V11.3021H15.2512C16.6207 11.3021 17.7625 12.3497 17.8801 13.7141L18.2492 17.9951C18.2977 18.5582 18.759 18.9964 19.3238 19.016C19.9493 19.0377 20.4706 18.5416 20.48 17.9159L20.6202 8.57801L15.8162 3.24957C15.4909 2.88879 15.5197 2.33264 15.8805 2.00737Z" fill="#32353B"/>
    <path d="M18.1845 8.2538V5.40993C18.1845 5.39168 18.2055 5.38137 18.2199 5.39252L21.2543 7.73336C21.2597 7.73752 21.2629 7.74395 21.2629 7.75077V12.1728C21.2629 12.1924 21.2393 12.2022 21.2254 12.1884L18.8341 9.81419C18.4183 9.40138 18.1845 8.8397 18.1845 8.2538Z" fill="#32353B"/>
</svg>

<svg *ngIf="type === 'trash'" width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52608 28.3278C3.46133 28.0259 3.42725 27.7126 3.42725 27.3913C3.42725 24.9369 5.41679 22.9476 7.87095 22.9476C7.93316 22.9476 7.99507 22.9494 8.05669 22.9517C8.71312 20.3942 11.0336 18.5039 13.7959 18.5039C16.0698 18.5039 18.0443 19.7849 19.0378 21.6643C19.4903 21.5352 19.9676 21.4664 20.4615 21.4664C23.1751 21.4664 25.4016 23.5511 25.627 26.2063H25.6458C26.7911 26.2063 27.7195 27.135 27.7195 28.2801C27.7195 28.296 27.7193 28.3119 27.719 28.3278H3.52608Z" fill="#32353B"/>
    <rect x="2.21387" y="28.3281" width="26.7215" height="1.82192" rx="0.91096" fill="#32353B"/>
    <rect x="2.21436" y="23.6172" width="3.64384" height="6.07307" rx="1.82192" transform="rotate(-45 2.21436 23.6172)" fill="#32353B"/>
    <path d="M21.5981 20.86C22.5494 19.8808 24.1037 19.8261 25.1215 20.7359C25.7706 21.316 26.0784 22.1875 25.9375 23.0466L25.6915 24.5471C25.3783 26.4575 23.3757 27.5857 21.5795 26.8637C19.5845 26.0619 19.0401 23.4926 20.5384 21.9505L21.5981 20.86Z" fill="#32353B"/>
    <rect x="2.58154" y="22.1865" width="1.09577" height="5.78014" rx="0.5" transform="rotate(-45 2.58154 22.1865)" fill="#32353B"/>
    <path d="M24.5297 20.9271C24.3893 21.0301 24.1934 20.917 24.2125 20.7439L24.3471 19.5232C24.363 19.3797 24.5209 19.2998 24.6459 19.3719L25.5012 19.8657C25.6262 19.9379 25.6359 20.1147 25.5196 20.2001L24.5297 20.9271Z" fill="#32353B"/>
    <path d="M18.4805 13.1871C18.261 12.3524 19.0979 11.4088 20.3499 11.0795C21.6019 10.7501 22.9274 11.6636 23.1469 12.4982C22.9274 11.6636 23.6318 10.2162 24.8838 9.88687C26.1358 9.55754 27.3287 9.96719 27.5483 10.8019" stroke="#32353B" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M1.20951 10.1393C1.0938 9.69937 1.5349 9.20207 2.19474 9.0285C2.85457 8.85494 3.55311 9.33633 3.66882 9.77622C3.55311 9.33633 3.92438 8.57353 4.58421 8.39997C5.24405 8.2264 5.87275 8.4423 5.98846 8.88219" stroke="#32353B" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M15.1633 2.06159C15.3204 1.47529 16.1607 1.19103 17.0401 1.42668C17.9196 1.66233 18.4104 2.68251 18.2533 3.26881C18.4104 2.68251 19.3455 2.04439 20.2249 2.28004C21.1044 2.51569 21.69 3.18201 21.5329 3.76831" stroke="#32353B" stroke-width="1.5" stroke-linecap="round"/>
</svg>
    