import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PlumesPage } from '@cityair/modules/plumes/components/plumes-page/plumes-page.component';
import { Notifications } from '@cityair/modules/notifications/components/notifications/notifications.component';
import { PostsAndDevicesPageComponent } from '@cityair/modules/posts-and-devices/components/posts-and-devices-page/posts-and-devices-page.component';
import { networksRoutes } from '@cityair/modules/posts-and-devices/posts-and-devices.route';
import { IndoorMainComponent } from '@cityair/modules/indoor/components/indoor-main/indoor-main.component';
import { forecastRoutes } from '@cityair/modules/forecast/forecast-routing';
import { MAIN_PAGES } from '@libs/common';
import { GlobalMapComponent } from './components/global-map/global-map.component';
import { IndoorWidgetComponent } from '@cityair/modules/indoor-widget/indoor-widget.component';

import { ResetPanelStateGuard } from './guards/reset-panel-state.guard';
import { analysisRoutes } from '@cityair/modules/analysis/analysis-routing';
import { eventsRoutes, impactRoutes } from '@cityair/modules/impact/impact-routing';
import { NetworkMainComponent } from '@cityair/modules/network/components/network-main/network-main.component';

export const cityScreenPageRoutes: Routes = [
    {
        path: MAIN_PAGES.analytics,
        loadComponent: () => import('@analytics').then((m) => m.AnalyticsPageComponent),
    },
    {
        path: MAIN_PAGES.plumes,
        canActivate: [ResetPanelStateGuard],
        component: PlumesPage,
    },
    {
        path: MAIN_PAGES.indoor,
        component: IndoorMainComponent,
    },
    {
        path: MAIN_PAGES.notifications,
        component: Notifications,
    },
    {
        path: MAIN_PAGES.settings,
        loadChildren: () => import('@settings').then(m => m.SettingsModule)
    },
    {
        path: MAIN_PAGES.networks,
        component: PostsAndDevicesPageComponent,
        children: networksRoutes,
    },
    {
        path: MAIN_PAGES.reports,
        loadChildren: () => import('@reports').then(m => m.ReportsModule)
    },
    {
        path: MAIN_PAGES.forecast,
        children: forecastRoutes,
    },
    {
        path: MAIN_PAGES.global,
        component: GlobalMapComponent,
    },
    {
        path: MAIN_PAGES.indoorWidget,
        component: IndoorWidgetComponent,
    },
    {
        path: MAIN_PAGES.analysis,
        children: analysisRoutes,
    },
    {
        path: MAIN_PAGES.analysisEvents,
        loadChildren: () => import('@analysis-events').then(m => m.AnalysisEventsModule)
    },
    {
        path: MAIN_PAGES.impact,
        children: impactRoutes,
    },
    {
        path: MAIN_PAGES.network,
        component: NetworkMainComponent,
    },
    {
        path: MAIN_PAGES.events,
        children: eventsRoutes,
    },
];

@NgModule({
    imports: [RouterModule.forChild(cityScreenPageRoutes)],
    exports: [RouterModule],
})
export class CityscreenPageRoutingModule {}
