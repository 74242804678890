import { Pipe, PipeTransform } from '@angular/core';
import { ExcessStatistic, SourceImpact } from '@cityair/modules/impact/service/api-model-impact';

import {
    IMPACT_CHART_COLOR_MMT,
    SOURCE_NUMBER_LIMIT_STATS_REGION,
} from '@cityair/modules/impact/consts';
import { CONTRIBUTIONS_COLORS_SOURCES_ORDER, getColorByIndex, TEXTS } from '@libs/common';

@Pipe({ name: 'chartDataExceedRegion' })
export class ChartDataExceedRegionPipe implements PipeTransform {
    transform(
        statsData: ExcessStatistic,
        sources: SourceImpact[],
        showAll: boolean,
        otherLabel?: string
    ): { values: number[]; labels?: string[]; colors?: string[] } {
        const valuesData = Object.values(statsData.sources_contribution);
        const isEmpty = valuesData.every((v) => v === 0);
        if (isEmpty) {
            return { values: [] };
        }
        const sourcesKey = Object.keys(statsData.sources_contribution);
        const data = sourcesKey?.map((id) => {
            const source = sources.find((v) => v.id === Number(id));
            if (source) {
                return { value: statsData.sources_contribution[id], label: source.name };
            }
        });

        data.sort((a, b) => {
            if (a.value < b.value) {
                return 1;
            }
            if (a.value > b.value) {
                return -1;
            }

            return 0;
        });
        if (!showAll && otherLabel && data.length > SOURCE_NUMBER_LIMIT_STATS_REGION) {
            data.length = SOURCE_NUMBER_LIMIT_STATS_REGION;
            const sum = data.reduce(function (acc, item) {
                return acc + item.value;
            }, 0);
            const otherValue = +(100 - sum).toFixed(2);
            data.push({ value: otherValue, label: otherLabel });
        }
        return {
            values: data.map((v) => +v.value.toFixed(2)),
            labels: data.map((v) => v.label),
            colors: data.map((v, index) =>
                getColorByIndex(CONTRIBUTIONS_COLORS_SOURCES_ORDER, index)
            ),
        };
    }
}
@Pipe({ name: 'chartDataAverageMmtRegion' })
export class ChartDataAverageMmtRegionPipe implements PipeTransform {
    transform(statsData: ExcessStatistic): {
        values: number[];
        labels?: string[];
        colors?: string[];
    } {
        const valuesData = Object.values(statsData.average_concentration);
        const isEmpty = valuesData.every((v) => v === 0);
        if (isEmpty) {
            return { values: [] };
        }
        const sourcesKey = Object.keys(statsData.average_concentration);
        const data = sourcesKey?.map((id) => ({
            value: statsData.average_concentration[id],
            label: TEXTS.NAMES[id] ?? id,
            color: IMPACT_CHART_COLOR_MMT[id] ?? IMPACT_CHART_COLOR_MMT.default,
        }));

        data.sort((a, b) => {
            if (a.value < b.value) {
                return 1;
            }
            if (a.value > b.value) {
                return -1;
            }

            return 0;
        });

        return {
            values: data.map((v) => +v.value.toFixed(0)),
            labels: data.map((v) => v.label),
            colors: data.map((v) => v.color),
        };
    }
}
@Pipe({ name: 'chartDataNumberExceedRegion' })
export class ChartDataNumberExceedRegionPipe implements PipeTransform {
    transform(statsData: ExcessStatistic): {
        values: number[];
        labels?: string[];
        colors?: string[];
    } {
        const valuesData = Object.values(statsData.number_exceed_concentration);
        const isEmpty = valuesData.every((v) => v === 0);
        if (isEmpty) {
            return { values: [] };
        }
        const sourcesKey = Object.keys(statsData.number_exceed_concentration);
        const data = sourcesKey?.map((id) => ({
            value: statsData.number_exceed_concentration[id],
            label: TEXTS.NAMES[id] ?? id,
            color: IMPACT_CHART_COLOR_MMT[id] ?? IMPACT_CHART_COLOR_MMT.default,
        }));

        data.sort((a, b) => {
            if (a.value < b.value) {
                return 1;
            }
            if (a.value > b.value) {
                return -1;
            }

            return 0;
        });

        return {
            values: data.map((v) => +v.value.toFixed(0)),
            labels: data.map((v) => v.label),
            colors: data.map((v) => v.color),
        };
    }
}
