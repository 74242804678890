import { Pipe, PipeTransform } from '@angular/core';
import { RunImpact, SourceImpact } from '../service/api-model-impact';
import { TIME_PREPARE_PLUMES } from './../consts';
import { ImpactEmissionValue } from '@cityair/modules/impact/models';

@Pipe({ name: 'impactSourceEmission' })
export class ImpactSourceEmissionPipe implements PipeTransform {
    transform(source: SourceImpact, run: RunImpact, mmt: string, isTotal: boolean): number {
        let emission;
        if (mmt) {
            emission = source.emissions
                .filter((data) => data.emissions_species === mmt)
                .reduce(function (acc, emission) {
                    return acc + emission.emissions_mass / 10000;
                }, 0);
        } else {
            emission = source.emissions.reduce(function (acc, emission) {
                return acc + emission.emissions_mass / 10000;
            }, 0);
        }
        const time_d = run.duration_minutes;
        const value = emission * (time_d + TIME_PREPARE_PLUMES); // kg
        return isTotal ? value * 31.536 : value / 1000;
    }
}
@Pipe({ name: 'impactSourceEmissionTotal' })
export class ImpactSourceEmissionTotalPipe implements PipeTransform {
    transform(sources: SourceImpact[], run: RunImpact): ImpactEmissionValue {
        const emissionTotal: ImpactEmissionValue = {};
        const mmts = run?.species_list ?? [];
        mmts?.forEach((mmt) => {
            let total = 0;
            sources?.forEach((source) => {
                const emission = source.emissions
                    .filter((data) => data.emissions_species === mmt)
                    .reduce(function (acc, emission) {
                        return acc + emission.emissions_mass;
                    }, 0);
                total += emission;
            });
            const time_d = run.duration_minutes;
            const value = total * (time_d + TIME_PREPARE_PLUMES); // kg
            emissionTotal[mmt] = value * 31.536;
        });
        return emissionTotal;
    }
}
@Pipe({ name: 'impactTotalEmission' })
export class ImpactTotalEmissionPipe implements PipeTransform {
    transform(total: ImpactEmissionValue): number {
        const arr = Object.values(total);
        return arr.reduce(function (acc, emission) {
            return acc + emission / 10000;
        }, 0);
    }
}
@Pipe({ name: 'impactEmissionPercentByTotal' })
export class ImpactEmissionPercentByTotalPipe implements PipeTransform {
    transform(total: ImpactEmissionValue, mmt: string): number {
        const arr = Object.values(total);
        const totalValue = arr.reduce(function (acc, emission) {
            return acc + emission / 10000;
        }, 0);
        return total[mmt] / 100 / totalValue;
    }
}
