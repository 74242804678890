import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { CalendarMonthsModule, LittleComponentsModule } from '@libs/shared-ui';
import { PipesModule } from '@libs/shared-ui';
import { DirectivesModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';
import { PageTabsModule } from '@libs/shared-ui';
import { CardModule, CardActionsModule } from '@libs/shared-ui';
import { InputModule } from '@libs/shared-ui';
import { InputForCoordinateModule } from '@libs/shared-ui';
import { LoadersModule } from '@libs/shared-ui';
import { ButtonAddModule } from '@libs/shared-ui';
import { OverlayModule } from '@libs/shared-ui';
import { SwitchItemModule } from '@libs/shared-ui';
import { KebabMenuModule } from '@libs/shared-ui';
import { ColorLegendModule } from '@libs/shared-ui';
import { SwitchButtonModule } from '@libs/shared-ui';
import { AqiDetailsTooltipModule } from '@libs/shared-ui';
import { TimelinePanelModule } from '@libs/shared-ui';
import { ButtonModule } from '@libs/shared-ui';
import { ButtonsSaveCancelModule } from '@libs/shared-ui';
import { MapControlModule } from '@libs/shared-ui';
import { MmtSelectorModule } from '@libs/shared-ui';
import { HeightsSelectorModule } from '@libs/shared-ui';
import { InputForDropdownModule } from '@libs/shared-ui';
import { InputDropdownModule } from '@libs/shared-ui';
import { SelectboxRadioModule } from '@libs/shared-ui';
import { MapStyleSelectorComponent } from '@libs/shared-ui';
import { ImpactMainComponent } from '@cityair/modules/impact/components/impact-main.component/impact-main.component';
import { ControlPointsComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/control-points.component';
import { ImpactRunsComponent } from '@cityair/modules/impact/components/impact-main.component/impact-runs/impact-runs.component';
import { ImpactRunResultsComponent } from '@cityair/modules/impact/components/impact-main.component/impact-run-results/impact-run-results.component';
import { ImpactMapElementComponent } from '@cityair/modules/impact/components/impact-map.component/impact-map-element/impact-map-element.component';
import { ImpactMapComponent } from '@cityair/modules/impact/components/impact-map.component/impact-map.component';
import { ImpactRunTimePipe } from '@cityair/modules/impact/pipes/run-time.pipe';
import { FinishRunTimePipe } from '@cityair/modules/impact/pipes/finish-run-time.pipe';
import { ConfigRunCardComponent } from '@cityair/modules/impact/components/impact-main.component/impact-runs/config-run-card/config-run-card.component';
import { DurationsRunPipe } from '@cityair/modules/impact/pipes/config-duration.pipe';
import { ConfigPeriodTimePipe } from '@cityair/modules/impact/pipes/config-period-time.pipe';
import { ImpactEditRunComponent } from '@cityair/modules/impact/components/impact-main.component/impact-runs/impact-edit-run/impact-edit-run.component';
import { ImpactRunResultComponent } from './components/impact-main.component/impact-run-result/impact-run-result.component';
import { ImpactControlPointComponent } from './components/impact-main.component/control-points/impact-points/impact-control-point/impact-control-point.component';
import { NonDataScreenModule } from '@libs/shared-ui';
import { ValueCircleModule } from '@libs/shared-ui';
import { ImpactCitiesComponent } from './components/impact-main.component/impact-cities/impact-cities.component';
import { ImpactSourcesComponent } from './components/impact-main.component/impact-sources/impact-sources.component';
import { WidgetComponent } from './components/impact-main.component/widget/widget.component';
import { SortListItemModule } from '@libs/shared-ui';
import { AnalyticModule } from '@libs/shared-ui';
import { CityDistrictChartModule } from './components/impact-main.component/city-district-chart/city-district-chart.module';
import { ImpactRunSelectorComponent } from './components/impact-map.component/impact-map-element/impact-run-selector/impact-run-selector.component';
import { ItemRadioModule } from '@libs/shared-ui';
import { ImpactSourceComponent } from './components/impact-main.component/impact-source/impact-source.component';
import { SourceTypeIconComponent } from './components/impact-main.component/source-type-icon/source-type-icon.component';
import { ImpactMapObjectSelectorComponent } from './components/impact-map.component/impact-map-element/impact-map-object-selector/impact-map-object-selector.component';
import { SelectboxModule } from '@libs/shared-ui';
import { ImpactEventListComponent } from './components/impact-main.component/impact-event-list/impact-event-list.component';
import { DataForLocalityWidgetPipe } from './pipes/data-locality-widget.pipe';
import { MaxValueEventPipe } from './pipes/max-value-event.pipe';
import { ImpactEventDetailComponent } from './components/impact-main.component/impact-event-list/impact-event-detail/impact-event-detail.component';
import { ImpactEventCardComponent } from './components/impact-main.component/impact-event-list/impact-event-card/impact-event-card.component';
import { BaseChartDirective } from 'ng2-charts';
import { RepeatExceedEventPipe } from './pipes/repeat-exceed-event.pipe';
import { DataSourceEventPipe } from './pipes/data-source-event.pipe';
import { InfoIconModule } from '@libs/shared-ui';
import { InfoCorrelationPopupModule } from '@libs/shared-ui';
import { CorrelationChartDataPipe } from './pipes/correlation-chart-data.pipe';
import { AreaChartImpactComponent } from './components/impact-main.component/impact-event-list/impact-event-detail/area-chart/area-chart.component';
import { ChartPostCorrelationComponent } from './components/impact-main.component/impact-event-list/impact-event-detail/chart-post-correlation/chart-post-correlation.component';
import { ChartDataPostPipe } from './pipes/chart-data-post.pipe';
import { EventContributionPipe } from './pipes/event-contribution.pipe';
import { EventContributionByTimePipe } from './pipes/event-contibution-by-time.pipe';
import { PostIdsByEventsPipe } from './pipes/post-ids-by-events.pipe';
import { OrderEventsPipe } from './pipes/order-events.pipe';
import { ControlPointFormComponent } from './components/impact-main.component/control-points/impact-points/control-point-form/control-point-form.component';
import { HeaderOfPanelModule } from '@libs/shared-ui';
import { StaticTagsModule } from '@libs/shared-ui';
import { SectionLineModule } from '@libs/shared-ui';
import { BtnExtraModule } from '@libs/shared-ui';
import { BtnCancelModule } from '@libs/shared-ui';
import { BtnAcceptModule } from '@libs/shared-ui';
import { PopupAskModule } from '@libs/shared-ui';
import { ImpactPointsComponent } from './components/impact-main.component/control-points/impact-points/impact-points.component';
import { ImpactRegionsComponent } from './components/impact-main.component/control-points/impact-regions/impact-regions.component';
import { ImpactRegionDetailComponent } from './components/impact-main.component/control-points/impact-regions/impact-region-detail/impact-region-detail.component';
import { ImpactPostsComponent } from './components/impact-main.component/control-points/impact-posts/impact-posts.component';
import { ImpactMmtItemComponent } from './components/impact-main.component/mmt-item/mmt-item.component';
import { ImpactSourceTypePipe } from './pipes/source-type.pipe';
import {
    ImpactEmissionPercentByTotalPipe,
    ImpactSourceEmissionPipe,
    ImpactSourceEmissionTotalPipe,
    ImpactTotalEmissionPipe,
} from './pipes/source-emission.pipe';
import { ImpactSourceSquarePipe } from './pipes/source-square.pipe';
import { CityPopulationPipe } from '@cityair/modules/impact/pipes/city-population.pipe';
import { OrderByRegionsPipe } from '@cityair/modules/impact/pipes/orderByRegions.pipe';
import { ImpactDensityRegionPipe } from '@cityair/modules/impact/pipes/density-region.pipe';
import { ControlPointsByRegionPipe } from '@cityair/modules/impact/pipes/control-points-by-region.pipe';
import { ImpactLineChartComponent } from './components/impact-main.component/impact-line-chart/impact-line-chart.component';
import { StoreModule } from '@ngrx/store';
import { impactStatFeature } from './store/statistic.feature';
import { ImpactStatsEffects } from '@cityair/modules/impact/store/statistic.effects';
import { EffectsModule } from '@ngrx/effects';
import {
    ChartDataAverageMmtRegionPipe,
    ChartDataExceedRegionPipe,
    ChartDataNumberExceedRegionPipe,
} from '@cityair/modules/impact/pipes/chart-data-exceed-region.pipe';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        StoreModule.forFeature(impactStatFeature),
        EffectsModule.forFeature([ImpactStatsEffects]),
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        LittleComponentsModule,
        CardModule,
        TooltipsModule,
        ButtonAddModule,
        CalendarModule,
        PageTabsModule,
        CardActionsModule,
        InputModule,
        InputForCoordinateModule,
        CalendarMonthsModule,
        LoadersModule,
        OverlayModule,
        TimelinePanelModule,
        SwitchItemModule,
        AqiDetailsTooltipModule,
        KebabMenuModule,
        ColorLegendModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        SwitchButtonModule,
        MapControlModule,
        MmtSelectorModule,
        HeightsSelectorModule,
        InputForDropdownModule,
        InputDropdownModule,
        SelectboxRadioModule,
        MapStyleSelectorComponent,
        NonDataScreenModule,
        ValueCircleModule,
        SortListItemModule,
        AnalyticModule,
        CityDistrictChartModule,
        ItemRadioModule,
        SelectboxModule,
        BaseChartDirective,
        InfoIconModule,
        InfoCorrelationPopupModule,
        HeaderOfPanelModule,
        StaticTagsModule,
        SectionLineModule,
        BtnExtraModule,
        BtnCancelModule,
        BtnAcceptModule,
        PopupAskModule,
    ],
    exports: [ImpactMapComponent],
    declarations: [
        ImpactMainComponent,
        ControlPointsComponent,
        ImpactRunsComponent,
        ImpactRunResultsComponent,
        ImpactMapComponent,
        ImpactMapElementComponent,
        ImpactRunTimePipe,
        FinishRunTimePipe,
        ConfigRunCardComponent,
        DurationsRunPipe,
        ConfigPeriodTimePipe,
        ImpactEditRunComponent,
        ImpactRunResultComponent,
        ImpactControlPointComponent,
        ImpactCitiesComponent,
        ImpactSourcesComponent,
        WidgetComponent,
        ImpactSourceComponent,
        SourceTypeIconComponent,
        ImpactRunSelectorComponent,
        ImpactMapObjectSelectorComponent,
        ImpactEventListComponent,
        DataForLocalityWidgetPipe,
        MaxValueEventPipe,
        ImpactEventDetailComponent,
        ImpactEventCardComponent,
        RepeatExceedEventPipe,
        DataSourceEventPipe,
        CorrelationChartDataPipe,
        AreaChartImpactComponent,
        ChartPostCorrelationComponent,
        ChartDataPostPipe,
        EventContributionPipe,
        EventContributionByTimePipe,
        ImpactRegionsComponent,
        ImpactRegionDetailComponent,
        PostIdsByEventsPipe,
        OrderEventsPipe,
        ControlPointFormComponent,
        ImpactPointsComponent,
        ImpactPostsComponent,
        ImpactMmtItemComponent,
        ImpactSourceTypePipe,
        ImpactSourceEmissionPipe,
        ImpactSourceSquarePipe,
        CityPopulationPipe,
        OrderByRegionsPipe,
        ImpactSourceEmissionTotalPipe,
        ImpactTotalEmissionPipe,
        ImpactEmissionPercentByTotalPipe,
        ImpactDensityRegionPipe,
        ControlPointsByRegionPipe,
        ImpactLineChartComponent,
        ChartDataExceedRegionPipe,
        ChartDataNumberExceedRegionPipe,
        ChartDataAverageMmtRegionPipe,
    ],
})
export class ImpactModule {}
