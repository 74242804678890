import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MAX_CONCENTRATION_DEMO_DATA } from '@cityair/modules/impact/consts';
import { DateRangeImpact, SourceImpact } from '@cityair/modules/impact/service/api-model-impact';
import {
    selectControlPoints,
    selectIsInitRegionPage,
    selectMmtsListByRegion,
} from '@cityair/modules/impact/store/impact.feature';
import { TEXTS } from '@libs/common';
import { CheckboxItem } from '@libs/common';
import { Store } from '@ngrx/store';
import moment from 'moment-timezone';
import { Subject, takeUntil } from 'rxjs';
import { RegionApi } from '@libs/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MAIN_PAGES } from '@libs/common';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { filter, take } from 'rxjs/operators';
import { selectCurrentMeasureScheme, selectExtConfig } from '@libs/shared-store';
import {
    ImpactStatActions,
    selectExcessStatistics,
    selectIsLoadingStatRegion,
    selectDateRangeRegion,
} from './../../../../../store/statistic.feature';

@Component({
    selector: 'impact-region-detail',
    templateUrl: './impact-region-detail.component.html',
    styleUrls: ['./impact-region-detail.component.less'],
})
export class ImpactRegionDetailComponent implements OnInit, OnDestroy {
    public textImpact = TEXTS.IMPACT;
    public textNames = TEXTS.NAMES;
    public textsScheme = TEXTS.MEASURES_SCHEME;
    public currentMeasure: string;
    public calendarIsOpened = false;
    public region: RegionApi;
    public sources: SourceImpact[];
    handleUpdateDays: (numberOfFutureDays?: number, minDate?: string) => void;
    public numberOfFutureDays = 1;
    public dateRange: DateRangeImpact;
    public dateTimeBegin: number;
    public dateTimeEnd: number;
    public ngDestroyed$ = new Subject<void>();
    public mmtList: CheckboxItem[] = [];
    public selectedSourceMmt: CheckboxItem;
    selectControlPoints = selectControlPoints;
    isLoadingStatRegion = selectIsLoadingStatRegion;
    selectExcessStatistics = selectExcessStatistics;
    public isShowDropdownForMmtList: boolean = false;
    selectMeasureScheme = selectCurrentMeasureScheme;
    minDate: string;
    loading = true;
    currentId: string;
    sourcesOrder: number[];
    MAX_CONCENTRATION_DEMO_DATA = MAX_CONCENTRATION_DEMO_DATA;

    constructor(
        public store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.currentId = this.route.snapshot.paramMap.get('id');
        if (this.currentId) {
            this.store
                .select(selectExtConfig)
                .pipe(
                    takeUntil(this.ngDestroyed$),
                    filter((data) => data !== null),
                    take(1)
                )
                .subscribe((data) => {
                    if (data?.impactSettings?.startDate) {
                        this.minDate = data.impactSettings.startDate;
                    }
                });
            this.store
                .select(selectIsInitRegionPage)
                .pipe(
                    takeUntil(this.ngDestroyed$),
                    filter((data) => data !== null),
                    take(1)
                )
                .subscribe((data) => {
                    this.region = data?.regions.find((v) => v.id === this.currentId);
                    this.sources = data?.sources;
                    if (this.region) {
                        this.loading = false;
                        this.store.dispatch(ImpactStatActions.setRegion({ payload: this.region }));
                    } else {
                        this.backToPoints();
                    }
                    _changeDetectorRef.markForCheck();
                });
        } else {
            this.backToPoints();
        }
        this.store
            .select(selectDateRangeRegion)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((dates) => {
                this.dateRange = dates;
                this.dateTimeBegin = moment(this.dateRange.start).valueOf();
                this.dateTimeEnd = moment(this.dateRange.end).valueOf();
            });
    }

    ngOnInit(): void {
        this.store
            .select(selectMmtsListByRegion)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => v !== null)
            )
            .subscribe(({ list, mmt }) => {
                if (list?.length) {
                    list.map((item) =>
                        this.mmtList.push({
                            id: item,
                            label: this.textNames[item],
                            selected: item === mmt,
                            value: item,
                        })
                    );
                    this.selectedSourceMmt = this.mmtList.find((v) => v.selected);
                    this._changeDetectorRef.markForCheck();
                }
            });
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
        this.ngDestroyed$.complete();
        this.store.dispatch(ImpactStatActions.setExcessStatistics({ payload: null }));
        this.store.dispatch(ImpactStatActions.setRegion({ payload: null }));
    }

    toggleCalendar() {
        if (this.calendarIsOpened) {
            if (this.minDate) {
                this.handleUpdateDays(this.numberOfFutureDays, this.minDate);
            } else {
                this.handleUpdateDays(this.numberOfFutureDays);
            }
        }
    }

    public updateDateRange = (begin: number, finish: number) => {
        this.calendarIsOpened = false;
        const start = moment(begin).startOf('day').toISOString();
        const end = moment(finish).endOf('day').toISOString();
        this.store.dispatch(ImpactStatActions.updateDateRangeRegion({ payload: { start, end } }));
    };

    updateDays = (fn: (numberOfFutureDays?: number, minDate?: string) => void) =>
        (this.handleUpdateDays = fn);

    selectSourceMmt(list: CheckboxItem[]) {
        const selectedMmt = list.find((v) => v.selected)?.value;
        this.store.dispatch(
            ImpactStatActions.updateRegionMmt({
                payload: selectedMmt,
            })
        );
    }

    backToPoints() {
        this.router.navigate([
            `/${MAIN_PAGES.impact}/${IMPACT_PAGES.controlPoints}/${IMPACT_PAGES.regions}`,
        ]);
    }
}
