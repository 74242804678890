import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GradientSliderComponent } from './gradient-slider.component';

@NgModule({
    imports: [CommonModule],
    exports: [GradientSliderComponent],
    declarations: [GradientSliderComponent],
})
export class GradientSliderModule {}
