import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TEXTS } from '@libs/common';
import { LayerButtonIconType } from '@libs/common';
import { ANIMATION_OPACITY } from '@libs/common';
import { selectSidebarIsOpen } from '@libs/shared-store';
import {
    ImpactActions,
    selectActiveRun,
    selectActiveMmt,
    selectSpeciesList,
    selectRunsImpact,
    selectDisplayObjOnMap,
} from '@cityair/modules/impact/store/impact.feature';
import { RunImpact } from '@cityair/modules/impact/service/api-model-impact';
import { CheckboxItem } from '@libs/common';

@Component({
    selector: 'ca-impact-map-element',
    templateUrl: 'impact-map-element.component.html',
    styleUrls: ['impact-map-element.component.less'],
    animations: ANIMATION_OPACITY,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactMapElementComponent {
    textNames = TEXTS.NAMES;
    textOnMap = TEXTS.MAP_CONTROL.on;
    textsSwitchButton = TEXTS.PLUMES.switchPostButton;
    textTitleMapControl = TEXTS.PLUMES.forecastOn + ':';
    LayerButtonIconType = LayerButtonIconType;

    selectSidebarIsOpen = selectSidebarIsOpen;
    selectActiveRun = selectActiveRun;
    selectActiveMmt = selectActiveMmt;
    selectSpeciesList = selectSpeciesList;
    selectRunsImpact = selectRunsImpact;
    selectDisplayObjOnMap = selectDisplayObjOnMap;
    constructor(public store: Store) {}

    changeMmt(mmt) {
        this.store.dispatch(ImpactActions.setActiveMmt({ payload: mmt }));
    }

    changeRun(run: RunImpact) {
        this.store.dispatch(ImpactActions.setActiveRun({ payload: run }));
    }

    mapObjectChanges(list: CheckboxItem[]) {
        const listItems = [...list];
        this.store.dispatch(ImpactActions.setMapObjectTypes({ payload: listItems }));
    }
}
