import { RasterSource } from 'mapbox-gl';
import { MeasureScheme } from '@libs/common';
import { GroupChartConfig } from '@libs/common';
import { GroupTooltipsMmt } from '@libs/common';
import { GroupMapSettings } from '@libs/common';
import { GroupExtConfigName } from '@libs/common';
import { AnalysisModuleSettings } from '@libs/common';

export interface GroupTilePlayerSettings {
    datesRange: string[];
    timeStep: number;
    layerId?: string;
    opacity?: number;
    dateFormat?: string;
    layer: RasterSource;
}
export interface IFloorPin {
    label: string;
    coordinates?: [number, number];
}
export interface IOutdoorPinConfig {
    id: number;
    coordinates?: [number, number];
}
export interface IndoorConfig {
    imgSize: [number, number];
    zoom: number;
    minZoom?: number;
    maxZoom?: number;
    imgBackgroundColor: string;
    pinsCoordinatesInPixels: {
        [pinId: number]: [number, number];
    };
    imgSourcePath?: string;
    floorPin?: IFloorPin;
    maxBounds?: [number, number, number, number];
    outdoorPinsConfig?: IOutdoorPinConfig[];
}
export class PinsDataFormat {
    type: string;
    valueType: number;
    zones?: number[];
    colors?: string[];
    measureScheme?: MeasureScheme;
}
export interface ComfortConditionSettings {
    WorkDays: boolean[];
    WorkHoursBegin: number;
    WorkHoursEnd: number;
    TemperatureMin: number;
    TemperatureMax: number;
    HumidityMin: number;
    HumidityMax: number;
    Co2Max: number;
    logo?: ILogoIndoor;
    showGlobalLink?: boolean;
}
export interface IOutdoorPinConfig {
    id: number;
    coordinates?: [number, number];
}
export interface IndoorConfig {
    imgSize: [number, number];
    zoom: number;
    minZoom?: number;
    maxZoom?: number;
    imgBackgroundColor: string;
    pinsCoordinatesInPixels: {
        [pinId: number]: [number, number];
    };
    imgSourcePath?: string;
    floorPin?: IFloorPin;
    maxBounds?: [number, number, number, number];
    outdoorPinsConfig?: IOutdoorPinConfig[];
}

export interface ForecastConfig {
    calendarMinDate: string;
    numberOfFutureDays: number;
    domain: string;
}
export interface ILogoIndoor {
    imgPath?: string;
    className?: string;
}
export interface ComfortConditionSettings {
    WorkDays: boolean[];
    WorkHoursBegin: number;
    WorkHoursEnd: number;
    TemperatureMin: number;
    TemperatureMax: number;
    HumidityMin: number;
    HumidityMax: number;
    Co2Max: number;
    logo?: ILogoIndoor;
    showGlobalLink?: boolean;
}
export interface FeaturesConfigType {
    [GroupExtConfigName.startModule]?: string; // example: plume
    [GroupExtConfigName.defaultMo]?: number;
    [GroupExtConfigName.mapSettings]?: GroupMapSettings;
    [GroupExtConfigName.chartMinMax]?: GroupChartConfig;
    [GroupExtConfigName.chartSingleSelect]?: boolean;
    [GroupExtConfigName.tilePlayerSettings]?: GroupTilePlayerSettings;
    [GroupExtConfigName.plumeMeasure]?: string;
    [GroupExtConfigName.showQRCode]?: boolean;
    [GroupExtConfigName.pinsDataFormat]?: PinsDataFormat;
    [GroupExtConfigName.disablePlumesRunNavigation]?: boolean;
    [GroupExtConfigName.enableTSP]?: boolean;
    [GroupExtConfigName.commonMaxPDK]?: number;
    [GroupExtConfigName.showNewAnalityc]?: boolean;
    [GroupExtConfigName.showForecastModule]?: boolean;
    [GroupExtConfigName.forecastMapSettings]?: GroupMapSettings;
    [GroupExtConfigName.forecastConfig]?: ForecastConfig;
    [GroupExtConfigName.indoorConfig]?: IndoorConfig;
    [GroupExtConfigName.ComfortConditionSettings]?: ComfortConditionSettings;
    [GroupExtConfigName.plumesStationShowFullChartData]?: boolean;
    [GroupExtConfigName.chartTooltipByMeasurements]?: GroupTooltipsMmt;
    [GroupExtConfigName.dataQualityPercent]?: number;
    [GroupExtConfigName.isDemoModeForEvents]?: boolean;
    [GroupExtConfigName.excludeIndexes]?: string;
    [GroupExtConfigName.analysisModuleSettings]?: AnalysisModuleSettings;
    [GroupExtConfigName.showSettingsModule]?: boolean;
}
export function prepareExtConfig(data: { [key: string]: string }) {
    const extConfig: FeaturesConfigType = {};

    Object.keys(data || {}).forEach((key) => {
        const str = data[key];
        let value: string;

        try {
            value = JSON.parse(str);
        } catch (e) {
            value = str;
        }

        extConfig[key] = value;
    });
    return extConfig;
}
