import { MeasureScheme } from '@libs/common';
import { HISTOGRAM_AQI } from '../consts/histogram-aqi.const';
import { IZA } from '../consts/iza.conts';
import { PDK } from '../consts/pdk.const';
import { ROOM } from '../consts/room.const';
import {
    AQI,
    HUM,
    PM10,
    PM25,
    SO2,
    CH2O,
    CO,
    CO2,
    O3,
    NO,
    H2S,
    NH3,
    NO2,
    TEMP,
    PRES,
    WDA,
    WVA,
    SUM,
    TSP,
    CS137,
    WVVA,
    NOISELEVEL,
    T,
    SP,
    R2,
    WD,
    WV,
    WDA_V,
    WVA_V,
} from '../consts/substance.consts';
import { S, E, NE, NW, SE, SW, W, N } from '../consts/wind-directions.const';
import { AqiType } from '../enums/aqi.type';
import { StndAskPopupTexts } from '../types/stnd-ask-popup-texts';

export const ugM = 'мкг/м<sup>3</sup>';
export const mgM = 'мг/м<sup>3</sup>';
export const shareOfMPC = 'Доли ПДК';

// используется для группировки коефициентов графика
export const MEASURES = {
    [AQI]: 'баллы',
    [AqiType.indoor]: 'баллы',
    [AqiType.cityair]: 'баллы',
    [AqiType.instant]: 'баллы',
    [AqiType.epa]: 'баллы',
    [AqiType.CAPI]: 'баллы',
    [AqiType.R]: 'баллы',
    [AqiType.EU]: 'баллы',
    [AqiType.aqiIn]: 'баллы',
    [AqiType.aqiNN]: 'баллы',
    [AqiType.aqiPm]: 'баллы',
    [HISTOGRAM_AQI]: '% времени',
    [HUM]: '%',
    [PM10]: ugM,
    [PM25]: ugM,
    [SO2]: ugM,
    [CH2O]: ugM,
    [CO]: ugM,
    [CO2]: 'ppm',
    [O3]: ugM,
    [NO]: ugM,
    [H2S]: ugM,
    [NH3]: ugM,
    [NO2]: ugM,
    [TEMP]: '°C',
    [PRES]: 'мм&nbsp;рт.ст.',
    [WDA]: '°',
    [WVA]: 'м/с',
    [SUM]: ugM,
    [WVVA]: 'м/с',
    [NOISELEVEL]: 'дБА',
    [T]: '°C',
    [SP]: 'мм&nbsp;рт.ст.',
    [WD]: '°',
    [WV]: 'м/с',
    [R2]: '%',
    [WDA_V]: '°',
    [WVA_V]: 'м/с',
};

export const MEASURES_USA = {
    ...MEASURES,
    [TEMP]: '°F',
};

export const MEASURES_MG = {
    [AQI]: 'баллы',
    [AqiType.indoor]: 'баллы',
    [AqiType.cityair]: 'баллы',
    [AqiType.instant]: 'баллы',
    [AqiType.epa]: 'баллы',
    [AqiType.CAPI]: 'баллы',
    [AqiType.R]: 'баллы',
    [AqiType.EU]: 'баллы',
    [AqiType.aqiIn]: 'баллы',
    [AqiType.aqiNN]: 'баллы',
    [AqiType.aqiPm]: 'баллы',
    [HISTOGRAM_AQI]: '% времени',
    [HUM]: '%',
    [PM10]: mgM,
    [PM25]: mgM,
    [SO2]: mgM,
    [CH2O]: mgM,
    [CO]: mgM,
    [CO2]: 'ppm',
    [O3]: mgM,
    [NO]: mgM,
    [H2S]: mgM,
    [NH3]: mgM,
    [NO2]: mgM,
    [TEMP]: '°C',
    [PRES]: 'мм&nbsp;рт.ст.',
    [WDA]: '°',
    [WVA]: 'м/с',
    [SUM]: mgM,
    [WVVA]: 'м/с',
    [NOISELEVEL]: 'дБА',
    [T]: '°C',
    [SP]: 'мм&nbsp;рт.ст.',
    [WD]: '°',
    [WV]: 'м/с',
    [WDA_V]: '°',
    [WVA_V]: 'м/с',
    [R2]: '%',
};

export const MEASURES_MPC = {
    ...MEASURES,
    [PM10]: shareOfMPC,
    [PM25]: shareOfMPC,
    [SO2]: shareOfMPC,
    [CH2O]: shareOfMPC,
    [CO]: shareOfMPC,
    [O3]: shareOfMPC,
    [NO]: shareOfMPC,
    [H2S]: shareOfMPC,
    [NH3]: shareOfMPC,
    [NO2]: shareOfMPC,
    [SUM]: shareOfMPC,
};

export const UNITS = {
    '°C': '°C',
    '°F': '°F',
    'mm Hg': 'мм&nbsp;рт.ст.',
    '%': '%',
    'µg/m³': ugM,
    'mg/m³': mgM,
    ppm: 'ppm',
    mV: 'мВ',
    '°': '°',
    'm/s': 'м/c',
    'mm/min': 'мм/мин',
    dBA: 'дБA',
};
export const WIND_DIRECTION = {
    [N]: 'C',
    [NE]: 'СВ',
    [E]: 'В',
    [SE]: 'ЮВ',
    [S]: 'Ю',
    [SW]: 'ЮЗ',
    [W]: 'З',
    [NW]: 'СЗ',
};
export const MEASURES_SCHEME = {
    [MeasureScheme.default]: MEASURES,
    [MeasureScheme.usa_default]: MEASURES_USA,
    [MeasureScheme.c_mmhg_mg]: MEASURES_MG,
    [MeasureScheme.mpc]: MEASURES_MPC,
};

export const AQI_DECL = [
    'балл<i style="visibility: hidden;">ов</i>',
    'балла<i style="visibility: hidden;">в</i>',
    'баллов',
    'баллы',
];
export const AQI_DECL2 = ['балл', 'балла', 'баллов', 'баллы'];

export const NAMES = {
    [AQI]: 'AQI',
    [AqiType.indoor]: 'IAQI',
    [AqiType.cityair]: 'cityair',
    [AqiType.instant]: 'AQI',
    [AqiType.epa]: 'EPA',
    [AqiType.CAPI]: 'КИЗА',
    [AqiType.R]: 'ИКВ-Р',
    [AqiType.EU]: 'ИКВ-ЕС',
    [AqiType.aqiIn]: 'AQI',
    [AqiType.aqiNN]: 'ИКВ-НН',
    [AqiType.aqiPm]: 'AQI',
    [IZA]: 'ИЗА',
    [HISTOGRAM_AQI]: '',
    [HUM]: 'RH',
    [PM10]: 'PM10',
    [PM25]: 'PM2.5',
    [SO2]: 'SO<sub>2</sub>',
    [CO]: 'CO',
    [CO2]: 'CO<sub>2</sub>',
    [O3]: 'O<sub>3</sub>',
    [NO]: 'NO',
    [H2S]: 'H<sub>2</sub>S',
    [NH3]: 'NH<sub>3</sub>',
    [NO2]: 'NO<sub>2</sub>',
    [CH2O]: 'CH<sub>2</sub>O',
    [TEMP]: 'T',
    [PRES]: 'P',
    [PDK]: 'ПДК',
    [SUM]: 'Сум.',
    [TSP]: 'TSP',
    [WDA]: 'НВ',
    [WVA]: 'ГСВ',
    [WVVA]: 'ВСВ',
    [CS137]: 'CS<sup>137</sup>',
    [NOISELEVEL]: 'Шум',
    [T]: 'T',
    [SP]: 'P',
    [WD]: 'НВ',
    [WV]: 'ГСВ',
    [WDA_V]: 'НВ',
    [WVA_V]: 'ГСВ',
    [R2]: 'RH',
};
export const MMT_LONG_NAMES = {
    [TEMP]: 'Температура',
    [HUM]: 'Влажность',
    [PRES]: 'Давление',
    [WDA]: 'Направление ветра',
    [WVA]: 'Горизонтальная скорость ветра',
    [WVVA]: 'Вертикальная скорость ветра',
    [T]: 'Температура',
    [SP]: 'Давление',
    [WD]: 'Направление ветра',
    [WV]: 'Горизонтальная cкорость ветра',
    [R2]: 'Влажность',
};

export const AQI_THREE_TEXTS = ['Безопасно', 'Терпимо', 'Опасно'];
export const AQI_THREE_TEXTS2 = [
    'Чистый воздух',
    'Среднее количество примесей',
    'Большое количество примесей',
];

export const CAPI_GRADES = ['0 – 4', '5 – 6', '7 – 13', '> 14'];
export const CAPI_THREE_TEXTS = [
    'Низкое загрязнение',
    'Повышенное загрязнение',
    'Высокое загрязнение',
    'Очень высокое загрязнение',
];

export const TOOLTIPS = {
    ...NAMES,
    [AQI]: 'Air quality index - Индекс качества воздуха',
    [PM25]: 'Particulate Matter - взвешенные частицы размером не более 2.5 мкм',
    [PM10]: 'Particulate Matter - взвешенные частицы размером не более 10 мкм',
    [CO2]: 'Углекислый газ',
    [TEMP]: 'Температура',
    [PRES]: 'Давление',
    [HUM]: 'Влажность',
};

export const COMMON = {
    actions: 'Действия',
    today: 'cегодня',
    yesterday: 'вчера',
    error: 'Ошибка',
    apply: 'Применить',
    save: 'Сохранить',
    doNotSave: 'Не сохранять',
    cancel: 'Отмена',
    close: 'Закрыть',
    closeChart: 'Закрыть график',
    delete: 'Удалить',
    gas: 'Газы',
    more: 'более',
    back: 'вернуться',
    placeholderDate1: 'Дата начала периода',
    placeholderDate2: 'Дата окончания периода',
    browserNotSupport: 'Ваш браузер не поддерживается',
    byTheCity: 'по городу',
    pm2: 'PM2.5',
    pm10: 'PM10',
    cities: 'Города',
    city: 'Город',
    location: 'Местоположение',
    allWorld: 'Весь мир',
    allCities: 'Все города',
    airQuality: 'Качество воздуха.',
    pdkMr: 'ПДКмр',
    pdkSs: 'ПДКсс',
    logOut: 'Выход',
    noGroupPermission: 'Нет доступа в данную сеть мониторинга',
    now: 'Сейчас',
};

export const TIME_NAMES = {
    month: 'Месяц',
    week: 'Неделя',
    days3: `72&nbsp;Часа`,
    watches24: `24&nbsp;Часа`,
    min5: '5 мин',
    min20: '20 мин',
    hour1: '1 час',
    day1: '1 день',
};
export const REPORTS_TIME_NAMES = {
    source: '1 мин',
    '5m': '5 мин',
    '20m': '20 мин',
    '1h': '1 час',
    '1d': '1 день',
};
export const REPORTS_TIME_NAMES_TOOLTIPS = {
    source: 'Доступно только, когда выбран один пост',
    '5m': '',
    '20m': '',
    '1h': '',
    '1d': '',
};
export const REPORTS_UNITS_NAMES = {
    default: ugM,
    c_mmhg_mg: mgM,
};
export const TIME_NAMES_TOOLTIP = {
    min5: 'Доступно для периода наблюдения менее 3 дней',
    min20: 'Доступно для периода наблюдения менее 12 дней',
    hour1: 'Доступно для периода наблюдения менее 36 дней',
    day1: '',
};
export const STATION_BLOCK = {
    returnScale: 'Вернуть масштаб',
    dataOnPollutants: 'Данные о загрязнителях/метеоданные',
    addToComparison: 'Сравнить',
    compare: 'Режим сравнения',
    removeCompare: 'Убрать из сравнения',
    addCompare: 'Добавить к сравнению',
    type: {
        mo: 'Станция Cityair',
        myMo: 'Станция Cityair',
        station: 'Станция Cityair',
        control_point: 'Результаты расчёта',
        OpenAQ: 'OpenAQ',
        city: '',
    },
    noData: 'Нет данных измерений за указанный период',
    noDataForMmt: 'Нет выбранных данных измерений за указанный период',
    averageCity: 'Среднее по городу',
    add: 'Добавить',
    select: 'Выбрать',
    sourceTypeName: {
        48: 'Станция Atmos',
        46: 'Станция Magic Air',
    },
};

export const TEXT_DESCRIPTION = {
    infoBlock: ['Температура', 'Давление', 'Влажность'],
    airQualityTitle: 'Air Quality Index (Индекс Качества Воздуха)',
    airQuality: `Качество воздуха определяется<br>
            по международной шкале AQI:<br>
            от 1 (чистый) до 10 (очень грязный)`,
    capiAirQuality: `Комплексный индекс загрязнения атмосферы`,
};

export const CHART_OPTIONS = {
    lang: {
        loading: 'Загрузка...',
        months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ],
        weekdays: [
            'Воскресенье',
            'Понедельник',
            'Вторник',
            'Среда',
            'Четверг',
            'Пятница',
            'Суббота',
        ],
        shortMonths: [
            'Янв',
            'Фев',
            'Март',
            'Апр',
            'Май',
            'Июнь',
            'Июль',
            'Авг',
            'Сент',
            'Окт',
            'Нояб',
            'Дек',
        ],
        exportButtonTitle: 'Экспорт',
        contextButtonTitle: 'Экспорт',
        printButtonTitle: 'Печать',
        rangeSelectorFrom: 'С',
        rangeSelectorTo: 'По',
        rangeSelectorZoom: 'Период',
        downloadPNG: 'Скачать PNG',
        downloadJPEG: 'Скачать JPEG',
        downloadPDF: 'Скачать PDF',
        downloadSVG: 'Скачать SVG',
        printChart: 'Напечатать график',
        fullscreen: 'fullscreen',
        resetZoom: 'Вернуть масштаб',
        resetZoomTitle: 'Вернуть 100% масштаб графика',
    },
};

export const MARKET = {
    text: 'Публичная карта теперь доступна в новом приложении',
    airvoice: 'AirVoice',
    linkApple: 'https://apps.apple.com/ru/app/airvoice/id1484315084',
    linkGoogle: 'https://play.google.com/store/apps/details?id=com.cityair.airvoice',
};

export const WEEKDAY = {
    mo: 'Пн',
    tu: 'Вт',
    we: 'Ср',
    th: 'Чт',
    fr: 'Пт',
    sa: 'Сб',
    su: 'Вс',
};

export const DOWNLOAD_POPUP = {
    title: 'Первичные данные',
    period: 'Выберите период',
    all: 'со всех постов',
    all_devices: 'со всех устройств',
    several: 'с выбранных постов',
    several_devices: 'с выбранных устройств',
    select: 'Укажите посты',
    select_devices: 'Укажите устройства',
    select_type: 'Выберите формат',
    selected: 'Выбрано: ',
    download: 'Скачать',
    dateRange: 'Интервал данных',
    unitsMeasurements: 'Единицы измерения',
    error14day: 'Временной интервал для скачивания не может превышать 14 дней',

    filenameDataMO: 'AQM_data',
    filenameDataDevice: 'device_data',
    filenameDiagnostic: 'device_diagnostic_data',
    filenameReport: 'AQM_report_TZA4',
    error: 'К сожалению, при загрузке данных произошла ошибка. Повторите попытку позже или обратитесь в сервисную службу',
};

export const GMT_VALUE = [
    [-12, '(GMT -12:00) Эниветок, Кваджалейн'],
    [-11, '(GMT -11:00) Остров Мидуэй, Самоа'],
    [-10, '(GMT -10:00) Гавайи'],
    [-9, '(GMT -9:00) Аляска'],
    [-8, '(GMT -8:00) Тихоокеанское время (США и Канада)'],
    [-7, '(GMT -7:00) Горное время (США и Канада)'],
    [-6, '(GMT -6:00) Центральное время (США и Канада), Мехико'],
    [-5, '(GMT -5:00) Восточное время (США и Канада), Богота, Лима'],
    [-4, '(GMT -4:00) Атлантическое время (Канада), Каракас, Ла-Пас'],
    [-3, '(GMT -3:00) Бразилия, Буэнос-Айрес, Джорджтаун'],
    [-2, '(GMT -2:00) Срединно-Атлантического'],
    [-1, '(GMT -1:00 час) Азорские острова, острова Зеленого Мыса'],
    [0, '(GMT) Лондон, Лиссабон, Касабланка'],
    [1, '(GMT +1:00 час) Брюссель, Копенгаген, Мадрид, Париж'],
    [2, '(GMT +2:00) Киев, Калининград, Южная Африка '],
    [3, '(GMT +3:00) Москва, Санкт-Петербург'],
    [4, '(GMT +4:00) Абу-Даби, Мускат, Баку, Тбилиси'],
    [5, '(GMT +5:00) Екатеринбург, Исламабад, Карачи, Ташкент'],
    [5.5, '(GMT +5:30) Гургаон, Харайана, Индия'],
    [6, '(GMT +6:00) Алматы, Дакке, Коломбо'],
    [7, '(GMT +7:00) Новосибирск, Красноярск, Кемерово'],
    [8, '(GMT +8:00) Пекин, Перт, Сингапур, Гонконг'],
    [9, '(GMT +9:00) Токио, Сеул, Осака, Саппоро, Якутск'],
    [10, '(GMT +10:00) Восточная Австралия, Гуам, Владивосток '],
    [11, '(GMT +11:00) Магадан, Соломоновы острова, Новая Каледония'],
    [12, '(GMT +12:00) Окленд, Веллингтон, Фиджи, Камчатка'],
];

export const PROFILE = {
    network: 'Сеть мониторинга',
    post: {
        zero: 'постов мониторинга',
        one: 'пост мониторинга',
        two: 'поста мониторинга',
        few: 'поста мониторинга',
        many: 'постов мониторинга',
        other: 'постов мониторинга',
    },
    support: 'Служба поддержки',
    phone: ['+74955405516', '+7&nbsp;495&nbsp;540&nbsp;55&nbsp;16'],
    email: 'info@cityair.ru',
    privicyPolicy: {
        text: 'Политика конфиденциальности',
        url: 'https://cityair.ru/ru/privacy-policy/',
    },
    copyright: 'CityAir. Все права защищены',
    fondText: (isMobile) =>
        isMobile
            ? 'Исследования осуществляются<br/> OOO «СитиЭйр» при грантовой<br/> поддержке Фонда «Сколково»'
            : 'Исследования осуществляются OOO «СитиЭйр»<br/> при грантовой поддержке Фонда «Сколково»',
};

export const ADMIN_PANEL = {
    listDevices: 'Управление и диагностика',
    listOM: 'Посты мониторинга',
    dashboard: 'Монитор состояния сети',
    OM: 'Посты',
    eventList: 'Список событий.<br>Раздел в разработке',
    notlistDevices: '.<br>Устройства в списке отсутствуют',
    notlistOM: '.<br>"Посты мониторинга" в списке отсутствуют',
    notlistUsers: '.<br>В списке нет ни одного пользоваеля',
    noteventList: '',
    notcityCard: '',
    notcoefficientView: '',
    coefficientView: 'Поправочные коэффициенты',
    listUsers: 'Пользователи',
    cityCard: 'Аналитика',
    plumes: 'Шлейфы',
    impact: 'Вклады источников',
    indoor: 'Indoor',
    reports: 'Отчёты',
    forecast: 'Прогнозы',
    notindoor: '',
    configPanel: 'Настройки',
    buttonAddMO: 'Создать пост мониторинга',
    buttonAddUser: 'Создать пользователя',
    notifications: 'Центр уведомлений',
    notnotifications: '',
    postsAndDevices: 'Посты и устройства',
    back: 'Назад',
    analysis: 'Анализ сети',
    analysisEvents: 'Анализ эпизодов',
    network: 'Эффективность сети',
    events: 'Анализ инцидентов',
};

export const ANALYTICS_PAGE = {
    labelCitySelector: 'Cеть',
    searchPlaceholder: 'Поиск',
};

export const SERVICE_PAGE = {
    devices: 'Устройства',
};

export const LIST_OM = {
    objects: 'Сеть мониторинга',
    objectsView: 'Пост мониторинга',
    filterForName: 'Поиск',
    notPublic: 'Не Публичная',
    public: 'Публичная',
    offline: 'Оффлайн',
    online: 'Онлайн',
    clearFilter: 'Очистить фильтр',
    clearSearch: 'Очистить поиск',
    countStation: 'Количество',
    owner: 'Владелец',
    operator: 'Оператор',
    observer: 'Наблюдатель',
    observer_at: 'ПМ',
    selectOm: 'Нажмите для назначения Постов Мониторинка <br> данному пользователю',
    changesSaved: 'Изменения успешно сохранены',
    checkAll: 'Выбрать всё',
    uncheckAll: 'Убрать всё',
    noRole: 'Нет доступа',
    worked: 'Работает в штатном режиме',
    notWorked: 'Нет сигнала',
    batWorked: 'От батареи',
    batLow: 'Низкий заряд батареи',
    noData: 'Нет данных',
    noDevices: 'Нет привязанных устройств',
    noPosts: 'Нет доступных постов мониторинга',
    noFound: 'Постов мониторинга не найдено',
    noFoundDescription:
        'По вашему запросу ничего не найдено, попробуйте поискать по другим ключевым словам',
};

export const POPUP_DELETE: StndAskPopupTexts = {
    title: 'Удаление Поста Мониторинга',
    body: 'Вы действительно хотите удалить этот пост мониторинга?',
    cancel: 'Я передумал',
    cancelNew: COMMON.cancel,
    accept: COMMON.delete,
};

export const POPUP_DELETE_USER: StndAskPopupTexts = {
    title: 'Удаление пользователя',
    body: 'Вы действительно хотите удалить этого пользователя?',
    cancel: 'Я передумал',
    accept: COMMON.delete,
};

export const POPUP_THREE_QUESTIONS: StndAskPopupTexts = {
    title: 'У вас есть несохраненные изменения',
    body: 'Вы действительно хотите покинуть окно редактирования? Внесенные изменения не сохранятся',
    accept: 'Сохранить настройки',
    cancel: 'Продолжить редактирование',
    extraAction: COMMON.doNotSave,
};

export const EDIT_STATION = {
    accommodationObjects: 'Посты мониторинга',
    edit: 'Редактировать',
    delete: COMMON.delete,
    editing: 'Редактированние',
    name: 'Название',
    public: 'Публичная',
    publicName: 'Имя на карте',
    coordinates: 'Координаты',
    description: 'Описание',
    timezone: 'Часовой пояс',
    saveData: 'Сохранять данные',
    downloadXLSData: 'Скачать данные',
    downloadReport: 'Скачать отчёт ТЗА4',
    documentation: 'Документация',
    guideOperationName: 'Руководство по эксплуатации станций.pdf',
    guideOperationLink: 'https://cdn.mycityair.ru/docs/Manual.pdf',
    viewSite: 'Посмотреть на сайте',
    stationScheme: 'Схема станции.pdf',
    cancel: COMMON.cancel,
    save: COMMON.save,
    technicalInfo: 'Техническая информация ',
    model: 'Модель',
    versionH: 'Версия Hardware',
    versionS: 'Версия Software',
    startTimeStation: 'Запуск в эксплуатацию',
    lastTimeStation: 'Последний пакет',
    scheduledMaintenance: 'Плановое сервисное обслуживание',
    linkSpecification: 'https://cdn.mycityair.ru/docs/Station Design_ru.pdf',
    textareaPlaceholderAdmin:
        'Пользовательское описание объекта. Нажмите "редактировать", чтобы добавить описание.',
    textareaPlaceholder: 'Пользовательское описание объекта.',
    openChart: 'Открыть график',
    error: 'Ошибка сохранения. Сообщите службе поддержки.',
    listDevices: 'Устройство',
    partOf: 'Входит в состав постов мониторинга',
};

export const LIST_DEVICES = {
    selectDevice: 'Выберите устройство',
    selectDevices: 'Выберите устройствa',
    info: 'Информация',
    name: 'Имя',
    lat: 'Широта',
    lng: 'Долгота',
    timezone: 'Часовой пояс',
    location: 'Локация',
    listDevices: 'Список устройств',
    startDate: 'Запуск в эксплуатацию',
    service: 'Сервисное обслуживание',
    descriptionSection: `В данной вкладке отображаются все доступные вам устройства измерения<br>качества воздуха: станции мониторинга CityAir, модули расширения<br>G1/G2 для измерения газов, прочие устройства.`,
    connected: 'Посты мониторинга',
    notConnected: 'Станция нигде не установлена',
    loadPrimaryData: 'Скачать первичные данные',
    loadReport: 'Скачать отчёт',
    loadLog: 'Журнал диагностики',
    paramDeviceWork: 'Параметры работы устройства',
    interval: 'Интервал измерений',
    saveData: 'Сохранение данных',
    savingData: 'Cохранять данные',
    off: 'Выключено',
    on: 'Включено',
    min: ' мин',
    serialNumber: 'Серийный номер',
    type: 'Тип',
    online: 'На связи',
    offline: 'Нет сигнала',
    condition: 'Состояние',
    relatedDevices: 'Связанные устройства',
    since: 'с',
    noDevices: 'Нет доступных постов мониторинга',
    noFound: 'Устройств не найдено',
    equipmentNotAttached: `Оборудование&nbsp;не&nbsp;прикреплено&nbsp;к&nbsp;посту`,
    deviceNoPost: `Оборудование&nbsp;не&nbsp;прикреплено&nbsp;к&nbsp;устройству`,
    noConnectedDevices: `Нет&nbsp;связанных&nbsp;устройств`,
};

export const USER_ACCESS_TYPE = [
    'Нет доступа',
    'Администратор',
    'Оператор',
    'Наблюдатель',
    'Сервис',
];

export const LIST_USERS = {
    listUsers: 'Пользователи',
    roleUser: 'Роли пользователя',
    deleteUser: 'Удаление пользователя',
    deleteUserAction: 'Удалить из группы',
    deleteUserTitle: (login) => `Вы уверены, что хотите удалить пользователя ${login}?`,
    deleteUserDecs: (login) => ` ${login} больше не будет иметь доступа к аккаунту CityAir`,
    addUsers: 'Добавление пользователя',
    editUsers: 'Редактирование пользователя',
    login: 'Логин',
    userEmail: 'Email',
    loginExistError: 'Пользователь с таким логином уже существует',
    mail: 'e-mail',
    emailInGroup: 'Этот email уже используется в данной сети мониторинга',
    newUserDecs: (email) =>
        `На почту <b>${email}</b> будет отправлено приглашение для входа в систему`,
    minLength: (min) => `Минимальная длина ${min} символов`,
    required: 'Поле не может быть пустым',
    noPostsMsg: 'Нет данных постов мониторинга',
    incorrectEmail: 'Неправильный email',
    invalidLogin: 'Неправильный логин',
    access: 'Доступ',
    accessLevel: 'Уровень доступа',
    lastLoginTableHeader: 'Последний вход',
    user: 'Пользователь',
    add: 'Добавить',
    create: 'Создать',
    operator: `Операторы имеют доступ к просмотру данных и управлению постами мониторинга`,
    observer: `Наблюдатели имеют доступ только к просмотру данных с доступных им постов мониторинга`,
    noSelect: 'Не выбрано',
    accessSelect: 'Уровень доступа',
    whenSaving: 'Изменения сохранены',
    whenAdd: 'Пользователь добавлен',
    whenCreate:
        'Инструкции по активации учётной записи будут высланы на указанный адрес электронной почты',
    createAccount: (email) => `Приглашение было выслано на адрес ${email}`,
    noUsers: 'Пока нет пользователей',
    actionSendEmailPassword: 'Отправить письмо для<br/> восстановления пароля',
    you: 'Вы',
    accessPopUp: {
        title: 'Разграничение прав доступа пользователей',
        postDetails: 'Просмотр списка',
        fullAccess: 'полный доступ',
        closeAccess: 'доступ закрыт',
        partialAccess: 'частичный доступ',
        moduleNames: {
            users: 'Пользователи системы',
        },
    },
};

export const MODELS = {
    accommodationObjects: 'Посты мониторинга',
    simulation: 'Моделирование',
    info: `Внимание: математические модели работают в экспериментальном режиме`,
    info2: `Это значит, что сейчас мы щепетильно сравниваем результаты построений наших <br> математических моделей с данными, полученными со станций CityAir в этом регионе. <br>
        Чуть позже точность расчетов станет близкой к реальным показателям загрязненности воздуха`,
    noData: 'В данной области расчеты пока не осуществляются',
    title: 'Результаты моделирования',
    overlay: 'Непрерывно',
    contours: 'Контурно',
    off: 'Выкл',
    on: 'Вкл',
    border: 'Граница расчетной области',
};

export const CITY_CARD = {
    intro: [
        'Информация от создателей',
        'Мы&nbsp;рады делиться знаниями о&nbsp;качестве воздуха в&nbsp;разных городах мира',
        'В основе этой карты — данные платформы CityAir',
        'Сети мониторинга',
    ],
    backBtnCityScreen: 'Назад к списку',
    backBtn: ['Назад к списку городов', 'Назад к карте мира'],
    openCity: 'Заглянуть в город',
    goOver: 'Перейти',
    goOverCity: 'Перейти в город',
    close: 'закрыть',
    tooltip: [
        'Качество воздуха определяется по международной шкале AQI:<br> от 1 (чистый) до 10 (очень грязный)',
        'Общая доля каждого значения AQI',
        'Средние значения AQI по часам суток за всю историю наблюдений',
        'Средние значения AQI по дням недели за всю историю наблюдений',
    ],
    since2: ['Наблюдения ведутся с ', ''],
    nowInCity: 'Сейчас в городе',
    tabs: ['Посты мониторинга', 'Экологический профиль'],
    sorting: ['Города', 'Посты'],
    cityProfile: 'Экологический профиль',
    seasons: ['За год', 'Зима', 'Весна', 'Лето', 'Осень'],
    charts: [
        'Качество воздуха за всю историю наблюдений',
        'Среднее качество воздуха по часам суток',
        'Среднее качество воздуха по дням недели',
    ],
    noData: 'Нет данных за выбранный период времени',
    dataSources: 'Источники данных',
    noDataSources: 'Нет источников данных',
    noConnectedDevices: 'Данные станции CityAir',
};

export const COEFFICIENT_VIEW = {
    title: 'Поправочный коэффициент',
    content: [
        `Калибровка массовой концентрации взвешенных частиц для анализатора CityAir проводится по результатам параллельных измерений и состоит в определении двух коэффициентов: PCF (Photometric Calibration Factor) и SCF (Size Calibration Factor). В качестве опорного метода для определения коэффициентов рекомендуется использовать гравиметрический метод или любой эквивалентный.`,
        `Нормировочные коэффициенты определяются так, чтобы выполнялись следующие соотношения:`,
        `PM2.5<sup>Ref</sup> = PCF ⋅ PM2.5<sup>CityAir</sup>`,
        `PM10<sup>Ref</sup> = SCF<sup>3</sup> ⋅ PM10<sup>CityAir</sup> + (PCF - SCF<sup>3</sup> ) ⋅ PM2.5<sup>CityAir</sup>`,
        `где PM<sup>Ref</sup> и PM<sup>CityAir</sup> — результаты определения концентрации взвешенных веществ гравиметрическим методом с селективными импакторами, предназначенными для определения PM2.5 и PM10, и анализатором CityAir за один и тот же период времени.`,
    ],
    current: 'Текущий коэффициент PCF:',
    new: ['Задать новый коэффициент:', 'PCF', 'введите значение'],
    scf: 'Текущий коэффициент SCF<sup>3</sup>:',
    scfComment:
        'Коэффициент SCF может быть настроен только при наличии синхронных гравиметрических измерений  PM2.5 и PM10',
    description:
        'Поправочные коэффициенты изменяет только данные, поступающие в посты мониторинга. Первичные данные полученные с устройств мониторинга доступны в личном кабинете и через API для скачивания.',
    popup: ['Вы собираетесь изменить поправочный коэффициент', 'Новый коэффициент PCF:'],
    errorPcf:
        'Для установки значения PCF вне пределов стандартного диапазона, пожалуйста, обратитесь в службу сервиса.',
};

export const QUALITY_CURRENT = [
    [
        'Распахни окна! Воздух чист',
        'Подходящий момент, чтобы принять воздушную ванну',
        'Хорошие условия для занятий спортом на&nbsp;воздухе!',
    ],
    [
        'Бывает и&nbsp;лучше: примеси есть, но&nbsp;пока до&nbsp;критической ситуации далеко',
        'Обычный день. Ни&nbsp;хорошо, ни&nbsp;плохо',
        'Можно прогуляться, но&nbsp;не&nbsp;слишком долго',
        'На&nbsp;улицу можно, но&nbsp;лучше там не задерживаться',
    ],
    [
        'Внимание! Воздух грязный!',
        'Если есть возможность&nbsp;&mdash; на&nbsp;улицу лучше не&nbsp;выходить и&nbsp;закрыть окна',
        'Не&nbsp;рекомендуем выходить на&nbsp;улицу',
        'Тьма, пришедшая со&nbsp;Средиземного моря, накрыла Ершалаим',
        'Облокотиться на&nbsp;такой воздух еще нельзя, но&nbsp;кружку в&nbsp;руке уже может быть не&nbsp;видно',
    ],
];

export const QUALITY_FORECAST = {
    'now<last': [['Ожидается улучшение'], ['Ожидается улучшение'], ['Ожидается улучшение']],
    'now=last': [['Ситуация стабильна'], ['Ситуация стабильна'], ['Ситуация стабильна']],
    'now>last': [['Ожидается ухудшение'], ['Ожидается ухудшение'], ['Ожидается ухудшение']],
};

export const LOGIN_WINDOWS = {
    welcome: `Добро пожаловать<br>в&nbsp;CityAir`,
    toolsFor: 'Инструменты для настройки вашей сети мониторинга',
    enterNewPass: 'Введите новый пароль',
    newPass: 'Новый пароль',
    confNewPass: 'Подтвердите пароль',
    wrongNewPass: 'Пароль содержит недопустимые символы',
    passwordsDontMatch: 'Пароли не совпадают',
    warningNewPass: `<strong>Совет</strong>: пароль должен содержать минимум 7 символов. Чтобы&nbsp;сделать его более надёжным, используйте строчные и прописные латинские буквы, цифры и спецсимволы.`,
    resetPass: 'Сменить пароль',
    techSup: 'Техподдержка',
    passLevel: ['', 'простой', 'средний', 'средний', 'сложный', 'сложный'],
    wrong: 'Неправильный логин или пароль',
    wrongMail: 'Неправильный адрес почты',
    wrongCode: 'Неправильный проверочный код',
    cityscreenSignIn: 'Войти в CityAir',
    login: 'Логин',
    logout: 'Выйти',
    password: 'Пароль',
    forgot: 'Забыли пароль?',
    sign: 'Войти',
    forgotYourPass: 'Забыли пароль?',
    pleaseConfirm: `Введите адрес электронной почты вашего аккаунта, вам будет отправлен код подтверждения`,
    emailAddress: 'Адрес электронной почты',
    sendReset: `Далее`,
    backToSign: 'Вернуться к странице входа',
    language: 'Язык',
    en: 'English',
    ru: 'Русский',
    passUpd: 'Пароль изменен',
    yourPassHasBeen: 'Используйте его для входа в&nbsp;систему',
    check: 'Проверьте электронную почту',
    pleaseChk: `Инструкции по&nbsp;смене пароля были отправлены на&nbsp;вашу электронную почту`,
    info: `+7 495 540 55 16<br>service@cityair.ru`,
    licenseStr1: 'Лицензия Росгидромета',
    licenseStr2: '№ Р/2020/4163/100/Л от 23.11.2020',
    licenseStr3: `©${new Date().getFullYear()} CityAir. Все права защищены`,
    resetPassTitle: 'Восстановление пароля',
    weHaveSent: `Мы&nbsp;отправили код подтверждения на&nbsp;вашу электронную почту`,
    verCode: 'Код подтверждения',
    next: 'Дальше',

    mobileIsNotAvailable: 'Мобильная версия недоступна',
    suggestionForMobileClients:
        'Откройте, пожалуйста, CityAir на ноутбуке или настольном компьютере',
    suggestionForMobileClientsNoBranding:
        'Откройте, пожалуйста, личный кабинет на ноутбуке или настольном компьютере',

    toolsFor_sakhalin: 'Министерство экологии Сахалинской области',
    clientSignIn: 'Войти в личный кабинет',

    errorLink: 'У вас уже есть аккаунт в CityAir. Введите или восстановите логин и пароль',
};

export const REQUEST_ERRORS = {
    unrecognizedError: 'Неизвестная ошибка',
    tryAgainLater: 'Попробуйте ещё раз позже',
    clientOrNetworkError: 'Ошибка сети или обозревателя',
};

export const LOGIN_NORNICKEL = {
    contactSupport: 'Телефон службы поддержки',
    supportTel: '8 (800) 600-78-08',
    supportAvailable: 'ПН-ПТ с 9:00 до 18:00 (мск)',
    contactFeedback: 'Оставить отзыв',
    feedbackEmail: 'feedback@mail.city.online',
    contactHelp: 'Получить помощь',
    helpEmail: 'help@mail.city.online',
    copyright: `© 2008-${new Date().getFullYear()} ПАО «ГМК «Норильский никель»`,
};

export const NOTIFICATIONS = {
    allMonitoringPostsSelected: 'Выбраны все посты',
    accommodationObjects: 'Посты мониторинга',
    back: 'Назад',
    settingNotification: 'Мои оповещения',
    eventList: 'Лента событий',
    eventsNotFound: 'Событий не найдено',
    eventsNotFoundHint: 'Поищите по другим ключевым словам или уберите<br/> примененные фильтры',
    all: 'Все',
    measures: 'Измерения',
    service: 'Сервисные',
    forecasts: 'Прогнозы',
    plumes: 'Шлейфы',
    earlier: 'Более ранние события',
    collapse: 'свернуть',
    searchResultEmpty: 'Совпадений не найдено',
    subscribeSuggestion: 'Настроить оповещения',
    eventsHelpMessageMeasurements:
        'Настройте оповещения об измерениях, чтобы быть в курсе важных событий',
    eventsHelpMessageService: 'Настройте сервисные оповещения, чтобы быть в курсе важных событий',
    addSubscription: 'Добавить оповещение',
    activeSubscriptions: 'Активных оповещений',
    addSubscriptionHint:
        'Чтобы создать новое оповещение, нажмите на кнопку «Добавить оповещение» выше',
    measuresDescription1:
        'Получайте оповещения опревышениях заданных <br/> концентраций на постах мониторинга',
    measuresDescription2: 'Если вам подходят стандартные настройки, нажмите «Сохранить»',
    measuresDescription3:
        'Если вы хотите изменить предложенные настройки для оповещений, нажмите «Редактировать»',
    serviceDescription1:
        'Получайте оповещения о&nbsp;сбоях в работе оборудования&nbsp;и другую сервисную информацию',
    serviceDescription2: 'Если вам подходят стандартные настройки, нажмите «Сохранить»',
    serviceDescription3:
        'Если вы хотите изменить предложенные настройки для оповещений, нажмите «Редактировать»',
    serviceHelpText:
        'Если вы не хотите получать уведомления, удалите свой email в настройках оповещения',
    emailListInputLabel: 'Email (вы можете указать несколько через запятую)',
    noEventsAvailable: 'У вас пока нет событий',
    moNotFoundOrDeleted: 'Пост не найден или удален',
    subscriptionsPdkTooltip: {
        title: 'ПДКмр — максимальная разовая предельно&nbsp;допустимая концентрация',
        description: 'В соответствии с РД 52.04.667-2005',
        text: [
            'от 0 до 1 ПДК — низкое загрязнение',
            'от 2 до 4 ПДК — повышенное загрязнение',
            'от 5 до 10 ПДК — высокое загрязнение',
            'более 10 ПДК — очень высокое',
        ],
    },

    allPostMeasure: 'Все посты. Измерения',
    allPost: 'Все посты',
    allStation: 'Все станции',
    parameterForNotif: 'Параметры для оповещения',
    levelExcess: 'Уровень превышения',
    recipients: 'Получатели уведомлений',
    edit: 'Редактировать',
    save: COMMON.save,
    cancel: COMMON.cancel,
    deleteSubscription: 'Удалить оповещение',
    addToEventFeed: 'Показывать в ленте событий',
    removeFromEventFeed: 'Убрать из ленты событий',
    newSubscription: 'Новое оповещение',
    editSubscription: 'Редактирование оповещения',
    activateSubscription: 'Возобновить оповещение',
    deactivateSubscription: 'Приостановить оповещение',
    titleSubscription: 'Имя оповещения',
    substances: 'Вещества',
    selectPosts: 'Выберите посты мониторинга из списка или на карте',
    selectPostsSmall: 'Выберите посты мониторинга',
    selectAll: 'Выбрать все',
    add: 'Добавить',
    controlledSubstances: 'Контролируемые вещества',
    excessLevel: 'Уровень превышения, начиная с которого мы будем вас оповещать',
    advancedSetup: 'Расширенная настройка',
    getToBasicSetup: 'Вернуться к базовой настройке',
    moreThan: 'более',
    showMore: 'ещё',
    selected: {
        zero: 'Выбрано',
        one: 'Выбран',
        two: 'Выбрано',
        few: 'Выбрано',
        many: 'Выбрано',
        other: 'Выбрано',
    },
    selectedStation: {
        zero: 'Выбрано',
        one: 'Выбрано',
        two: 'Выбрано',
        few: 'Выбрано',
        many: 'Выбрано',
        other: 'Выбрано',
    },
    noneSelected: 'Посты не выбраны',
    post: {
        zero: 'постов',
        one: 'пост',
        two: 'поста',
        few: 'поста',
        many: 'постов',
        other: 'постов',
    },
    station: {
        zero: 'устройств',
        one: 'устройство',
        two: 'устройства',
        few: 'устройства',
        many: 'устройств',
        other: 'устройств',
    },
    setManually: 'Задать вручную (мкг/м3)',
    notifyPeriodically: 'Оповещать о каждом интервале измерений (20 мин)',
    notifyEvent: 'Оповещать только о начале и окончании события',
    Slack: 'Slack',
    SMS: 'SMS',
    Email: 'Email',
    specifyFew: 'Укажите несколько через запятую',
    dailyReport: 'Отчёт за день',
    whenSaving: '* При сохранении настроек будет выслано тестовое сообщение',
    emailsNotSpecified: 'не указаны',
    searchMoPlaceholderText: 'Искать пост мониторинга',
    stationSelectionPrompt: 'Выберите устройства из списка ниже',
    postsFilterPlaceholder: 'Поиск по посту мониторинга',
    serviceNotifications: 'Сервисные оповещения',
    notificationsFromEquipment: 'Параметры для оповещений',
    lackOfData: 'Отсутствие данных более',
    lackOfPower: 'Отсутствие питания более',
    verificationExpires: 'Истекает срок поверки через',
    timeIntervalMinutes: {
        zero: 'минут',
        one: 'минута',
        two: 'минут',
        few: 'минут',
        many: 'минут',
        other: 'минут',
    },
    timeIntervalHours: {
        zero: 'часов',
        one: 'часа',
        two: 'часов',
        few: 'часов',
        many: 'часов',
        other: 'часов',
    },
    moreDays: {
        zero: 'дней',
        one: 'дня',
        two: 'дней',
        few: 'дней',
        many: 'дней',
        other: 'дней',
    },
    timeIntervalDays: {
        zero: 'дней',
        one: 'день',
        two: 'дня',
        few: 'дня',
        many: 'дней',
        other: 'дней',
    },
    timeIntervalMonths: {
        zero: 'месяцев',
        one: 'месяца',
        two: 'месяцев',
        few: 'месяцев',
        many: 'месяцев',
        other: 'месяцев',
    },
    timeIntervalYears: {
        zero: 'лет',
        one: 'года',
        two: 'лет',
        few: 'лет',
        many: 'лет',
        other: 'лет',
    },
    locationSelection: 'Выбор локации',
    point: 'Точка',
    region: 'Область',

    statusActive: 'Активная',
    statusDeactivated: 'Неактивная',
    statusActive2: 'Активное',
    statusNotActive: 'Неактивное',
    statusDeactivated2: 'Остановлено',
    excessLevelUnits: 'ПДКмр',
    excessLevelReport: 'Уровень превышения ПДКмр',
    qmax: 'q<sub>М</sub>',

    errMsgTitleRequired: 'Необходимо задать имя',
    errMsgValueMustBeGreaterThanZero: 'Необходимо задать значение больше нуля',
    errMsgValueMustBeIntegerNumber: 'Необходимо задать целое число',
    errMsgIncorrectEmails: 'Неправильный формат списка email адресов',
    errMsgMissingEmail: 'Необходимо внести хотя бы один email адрес',
    errMsgPost: 'Необходимо выбрать хотя бы один пост мониторинга',
    error: 'Ошибка',

    tooltipEventIcon: {
        measurements: 'Оповещение об измерениях',
        service: 'Сервисное оповещение',
    },

    tooltipEventTitle: 'Настроить оповещение',

    tooltipSettingsHintValues:
        'Вы&nbsp;можете задать абсолютное значение <br>ПДК для выбранных параметров',

    tooltipSettingsHintDailyReport:
        'Вся информация о превышениях<br> в ежесуточном отчёте (детализация: 20 мин)',
    tooltipDeviceDailyReport: 'Вся информация о состоянии устройств в ежесуточном отчёте',

    tooltipDurationLabel: ['Не завершено', 'Завершено'],

    duration: 'длительность',

    popupDelete: {
        title: 'Хотите удалить оповещение?',
        body: 'При удалении оповещения очистится вся история превышений. Вы можете предварительно её скачать в настройках оповещения',
        cancel: COMMON.cancel,
        accept: COMMON.delete,
    },

    popupDownloadFeetHistory: {
        title: 'Скачать историю',
        body: 'Выберите период, за который хотите скачать историю',
        cancel: COMMON.cancel,
        accept: 'Скачать',
        period: 'Укажите период',
    },

    durationDescription: {
        begin: 'Начало',
        continue: 'Длительность',
        expect: 'Случится',
    },

    notificationAlertLabel: {
        pdk: {
            None: '',
            Begin: 'Превышение',
            Continue: 'Превышение',
            End: 'Окончание превышения',
        },
        nodata: {
            None: '',
            Begin: 'Hет данных',
            Continue: 'Hет данных',
            End: 'Передача данных воcстановлена',
        },
        nopower: {
            None: '',
            Begin: 'Нет питания',
            Continue: 'Нет питания',
            End: 'Питание восстановлено',
        },
    },

    alertTag: {
        endOfExcess: 'Окончание превышения',
        excess: 'Превышение',
        thereIsNoData: 'Нет данных',
        dataTransferRestored: 'Передача данных воcстановлена',
        noPowerSupply: 'Нет питания',
        powerRestored: 'Питание восстановлено',
        online: 'Online',
        offline: 'Offline',
        onBattery: 'От батареи',
        onNetworks: 'От сети',
    },

    statusTexts: {
        pdk: ['Уровень вещества превысил', 'Концентрация вещества вернулась к норме'],
        nodata: ['Отсутствуют данные более', 'Передача данных восстановлена'],
        nopower: ['Нет питания более', 'Питание восстановлено'],
    },

    showFirstEvent: 'показать начало',
    eventsFilterPrompt: 'Имя оповещения, устройство, пост или вещество',
    analysisEvent: {
        buttonText: 'Анализ событий',
    },
};

export const CONFIG = {
    title: 'Настройки',
    coeff: 'Поправочные коэффициенты',
    token: 'Ключи доступа API',
    history: 'История активности',
    notifications: 'Оповещения',
    settings: 'Пользовательские настройки',
    users: 'Пользователи',
    searchToken: 'Поиск',
    settingsGroup: 'Настройки личного кабинета',
    settingGroupTitles: {
        map: 'Настройки карты',
        coef: 'Поправочные коэффициенты',
        startModule: 'Стартовый модуль',
        defaultPost: 'Предвыбор постов мониторинга',
    },
    mapSettings: {
        centerZoom: {
            title: 'Положение карты по умолчанию',
            info: 'Определяет начальное положение карты при первой загрузке модуля или его обновлении',
            description:
                'Переместите карту в нужную позицию. Отрегулируйте уровень приближения с помощью колесика мыши.',
            labelZoom: 'Приближение',
            labelLng: 'Центр (долгота)',
            labelLat: 'Центр (широта)',
        },
        bounds: {
            title: 'Границы карты',
            info: 'Определяют видимую область карты.',
            description: 'Границы карты не установлены',
            setBoundsTitle: 'Редактировать',
            newBoundsTitle: 'Установить',
            deleteBtn: 'Удалить',
            infoTooltip:
                'Отредактируйте текущие границы - нажмите на угол прямоугольника и потяните.',
            notExist:
                'Нарисуйте на карте границы, кликнув по карте и выделяя нужную область мышкой. Для изменения границ потяните за угол прямоугольника.',
        },
        errorCenter:
            'Центр карты не попадает в новые границы карты. Поправьте новые границы или поменяйте центр.',
        errorPosts: 'Не все посты мониторинга попадают в область видимости карты',
    },
    startModuleSetting: {
        info: 'Автоматически открывается при переходе на сайт',
        label: 'Стартовый модуль',
        noSelect: 'Не установлен',
    },
    noFoundToken: 'Ключи не найдены',
    tokenTableHeader: {
        name: 'Название',
        status: 'Статус',
        key: 'Ключ',
        lastDate: 'Последнее использование',
        createDate: 'Дата создания',
        count: 'Количество запросов',
    },
    coeffTooltip: `Локальный поправочный коэффициент изменяет только данные,<br>
 формирующие графики с постов мониторинга. Первичные данные,<br>
 полученные с устройств мониторинга, остаются неизменными <br>
 доступны в личном кабинете и через API для скачивания`,

    tokenTooltip: `Уникальные идентификаторы (токены) предназначены<br>
 для взаимодействия с серверами платформы CityAir<br>
  без использования Web-интерфейса, посредством API`,

    historyTooltip: `История активности показывает информацию об истории входов<br>
 в ваш личный кабинет. Если вы подозреваете, что кто-то получил<br>
 доступ к вашему аккаунту, мы рекомендуем сменить пароль<br>
 и обратиться в службу безопасности`,

    measureSchemeDescriptionAWS: '',

    saveDescription: '<b>Внимание:</b> После сохранения настроек страница перезагрузится',

    units: 'Единицы измерения',
    timezoneTitle: 'Часовой пояс',
    langTitle: 'Язык системы',
    autoTimezoneLabel: 'Aвтоматически (время браузера)',
    searchPlaceholderTzText: 'Найти часовой пояс',
    browserTokenItems: ['Последнее использование:', 'Дата создания:', 'Количество запросов:'],
    closeToken: 'Завершить сессию',
    activeToken: 'Текущая сессия',
    closedToken: 'Завершённая сессия',
    closeAll: 'Завершить все сеансы',

    active: 'активный',

    rename: 'Переименовать',
    close: 'Удалить ключ',
    countTokens: 'Активных ключей',
    create: 'Добавить ключ',

    titlePopupCreate: 'Создать ключ',
    titlePopupClose: 'Вы действительно хотите удалить данный ключ доступа API?',
    titlePopupRemove: (name) => `Вы действительно хотите удалить данный ключ "${name}"?`,
    titlePopupRename: 'Переименование ключа',

    applyCreate: 'Создать',
    applyClose: 'Удалить',
    applyRename: 'Сохранить',

    closeDescription: 'Ключ: ',
    createDescription: 'Задайте имя ключа API',
    createKeyTitle: 'Добавление ключа',
    createKeyLabel: 'Название ключа',
    copySuccess: 'Ключ API скопирован',
    copy: 'Копировать ключ',

    language: 'Язык',
    timeZone: 'Часовой пояс',
};

export const PLUMES = {
    runResults: 'Результаты расчёта',
    runConfiguration: 'Расчёты',
    controlPoints: 'Контрольные точки',
    resultsAt: 'от',
    addControlPoint: 'Добавить контрольную точку',
    addSource: 'Добавить источник',
    nowOnMap: 'Сейчас на карте',
    noRunSelected: 'Расчёт не выбран',
    forecastOn: 'Прогноз на',
    createControlPointTitle: 'Добавление контрольной точки',
    editControlPointTitle: 'Редактирование контрольной точки',
    editRunConfigurationTitle: 'Редактирование расчёта',
    latitude: 'Широта',
    longitude: 'Долгота',
    title: 'Название',
    value: 'Значение',
    runConfigurationName: 'Название расчёта',
    runConfigurationStatus: ['В работе', 'Остановлен'],
    interval: 'Периодичность',
    intervalUnits: 'мин',
    editRunConfigurationAction: 'Редактировать расчёт',
    nameRequiredError: 'Введите название расчёта',
    maxLength: (max: number) => `Название расчета не должно превышать ${max} символов`,
    stopRunConfigurationAction: 'Остановить расчёт',
    startRunConfigurationAction: 'Возобновить расчёт',
    sourcesListTitle: 'Источники в расчёте',
    runInterval: 'Периодичность расчёта',
    day: 'Дни',
    hour: 'Часы',
    min: 'Минуты',
    runIntervalDescription: 'Расчёт будет запускаться',
    noRunsAvailable: 'Расчётов пока нет',
    selectedPeriodNoRunsAvailable: 'За выбранный период расчётов нет',
    popupDelete: {
        title: 'Удаление контрольной точки',
        body: 'Вы действительно хотите удалить контрольную точку «%s»?',
        cancel: COMMON.cancel,
        accept: COMMON.delete,
    },
    timeIntervalHours: ['час', 'часа', 'часов'],
    every: {
        zero: 'каждые',
        one: 'каждый',
        two: 'каждые',
        few: 'каждые',
        many: 'каждые',
        other: 'каждые',
    },
    previousRun: 'Предыдущий расчёт',
    nextRun: 'Следующий расчёт',
    newControlPointName: 'Новая контрольная точка',
    infoTextCreate:
        'Чтобы добавить контрольную точку, кликните в любое место на карте и перемещайте метку.' +
        ' Для более точной настройки по координатам воспользуйтесь полями ниже.',
    infoTextEdit:
        'Для редактирования расположения контрольной точки перемещайте метку на карте. ' +
        'Для более точной настройки по координатам воспользуйтесь полями ниже.',
    coordinates: 'Расположение на карте',
    noControlPoints: 'Нет контрольных точек',
    noRegions: 'Нет рaйонов',
    noStations: 'Нет постов мониторинга',
    unitMetr: 'м',
    windLayerButtonText: 'Анимация ветра',
    quickWindButton: 'Ветер',
    tooltipWIndButton: {
        byHeight: 'Анимация ветра доступна <br/>только на приземном слое',
        byPlayer: 'Не доступно в режиме <br/>анимации шлейфов',
    },
    expandControlPoint: 'Свернуть',
    contributionTitle: 'Вклад источников',
    noDataControlPoint: 'Нет данных для контрольной точки.',
    noContributes: 'Вкладов источников не зафиксированно.',
    errorLoadContribution: 'Не удалось загрузить данные по вкладам источников',
    otherChartLabel: 'Остальные',
    switchPostButton: {
        off: 'Отображать посты<br/> мониторинга на карте',
        on: 'Не отображать посты<br/>  мониторинга на карте',
    },
    runResultLabelPeriod: 'Период',
    showSources: 'Показать',
    contribution: {
        titlePost: 'Пост',
        titleRun: 'Расчёт',
    },
    cardRun: {
        interval: 'Запуск расчета',
        duration: 'Прогноз на',
        wind: 'Поле ветра',
        windOn: 'включено',
        windOff: 'выключено',
        measures: 'Вещества',
    },
};

export const DASHBOARD = {
    dashboardTitle: 'Монитор состояния сети',
    refreshData: 'Обновить',
    availabilityStatsTitle: 'Связь и питание',
    monthlyStatsTitle: 'Статистика за',
    packetsMonitorTitle: 'Доставка данных от устройств',
    packets: {
        zero: 'пакетов',
        one: 'пакет',
        two: 'пакета',
        few: 'пакета',
        many: 'пакетов',
        other: 'пакетов',
    },
    lowBattery: 'Нет питания',
    lowSignal: 'Низкий сигнал',
    noPackets: 'Нет данных',
    showInMonitor: 'Показать',
    dataTransmissionTitle: 'Передача данных',
    dataTransmissionStatus: ['offline', 'online'],
    powerSupplyTitle: 'Питание',
    powerSupplyStatus: ['работает от батареи', 'работает от сети'],
    monthlyStatsChartTitle: 'Доставка данных со всех устройств',
    dataReceived: 'данных получено',
    deviceMonitorLegend: {
        dataReceived: 'Полученные данные',
        hasMissingData: 'Отсутствующие данные',
        poweredByBattery: 'Питание от батареи',
        lowGMSSignal: 'Низкий сигнал GSM',
    },
    availabilityTooltipText: 'Данные последнего <br/>пакета',
    monthlyStatsTooltipText: 'Общее соотношение доставленных <br/>данных со всех устройств',
    selectedTimeIntervalLabel: 'Период для отображения',
    selectedDevicesLabel: 'Устройства',
    columnLabels: {
        device: 'Устройство',
        monitoringObject: 'Пост',
        status: 'Статус',
        packetsRate: 'Количество пакетов в час',
    },
    searchDevicePlaceholderText: 'Искать устройство',
    selectAllDevicesFilter: 'Выбрать все',
    resetDevicesFilter: 'Сбросить фильтр',
    notSelectedStatusFilter: 'не выбрано',
};

export const POSTS_AND_DEVICES = {
    postAndDevices: 'Посты и устройства',
    addPost: 'Добавить пост мониторинга',
    viewInformation: 'Смотреть информацию',
    loadPrimaryData: 'Скачать первичные данные',
    downloadDataFromPost: 'Скачать данные с поста',
    downloadDataFromPosts: 'Скачать данные с постов',
    downloadTZA4Report: 'Скачать отчёт ТЗА4',
    tza4: 'Отчёт ТЗА4',
    edit: 'Редактировать',
    replaceDevice: 'Заменить устройство',
    deletePost: 'Удалить пост',
    dataDevice: 'Данные устройства',
    noMonitoringPosts: 'Пока нет постов мониторинга',
    noDevices: 'Пока нет Устройств',
    unfortunatelyNothing: 'К сожалению, ничего',
    searchPost: 'Искать пост, устройство, локацию',
    searchDevice: 'Искать пост, устройство',
    post: 'Пост',
    posts: 'Посты',
    postsMonitoring: 'Посты мониторинга',
    location: 'Локация',
    aqi: 'AQI',
    device: 'Устройство',
    devices: 'Устройствa',
    type: 'Тип',
    state: 'Состояние',
    nameOnMap: 'Имя',
    coordinate: 'Координаты',
    timeZone: 'Часовой пояс',
    dataSources: 'Источники данных',
    actionHistory: 'История действий',
    users: 'Пользователи',
    documentation: 'Документация',
    showMore: 'показать больше',
    showLess: 'показать меньше',
    administrator: 'Администратор',
    operator: 'Оператор',
    power: 'Питание',
    dataTransfer: 'Передача данных',
    info: 'Информация',
    online: 'Online',
    offline: 'Offline',
    relatedDevices: 'Связанные устройства',
    name: 'Название',
    description: 'Описание',
    locationOnTheMap: 'Расположение на карте',
    createPost: 'Создать пост',
    editPost: 'Редактировать пост',
    newMO: 'Новый пост мониторинга',
    editMo: 'Редактирование поста мониторинга',
    dataFromPost: 'Данные с постов мониторинга',
    intervalData: 'Интервал данных',
    units: 'Единицы измерения',
    pightsOfPDK: 'доли ПДК',
    loadingDataForThePrevious: 'Загрузка данных за предыдущий период может занять некоторое время',
    format: 'Формат',
    allAdministratorsAndOperators:
        'Доступ к&nbsp;данным с&nbsp;поста мониторинга имеют все администраторы и&nbsp;операторы',
    asWellAsTheFollowing: ', а&nbsp;также следующие наблюдатели:',
    accessToData: 'Доступ к данным с поста мониторинга',
    formError: {
        maxLength: (max: number) =>
            `Превышена максимальная длина, значение не должно превышать ${max} символов`,
        required: 'Поле не может быть пустым',
    },
};

export const INDOOR = {
    [ROOM]: 'Комната',
    selectRoom: 'Выберите комнату для отображения информации',
    iaq: 'IAQI - индекс качества воздуха в помещении',
    search: 'Поиск',
    comfort: 'Comfort',
    statTitle: 'Summary for all offices',
    safety: 'Safety',
    title1: 'Good air, time',
    title2: 'IAQI’',
    emptyPosts: 'У вас нет доступа к постам мониторинга',
    emptyStatInfo: 'Нет данных за указанный период',
    moMapMobile: 'Dashboard еще недоступен в мобильной версии, но мы уже работаем над ним',
    popupComfort: `Here we&nbsp;calculate the percentage of&nbsp;time during the work day when the air in&nbsp;the space was comfortable and contributed to&nbsp;productive work and <nobr>well-being</nobr>.
<br /><br />
Comfort is&nbsp;determined by&nbsp;the temperature, humidity and carbon dioxide levels.`,
    popupSafety: `Here we&nbsp;show summary statistics based on&nbsp;the IAQI in&nbsp;the rooms you manage: the percentage of&nbsp;time that air quality was high, as&nbsp;well as&nbsp;the average index per day.
<br /><br />
To&nbsp;calculate IAQI we&nbsp;use <nobr>real-time</nobr> data on&nbsp;the concentrations of&nbsp;PM2.5, PM10 and carbon dioxide CO2.`,
};
export const INDOOR_WIDGET_STATUS = {
    statusAQI: [
        'Воздух чистый и свежий, комфортный для продуктивной работы.',
        'Воздух весьма тяжёлый. Стоит проветрить, если за окном свежо.',
        'Качество воздуха критически низкое. Необходимо принять меры.',
    ],
    offlineStatus: 'Станция мониторинга временно отключена',
    notSettings: 'Виджет не настроен, обратитесь в тех.поддержку',
    noData: 'Нет данных',
};
export const ANALYTICS_COMPONENT = {
    tooltip: (index: number, measure: string) =>
        [
            `Общая доля каждого значения ${measure}`,
            `Средние значения ${measure} по часам суток за всю историю наблюдений`,
            `Средние значения ${measure} по дням недели за всю историю наблюдений`,
        ][index],
};

export const FEEDBACK = {
    leaveFeedback: 'Написать нам',
    formTitle: 'Поделитесь вашим мнением',
    formDescription:
        'Мы будем благодарны, если вы напишите свои пожелания по улучшению нашего сервиса',
    formPlaceholder: 'Текст сообщения',
    submit: 'Отправить',
    requiredField: 'Это поле нужно заполнить',
    successTitle: 'Спасибо! Ваше сообщение отправлено',
    successMessage: 'Ваш отзыв будет использован для улучшения нашего сервиса.',
};

export const AQI_DETAILED_INFO = {
    tooltip:
        'Индекс качества воздуха по шкале от 1 (чистый) до 10<br/> (очень грязный). Отражает качество воздуха в текущий<br/> момент.',
    gotoFAQ: 'Подробнее об индексе',
    title: 'Instant AQI',
    description: 'Отражает качество воздуха на текущий момент',
    legendTitle: 'Значения шкалы определяются следующим образом',
    dangerLevelLow: 'безопасно для всех',
    dangerLevelMedium:
        'терпимо, но могут быть негативные реакции у больных сердечно-сосудистыми заболеваниями, аллергиями, астмой',
    dangerLevelHigh: 'опасно для всех',
    selectAqi: 'На графике может отображаться<br>только один из индексов',
};
export const REPORTS = {
    mainTitle: 'Отчёты',
    analiticTitle: 'Аналитика',
    reportsListTitle: 'Отчёты',
    compareTitle: 'Сравнение данных',
    searchText: 'Поиск отчёта',
    settingsAutoText: 'Настроить расписание отчётов',
    createReportTitle: 'Сформировать отчёт',
    historyListTitle: 'История отчётов',
    checkAsReadTitle: 'Отметить все как прочитанные',
    filter: 'Фильтр',
    hTableHeaderFormat: 'Формат',
    hTableHeaderName: 'Название',
    hTableHeaderCreate: 'Дата создания',
    hTableHeaderUser: 'Кем создан',
    hTableHeaderComment: 'Комментарий',
    hTableHeaderStatus: 'Статус',
    hDetailHeader: 'Посты мониторинга',
    CREATED: 'Создан',
    QUEUED: 'На очереди',
    IN_PROGRESS: 'Формируется',
    ERROR: 'Ошибка',
    READY: 'Готов',
    autoGenerate: 'Автоматически',
    clearFilter: 'Сбросить фильтр',
    emptyReport: 'Отчёты не найдены',
    generateReport: 'Сформировать',
    noFoundKind: 'Форма отчёта не найдена',
    returnReportButtonText: 'К списку отчётов',
    noFoundKindReturn: 'Сейчас вернёмся к списку отчётов',
    noReportByPeriod: 'За выбранный период отчётов нет',
    filterPeriod: 'Дата создания',
    formCreate: {
        name: 'Название',
        nameError: 'Введите название отчёта',
        postMoError: 'Поле не может быть пустым',
        calendar: 'Отчётный период',
        commentPlaceholder: 'Комментарий',
        maxLength: (field: string, max: number) => {
            if (field === 'name') {
                return `Название отчёта не должно превышать ${max} символов`;
            } else if (field === 'comment') {
                return `Комментарий не должен превышать ${max} символов`;
            }

            return `Данное поле не должено превышать ${max} символов`;
        },
        max: (field: string, max: number) => `Значение не должно превышать ${max}`,
        min: (field: string, min: number) => `Значение не должно быть меньше ${min}`,
        measure: 'Выбранное вещество не измеряется в заданных постах мониторинга',
        errorTitle: 'Ошибка',
        tryAgain: 'Попробуйте ёще раз',
        search: {
            timezone: 'Искать часовой пояс',
        },
    },
    keyParams: {
        available_formats: 'Формат',
        measure: 'Вещества',
        timezone: 'Часовой пояс',
        year: 'Выберите год',
        fileupload: 'Загрузка файла',
        include_mpc: 'Добавить в долях ПДК',
    },
    measure: 'Вещество',
    titleDeletePopup: (name: string) => `Вы действительно хотите удалить отчёт «${name}»?`,
    titleDeleteCommentPopup: (name: string) =>
        `Вы действительно хотите удалить комментарий к отчёту «${name}»?`,
    titleRenamePopup: 'Переименовать отчёт',
    titleEditComment: 'Редактирование комментария к отчёту',
    titleAddComment: 'Добавление комментария к отчёту',
    addComment: 'добавить',
    bodyDeletePopup: '',
    cancel: COMMON.cancel,
    accept: COMMON.delete,
    reports: {
        zero: 'отчётов',
        one: 'отчёт',
        two: 'отчёта',
        few: 'отчёта',
        many: 'отчётов',
        other: 'отчётов',
    },
    countReportText: (count: number) => `Найдено: ${count}`,
    morePostText: (count: number) => `ещё ${count} `,
    messages: {
        ERROR: {
            title: 'Ошибка',
            text: (name: string) => `При формировании отчёта «${name}» произошла ошибка`,
        },
        READY: {
            title: (name: string) => `Отчёт «${name}» готов`,
        },
        IN_PROGRESS: {
            title: (name: string) => `Отчёт «${name}» формируется`,
        },
    },
    messagesEdit: {
        ERROR: {
            title: 'Ошибка',
            text: (name: string) => `При сохранинии изменении отчёта «${name}» произошла ошибка`,
        },
        READY: {
            title: 'Изменения успешно сохранены',
        },
    },
    messageDeleteSuccess: 'Отчёт успешно удален',
    messageDeleteError: 'Ошибка при удалении отчёта',
    rename: 'Переименовать',
    edit: 'Редактировать',
    save: 'Сохранить',
    totalReports: 'Всего отчётов',
    error403: 'Нет прав для данного действия',
    error401: 'Ошибка формирования отчёта. Попробуйте перезагрузить страницу',
    noPostsMsg: 'Нет данных постов мониторинга для формирования отчёта',
    reportDetails: {
        step: 'Шаг',
        resultProcessingHeader: 'Результаты формирования отчёта',
        tooltipOutputFile: 'Скачать детали отчёта',
        statusCode: {
            parsing_excel: 'Анализ входных данных',
            prepare_concentrations: 'Подготовка данных',
            generate_report_images: 'Формирование графиков',
            generate_output_file: 'Создание отчёта',
            getting_data: 'Получения данных для постов',
            generate_file: 'Формирования файла',
        },
        statusText: {
            Done: 'Успешно',
            Error: 'Ошибка',
            error: 'Ошибка',
        },
        noData: 'Нет данных',
    },
    demo: {
        buttonExamplePdf: 'Годовой отчёт.pdf',
        buttonExampleXlsx: 'Отчёт по качеству связи.xlsx',
        showText: 'Примеры отчётов:',
        contact:
            'Если вы хотите автоматизировать процесс создания отчётов, свяжитесь с вашим менеджером проекта из CityAir или воспользуйтесь контактам ниже: ',
        desc: 'В ПО CityAir.Basic есть возможность создания любых форм отчётов для решения ваших задач',
        tagXlsx: 'Отчёт по качеству связи с устройствами ПАК',
        xlsxPeriod: 'Период',
    },
    download: {
        period: 'Период',
        fileNameDefault: 'Данные_постов',
    },
    fileUpload: {
        uploadText1: 'Перетащите файл сюда или ',
        uploadText2: 'нажмите',
        uploadText3: ', чтобы загрузить',
        availableFormat: 'Доступный формат для загрузки: ',
        availableFormats: 'Доступные форматы для загрузки: ',
        status: {
            loading: 'Загружаем',
            done: 'Файл загружен',
        },
        error: {
            incorrectFormat: 'Данный формат файла не поддерживается.',
            loadError: 'Возникли ошибки во время загрузки',
        },
    },
    confirmRedirectPopUp: {
        title: 'Скачивание данных доступно в новом модуле "Отчёты"',
        body: 'Скоро этот функционал окончательно переедет в модуль. Начните пользоваться прямо сейчас!',
        accept: 'Перейти',
        cancel: 'Отмена',
    },
};
export const FORECAST = {
    mainTitle: 'Прогнозирование',
    controlPointsTitle: 'Контрольные точки',
    addControlPoint: 'Добавить контрольную точку',
    editControlPoint: 'Редактирование контрольной точки',
    newControlPoint: 'Новая контрольная точка',
    nameLabel: 'Название',
    latLabel: 'Широта',
    lngLabel: 'Долгота',
    errorRequired: 'Обязательное значение',
    nameRequiredError: 'Введите название контрольной точки',
    minError: 'Mинимальное значение',
    maxError: 'Максимальное значение',
    maxLength: (max: number) => `Имя контрольной точки не должно превышать ${max} символов`,
    titleDeletePopup: (name: string) =>
        `Вы действительно хотите удалить контрольную точку «${name}»?`,
    bodyDeletePopup:
        'Вы можете позже заново создать контрольную точку, данные в ней загрузятся с момента начала расчётов',
    cancel: COMMON.cancel,
    accept: COMMON.delete,
    deleteList: 'Удалить контрольную точку',
    domainError: 'Координаты контрольной точки за пределами расчетной области',
    edit: EDIT_STATION.edit,
    errorTitle: 'Ошибка',
    tryAgain: 'Попробуйте ёще раз',
    noData: 'Нет контрольных точек',
    errorListLoad: 'Что-то пошло не так, попробуйте позже',
    errorLoadList404: 'Что-то пошло не так, обратитесь в тех.поддержку',
    newControlPointName: 'Новая контрольная точка',
    infoTextCreate:
        'Чтобы добавить контрольную точку, кликните в любое место на карте и перемещайте метку.' +
        ' Для более точной настройки по координатам воспользуйтесь полями ниже.',
    infoTextEdit:
        'Для редактирования расположения контрольной точки перемещайте метку на карте. ' +
        'Для более точной настройки по координатам воспользуйтесь полями ниже.',
};

export const ONBOARDING = {
    doNotShowAgain: 'Больше не показывать',
    before: 'Было',
    after: 'Стало',
    newMapStyle: {
        title: 'Карты CityAir стали подробнее',
        description: [
            'Теперь вы сможете видеть рельеф местности, леса, поля, болота, сельхозобъекты и границы городов.',
        ],
        imgUrl: 'onboarding/new-map-style-07-2022/new-map-style-after.jpg',
        imgUrlCompare: 'onboarding/new-map-style-07-2022/new-map-style-before.jpg',
    },
};
export const INFO_MESSAGE = {
    title: 'Ошибка',
    internetOff: 'Отсутствует интернет-соединение.',
    serverError: 'Сервер временно недоступен, пожалуйста, попробуйте позже.',
    notFoundObject: 'Запрашиваемый объект не существует.',
    notFoundPost: 'У вас нет доступа к выбранному посту мониторинга.',
    tokenExpired: 'Ваш токен устарел. Пожалуйста, перезагрузите страницу.',
    errorQualityDataTimeline: 'Не удалось загрузить информацию о качестве данных',
    Internal_Error: 'Unhandled internal exception',
    Bad_Request: 'Не корректый запрос, обратитесь в тех.поддержку',
    Invalid_UserId: 'Идентификатор пользователя недействителен или не существует',
    Access_Denied: 'У вас нет доступа к запрашиваемым данным',
    Not_Found: 'Запрашиваемые данные не найдены',
    Update_Success: 'Изменения успешно сохранены',
    Create_Success: 'Новый объект успешно создан',
    Create_Error: 'Возникли ошибки при сохранении данных',
    Delete_Success: 'Объект успешно удален',
    Delete_Error:
        'Возникли проблемы при удалении. Пожалуйста, попробуйте позже или обратитесь в тех.поддержку',
    Delete_Post_Error: 'Вы не можете удалить данный пост. Пожалуйста, обратитесь в тех.поддержку',
    update_user_success: LIST_USERS.whenSaving,
    create_user_success_when_add: LIST_USERS.whenAdd,
    create_user_success_when_create: LIST_USERS.whenCreate,
    delete_user_success: 'Пользователь удален',
    no_group: 'У вас нет активных групп',
    windLoadError: 'Запрашиваемые данные по полю ветра не найдены',
    no_forecast_config: 'Модуль прогнозов не настроен.  Пожалуйста, обратитесь в тех.поддержку',
};

export const mmtMapSelectorTooltip =
    'Выберите тип данных. Значение будет отображено в пинах на карте.';

export const QUALITY_DATA = {
    tooltip: {
        noData: 'Данные отстутсвуют',
        tempMaxValue: (val: string) =>
            `Неподтвержденные данные. <br/> Нестабильные метеоусловия. <br/>T выше +${val} °C<br/>`,
        tempMinValue: (val: string) =>
            `Неподтвержденные данные. <br/> Нестабильные метеоусловия. <br/>T ниже ${val} °C<br/>`,
        tempGradient: (val: string) =>
            'Данные газовых сенсоров могут быть<br/> некорректны из-за резкого изменения температуры<br/>',
        phMaxValue: (val: number) =>
            `Неподтвержденные данные. <br/>Нестабильные метеоусловия. <br/>Влажность выше ${val}%<br/>`,
        phGradient: (val: string) =>
            'Достоверность данных по <br/>концентрациям газов снижена<br/>',
        mmtMaxValue: (val: string) => {
            const br = val.length > 30 ? '<br/>' : '';
            return `Больше половины значений <br/> ${val}${br} в интервале усреднения выше<br/>границы диапазона измерений<br/>`;
        },
        mmtMinValue: (val: string) => {
            const br = val.length > 30 ? '<br/>' : '';
            return `Больше половины значений<br/> ${val}${br} в интервале усреднения ниже <br/> границы диапазона измерений<br/>`;
        },
        mmtMinMaxValue: (val: string) =>
            `Значение ${val} вышло за <br/> границы диапазона измерений<br/>`,
    },
    tooltipLink: 'Подробнее',
    tooltipHeader: {
        warning: 'Предупреждение!',
        unverifiedData: 'Неподтвержденные данные',
    },
    timelineLabel: 'Качество данных',
};
export const QUALITY_DATA_INFO = {
    aboutPercentData: (val: number) =>
        `Данные помечаются как недостоверные,<br/> если число пакетов, входящих в расчет, больше 50%`,
    deviceUseHeader: 'Условия эксплуатации:',
    noData: {
        [TEMP]: `Температура окружающей среды за пределами интервала калибровки сенсоров и условий эксплуатации оборудования<br/>`,
        [HUM]: `Влажность находится за пределами диапазона измерения сенсоров и условий эксплуатации оборудования`,
    },
    [TEMP]: (val: string) =>
        `Температура окружающей среды (${val}°C) за пределами интервала калибровки сенсоров и условий эксплуатации оборудования`,
    tempGradient: (val: string) =>
        'Значениях всех измеряемых газов могут быть некорректны из-за резкого изменения градиента температуры',
    [HUM]: (val: number) =>
        `Влажность (${val?.toFixed(
            1
        )}%) находится за пределами диапазона измерения сенсоров и условий эксплуатации оборудования`,
    phGradient: (val: string) =>
        'Значениях всех измеряемых газов могут быть некорректны из-за резкого изменения градиента влажности',
    deviceInfo: {
        [TEMP]: {
            title: ' - температура окружающей среды',
            value: (min, max) => `oт ${min}°C до +${max}°C`,
        },
        [HUM]: {
            title: ' - относительная влажность окружающей среды, не более',
            value: (val) => `${val} %`,
        },
    },
    menuItems: {
        dataQuality: 'Качество данных',
        termsForUse: 'Условия эксплуатации оборудования',
        mainProperty: 'Основные метрологические характеристики',
        markersData: 'Маркировка данных',
        intervalMarkers: 'Диапазоны маркировки',
        conditionsMarkers: 'Условия маркировки N-минуток',
        markersReports: 'Маркировка в отчётах',
    },
    sections: {
        dataQuality:
            'Для любого оборудования существует определённые параметры окружающей среды, при которых полученные с него данные признаются метрологически значимыми, а значит этим данным можно доверять. К этим параметрам относятся температура и влажность. Информация о границах этих диапазонов отражена в описании типа средства измерения в РСИ',
        links: {
            cityair: 'на станцию CityAir',
            gModule: 'на модули расширения G',
            dust: 'на пылемер Dust',
        },
        termsForUse: {
            tempTitle: 'Tемпература окружающей среды',
            tempValue: (min, max) => `oт ${min}°C до +${max}°C`,
            phTitle: 'Относительная влажность окружающей среды',
            phValue: (val) => `не более ${val}%`,
            presTitle: 'Атмосферное давление',
            presValue: 'от 84 до 106,7 кПа',
        },
        mainProperty: {
            tableHeader: {
                mmt: 'Измеряемый параметр',
                interval: 'Диапазон показаний,<br/> мг/м<sup>3</sup>',
                interval1: 'Диапазоны измерений,<br/> мг/м3<sup>3</sup>',
                mainAccuracy: 'Основная допускаемая погрешность, %',
                sub1: 'приведенная<sup>1</sup>',
                sub2: 'относительная',
            },
            titleMmt: {
                NO2: 'Диоксид азота',
                O3: 'Oзон',
                CO: 'Оксид углерода',
                H2S: 'Сероводород',
                SO2: 'Диоксид серы',
            },
            textLimit: 'Предел допускаемого времени установления показаний Т0,9Д — 180 с',
            textAccuracy:
                '<sup>1)</sup> Приведённая погрешность нормирована к верхнему значению диапазона измерений.',
        },
        markersData: {
            header: 'Выделяется несколько основных маркеров данных:',
            listHeader1: 'Предупреждение',
            listHeader2: 'Отсутствие данных',
            listLi: [
                'Значение по измеряемому газу превысило верхнюю границу диапазона измерения',
                'Значения всех измеряемых газов могут быть некорректны из-за выхода метеопараметров (Т, RH) за границы диапазона измерений',
                'Значениях всех измеряемых газов могут быть некорректны из-за резкого изменения градиента (скорости роста или падения значений) метеопараметров (Т, RH)]',
            ],
        },
        intervalMarkers: {
            titleAllData: 'Маркировка всех данных',
            titleMmt: 'Маркировка определённого параметра',
            tempData: {
                title: 'Температура (T)',
                minValue: (val: number) =>
                    `<b> < ${val}</b> — выделение всех параметров с предупреждением <i>«Неподтвержденные данные. Т ниже ${val}°C»</i>`,
                maxValue: (val: number) =>
                    `<b> > +${val}</b> — выделение всех параметров с предупреждением <i>«Неподтвержденные данные. Т выше +${val}°C»</i>`,
                gradient:
                    '<b>%изменение градиента Т%</b> — выделение всех параметров с предупреждением <i>«Нестабильные метеоусловия. Достоверность данных по концентрациям газов снижена»</i>',
            },
            humData: {
                title: 'Влажность (RH)',
                maxValue: (val: number) =>
                    `<b>${val}%</b> — выделение всех параметров с предупреждением <i>«Неподтвержденные данные. Влажность выше ${val}%»</i>`,
                gradient:
                    '<b>%изменение градиента RH%</b> — выделение всех параметров с предупреждением <i>«Нестабильные метеоусловия. Достоверность данных по концентрациям газов снижена»</i>',
            },
            mmtData: {
                tableHeader: {
                    mmt: 'Вещество',
                    min1: 'Нижняя граница, мг/м<sup>3</sup>',
                    max1: 'Верхняя граница, мг/м<sup>3</sup>',
                    min2: 'Нижняя граница, мкг/м<sup>3</sup>',
                    max2: 'Верхняя граница мкг/м<sup>3</sup>',
                    text: 'Текст предупреждения',
                },
            },
            text: (mmt: string) =>
                `Предупреждение! Больше половины значений ${mmt} интервале усреднения выше границы диапазона измерений.`,
            imgExampleCritical: 'Пример выделения неподтвержденных данных',
        },
        conditionsMarkers: {
            text: (val: number) =>
                `Общий принцип основан на вхождении более 50% недостоверных усреднений в интервал, например, если две и больше 5-минутки в 20-минутном интервале усреднения недостоверны, то эта 20-минутка недостоверна.`,
            list: {
                min5: (val: number) =>
                    `5-минутки: три пакета и больше попадают под условия, тогда выдается предупреждение на эту 5-минутку;`,
                min20: (val: number) =>
                    `20-минутки: две и больше пятиминутки попадают под условия, тогда выдается предупреждение на эту 20-минутку;`,
                hour: (val: number) =>
                    `Часовые усреднения: две или три 20-минутки попадают под условия, тогда выдается предупреждение на этот час;`,
                day: (val: number) =>
                    `Суточные усреднения: 12 и больше часовых усреднения попадают под условия, тогда выдается предупреждение на эти сутки.`,
            },
        },
        markersReports:
            'Во всех скачиваемых файлах с данными ячейки с предупреждениями помечаются цветом с указанием причины.',
    },
};
export const ANALYSIS = {
    mainTitle: 'Анализ сети',
    pagesName: {
        summary: 'Сводка',
        links: 'Связи',
        posts: 'Посты',
    },
    summary: {
        header: 'Плотность сети',
        density: ['Очень низкая', 'Низкая ', 'Средняя', 'Высокая'],
        recommendTextTitle: 'Рекомендуется',
        recommendByKey: {
            increase_posts_count: 'увеличить количество постов мониторинга в сети',
            optimize_posts_positioning: 'оптимизировать расположение постов мониторинга',
        },
        infoAttention: 'Посты с наименьшими корреляциями в сети',
        nameHeader: 'Пост мониторинга',
        distHeader: 'До ближ. поста',
        byPeriod: 'за период',
        averageDist: 'Среднее минимальное расстояние между постами',
        squareText: (value) => `на ${value} км<sup>2</sup>`,
        popup: {
            desc: '% существующих постов мониторинга от оптимального (расчетного) количества постов',
            subTitle: 'Среднее минимальное расстояние между постами мониторинга',
            subDesc: 'Позволяет оценить взаимное расположение постов мониторинга',
            increaseValue: ['<40%', 'от 40% до 60%', 'от 60% до 80%', '>80%'],
            averageStatus: ['Очень низкое', 'Низкое', 'Среднее', 'Высокое'],
            averageDist: ['>4км', 'от 4км до 3км', 'от 3км до 2км', '<2км'],
        },
    },
    posts: {
        searchPlaceholder: 'Искать пост мониторинга',
        noFoundPost: 'Посты не найдены.',
    },
    correlation: {
        title_date: 'Корреляция данных',
        titleDetail: 'Корреляция с другими постами',
        titleWind: 'Поле ветра',
        titleWindDesc: 'Повторяемость направления ветра за сутки, (%)',
        filter: {
            all: 'Все',
            max: 'Наибольшая корреляция',
            min: 'Наименьшая корреляция',
        },
        sortHeader: {
            name: 'Название',
            dist: 'Расстояние',
            cor: 'КК',
        },
        unitsDist: ['м', 'км'],
        dateNames: {
            month: 'Месяц',
            quarter: 'Три месяца',
            halfYear: 'Полгода',
            year: 'Год',
        },
        onMMt: 'по',
        chartTitleMmt: (mmt) => `Измерения с постов (${mmt})`,
        chartTitleCorrelation: 'Коэффициент корреляции',
        infoPopUp: {
            title: 'Как считается коэффициент корреляции',
            content:
                'Концентрации загрязняющих веществ подвержены влиянию множества факторов. Разные факторы имеют различную продолжительность воздействия и территориальный охват. Для того, чтобы оценить, насколько различаются условия в разных узлах сети мониторинга, между узлами рассчитывается статистическое расстояние — мера того, насколько близки узлы сети как объекты, характеризующиеся историей наблюдений. <b>В качестве количественной меры статистического расстояния  используется коэффициент корреляции.</b> ',
            content1:
                'Коэффициент корреляции (КК) между двумя рядами наблюдений X и Y — это число в интервале от -1 до 1. КК равен 1, если между рядами наблюдений существует жесткая линейная связь: Y = a + b X, при постоянных значениях a и b и b > 0.',
            title2: 'Для качественной оценки силы связи используется шкала Чеддока: ',
            title3: 'Отрицательные значения указывают на обратную связь: если X возрастает, то Y убывает и наоборот.',
            values: [
                '-1,0,<br/>-<br/>-0,9',
                '-0,9<br/>-<br/>-0,7',
                '-0,7<br/>-<br/>-0,5',
                '-0,5<br/>-<br/>-0,3',
                '-0,3<br/>-<br/>-0,1',
                '-0,1<br/>-<br/>0,1',
                '0,1<br/>-<br/> 0,3',
                '0,3<br/>-<br/> 0,5',
                '0,5<br/>-<br/>0,7',
                '0,7<br/>-<br/> 0,9',
                '0,9<br/>-<br/>1,0',
            ],
            valuesLegend: [
                '-1,0 - -0,9 ',
                '-0,9 - -0,7',
                '-0,7 - -0,5',
                '-0,5 - -0,3',
                '-0,3 - -0,1',
                '-0,1 - 0,1',
                '0,1 -  0,3',
                '0,3 -  0,5',
                '0,5 - 0,7',
                '0,7 -  0,9',
                '0,9 - 1,0',
            ],
            labels: [
                'Сильная\n' + 'отрицательная',
                'Высокая отрицательная',
                'Заметная\n' + 'отрицательная',
                'Умеренная\n' + 'отрицательная',
                'Слабая\n' + 'отрицательная',
                'Отсутствующая',
                'Слабая\n' + 'положительная',
                'Умеренная\n' + 'положительная',
                'Заметная\n' + 'положительная',
                'Высокая\n' + 'положительная',
                'Сильная\n' + 'положительная',
            ],
        },
        shortLabel: 'КК',
        notFoundCorrelationData: 'Данные корреляции не найдены.',
        back: 'К списку постов',
        noData: 'Нет данных',
        goToLinks: 'Перейти к связи',
        emptyDataByPeriod: 'Нет данных для расчёта корреляции за выбранный период.',
    },
    mapLabelInfoPin: 'Зона рекомендуемого расширения сети',
    closeChart: 'Закрыть связь',
};
export const ANALYSIS_EVENTS = {
    mainTitle: 'Анализ эпизодов',
    registerEventsTitle: 'Журнал событий',
    registerEvents: {
        titleHeader: {
            oftenMmt: (currentYear) =>
                `Чаще всего за ${currentYear} год фиксировались превышения по`,
            avarageDuration: (currentYear) =>
                `Средняя продолжительность эпизодов в ${currentYear} году`,
            numbersofIncident: (currentYear) =>
                `Количество дней за ${currentYear}, когда фиксировались превышения в сети`,
        },
        tableHeader: {
            mmt: 'Параметр',
            value: 'Значение',
            name: 'Пост мониторинга',
            period: 'Начало / Окончание',
            periodStart: 'Начало',
            periodEnd: 'Окончание',
            forecast: 'Прогноз окончания',
            duration: 'Длительность',
            veracity: 'Достоверность',
            mmtLine: 'Измерения',
            forecastLine: 'Расчёт нейросети',
            status: 'Статус',
            all: 'Все',
        },
        searchText: 'Поиск по посту, параметру или №',
        clearText: 'Сбросить фильтры',
        pdkMr: 'ПДКмр',
        actions: {
            analysis: 'Анализ',
        },
        statusText: {
            NEW: 'Новое',
            WAIT: 'Ждет решения',
            CANCEL: 'Отклонено',
            ACCEPT: 'Принято',
        },
        statusTextCounter: {
            NEW: 'Новые',
            WAIT: 'Ждут решения',
            CANCEL: 'Отклонено',
            ACCEPT: 'Принято',
        },
        typeByEndText: {
            NEW: 'Новое',
            END: 'Завершено',
        },
        typeByEndTextCounter: {
            NEW: 'Новые',
            END: 'Завершенные',
        },
        total: 'Всего',
        noEventsByPeriod: 'Эпизоды за выбранный период не найдены',
        clearFilter: 'Сбросить',
    },
    downloadEventsButton: 'Скачать журнал',
    analysisEvent: {
        buttonText: 'Анализ событий',
        tableHeader: {
            mmt: 'Параметр',
            value: 'Значение',
            name: 'Пост мониторинга',
            period: 'Начало / Окончание',
            forecast: 'Прогноз окончания',
            duration: 'Длительность',
            veracity: 'Достоверность',
            mmtLine: 'Измерения',
            forecastLine: 'Расчёт нейросети',
        },
        cardTitle: {
            mainStatistics: 'Общая статистика',
            postMmts: 'Измерения с постов',
            correlationData: 'Корреляция данных',
            windData: 'Поле ветра',
            correlationMap: 'Корреляция с другими постами',
            searchSource: 'Поиск источника',
            averageDurations: 'Средняя продолжительность превышения за последний квартал',
            numbersByDay: 'Количество превышений по часам суток за последний квартал',
            concentration: (mmt) =>
                `Концентрации по ${mmt} накопительным итогом за последний квартал`,
            correlationTable: 'Таблица корреляции данных по сети',
            comments: 'Обсуждение',
        },
        statisticTabs: {
            100: 'Все посты',
            90: '90% постов',
            50: '50% постов',
            20: '20% постов',
        },
        barChartLabels: ['< 30 мин', '0.5 — 1 ч.', '1 — 2 ч.', '2 — 4 ч.', '4 — 8 ч.', '> 8 ч.'],
        barChartNoDataText: 'За выбранный период превышений не зафиксировано',
        WHOChartLabels: [
            'ВОЗ — промежуточный показатель № 2',
            'ВОЗ — промежуточный показатель № 3',
            'ВОЗ — промежуточный показатель № 4',
        ],
        statisticsText: (mmt) =>
            `Количество  дней за последний год, когда фиксировалось превышение в сети по ${mmt}`,
        statisticAverageResult: 'Большинство превышений по сети на постах длятся до 4 часов',
        statisticAverageResultByIndex: [
            'Большинство превышений по сети на постах длятся до 30 минут',
            'Большинство превышений по сети на постах длятся до часа',
            'Большинство превышений по сети на постах длятся до 2 часов',
            'Большинство превышений по сети на постах длятся до 4 часов',
            'Большинство превышений по сети на постах длятся до 8 часов',
            'Большинство превышений по сети на постах длятся больше 8 часов',
        ],
        statisticDaysNumberResult:
            'Основной пик превышений по сети приходится на интервал с 8:00 до 12:00',
        statisticDaysNumberResultByIndex: (index) =>
            `Основной пик превышений по сети приходится на интервал с ${index}:00 до ${
                index + 1
            }:00`,
        searchButtonText: 'Найти источник',
        notEnded: 'не завершено',
        finishChartLabel: 'Окончание',
        correlationTabsName: {
            month: 'Месяц',
            quarter: 'Квартал',
            halfYear: 'Полгода',
            year: 'Год',
        },
        eventTypeTitle: 'Тип события',
        eventTypeName: {
            global: 'Глобальное событие.',
            local: 'Локальное событие.',
        },
        eventTypeDesc: {
            global: 'Большое количество станций сети фиксируют превышения, скорее всего это глобальное событие. Например, лесные пожары, перенос пыли из пустыни или сильный выброс от источника.',
            local: 'Меньше половины станций сети фиксируют превышения, скорее всего это локальное событие.',
        },
        forecastEndTitle: 'Прогноз окончания',
        resultExpert: 'Решение эксперта',
        doneResultTitle: 'Утвердил',
        userRole: {
            ecolog: 'Эколог',
            mainEcolog: 'Главный эколог',
        },
        dataCorrChartLabel: 'за 1 месяц до начала эпизода',
        dataCorrChartLabelLastDay: 'за 24 часа до начала эпизода',
        windDescriptionLabel: 'Повторяемость направления ветра за сутки,',
        actions: {
            asResult: 'Выбрать как результат',
            add: 'Добавить в журнал',
            cancel: 'Отклонить событие',
            addComment: 'Добавить комментарий',
        },
        analysisEvent: 'Анализ эпизода',
        analysisResults: 'Результаты анализа',
        collapse: 'Свернуть',
        expand: 'Показать',
        excess: {
            zero: 'превышений',
            one: 'превышение',
            two: 'превышения',
            few: 'превышения',
            many: 'превышений',
            other: 'превышений',
        },
    },
    more: 'ещё',
    monitoring: `мониторинга`,
    notEnded: 'не завершено',
};
export const MAP_STYLE_SELECTOR = {
    title: 'Карты',
    labels: {
        cityair: 'CityAir',
        satellite: 'Спутник',
        yandex: 'Яндекс.Карты',
        osm: 'Схема',
        outdoor: 'Ландшафт',
    },
};
export const MAP_CONTROL = {
    title: {
        default: 'Данные за период',
        nowOnMap: 'Сейчас на карте',
        forecast: 'Прогноз на',
    },
    on: 'по',
    labels: {
        pollutant: 'Загрязнители',
        meteo: 'Метеопараметры',
    },
};
export const IMPACT = {
    cities: {
        title: 'Город',
        currentDate: 'Сейчас в городе:',
    },
    cityEnvChars: {
        title: 'Характеристика городской среды',
    },
    networkDensity: {
        title: 'Плотность сети',
        linkMoreText: 'Подробнее',
        headers: {
            name: 'Районы',
            posts: 'Посты',
            area: 'Площадь',
            density: 'Плотность',
        },
        density: ['Очень низкая', 'Низкая ', 'Средняя', 'Высокая'],
        monitoring: `мониторинга`,
        squareText: (value) => `на ${value} км<sup>2</sup>`,
    },
    emissionSources: {
        title: 'Источники выбросов',
        link: 'К источникам',
    },
    cityDistricts: {
        title: 'Районы города',
        link: 'К контрольным точкам',
    },
    airQualityCity: {
        title: 'Качество воздуха в городе',
    },
    sources: {
        title: 'Источники',
        backToSources: 'К списку источников',
        labels: {
            objTitle: 'Название источника',
        },
        filters: {
            period: 'Период',
            measure: 'Вещество',
        },
        noData: 'Нет источников',
        noFoundSource: 'Источник не задан',
        sourceCard: {
            sourceType: {
                point: 'Точечный',
                square: 'Площадной',
            },
            emissionIntensity: 'Интенсивность выброса',
            titleSource: 'Параметры источника',
            nameSource: 'Название источника',
            type: 'Тип',
            height: 'Высота',
            square: 'Площадь',
            titleParams: 'Параметры выбросов ПДВ',
            formTube: 'Форма устья',
            numberPDV: '№ПДВ',
            estimatedEmissions: 'Расчетные выбросы',
            estimatedEmissionsUnit: 'т/г',
            modelingParamsUnit: 'г/сек',
            modelingParams: 'Параметры моделирования',
            influenceStatistics: 'Статистика влияния',
            avarageContribution: 'Средняя продолжительность влияния источника за',
        },
    },
    mapObjectTitle: {
        posts: 'Посты мониторинга',
        points: 'Контрольные точки',
        sources: 'Источники',
        regions: 'Районы',
    },
    regions: {
        fields: {
            name: 'Название',
        },
    },
    region: {
        backTitle: 'К списку районов',
        subtitle: 'Район',
        statisticTitle: 'Статистика превышений',
        noStatsRegion: 'Нет данных по статистики превышений',
        otherLabel: 'Остальные',
        titleChartExceed: 'Влияние источников на район по',
        titleChartExceedFor: 'за',
        titleChartAverageMmt: ' Средняя расчетная концентрация загрязняющих веществ за ',
        titleChartMax: 'Максимальная расчетная разовая концентрация',
        titleChartNumberExceed:
            ' Количество превышений расчетных концентраций выше порогового (1 ПДКмр) за ',
        noChartExceed: 'Влияний источников не зарегистрированно',
        noChartAverageMmt: 'Нет данных по средней концентрации',
        noChartNumberExceed: 'Превышений не зафиксированно',
    },
    controlPoints: {
        buttonAdd: 'Добавить',
        noControlPoints: 'Нет контрольных точек',
        noRegions: 'Нет рaйонов',
        noStations: 'Нет постов мониторинга',
        tabs: {
            regions: 'Районы',
            points: 'Контрольные точки',
            posts: 'Посты',
        },
        sites: {
            subTitle: 'Пост мониторинга',
        },
    },
    eventList: {
        emptyDataEvents: 'За выбранный период эпизодов не зафиксированно',
        filterLabelAll: 'Все',
        headerStart: 'Начало',
        details: {
            backTitle: 'К списку инцидентов',
            postsTitle: 'Список постов',
            postHeader: 'Пост мониторинга',
            postsHeader: 'Посты мониторинга',
            mmtHeader: 'Вещество',
            maxHeader: 'Max концентрация',
            mainImpactHeader: 'Вклад  источников в событие',
            mainImpactDescription: 'Вклад источников в зафиксированное превышение на',
            mainImpactDescriptionFor: 'по',
            posts: {
                zero: 'постов мониторинга',
                one: 'посту мониторинга',
                two: 'постах мониторинга',
                few: 'постах мониторинга',
                many: 'постах мониторинга',
                other: 'постах мониторинга',
            },
            showMoreBtn: 'Подробнее',
            closeBtn: 'Закрыть',
            printRtn: 'Печать',
            mainImpact: 'Основной вклад',
            repeatHeader: 'Повторяемость превышений',
            allSourceHeader: 'Все источники в событии',
            correlationChartHeader: 'Корреляция данных',
            threeMonthLabel: 'за 3 месяца',
            lastDayLabel: 'за последние 24 часа',
        },
    },
    chartNoContribution: 'Вкладов инвентаризованных источников не зафиксировано',
};
export const NETWORK = {
    mapControl: {
        title: 'Период расчётов',
    },
    priorityHeader: 'Области для приоритетного размещения постов мониторинга',
    postHeader: 'Средняя расчетная концентрация в области установки постов мониторинга',
    name: 'Название',
    hasPost: 'Есть',
    monitoring: 'мониторинга',
    neighborPost: 'в соседней области',
    periodTitle: 'Среднее за период',
    polygonName: 'Область',
    showMore: `Показать еще`,
    collapse: 'Свернуть',
    areas: {
        zero: 'областей',
        one: 'область',
        two: 'области',
        few: 'области',
        many: 'областей',
        other: 'areas',
    },
};
