import { Pipe, PipeTransform } from '@angular/core';
import { ChartDataEvent } from '@cityair/modules/impact/service/api-model-impact';

import { Dictionary } from '@ngrx/entity';
import { CONTRIBUTIONS_COLORS_SOURCES_ORDER, getColorByIndex } from '@libs/common';
import { ContributionsSource, Feature } from '@libs/common';

@Pipe({
    name: 'dataSourceEvent',
})
export class DataSourceEventPipe implements PipeTransform {
    transform(data: { [key: string]: Feature[] }, mmt): ChartDataEvent {
        const result = [];
        const posts = Object.keys(data);
        let total = 0;
        const summary = {};
        let sources: Dictionary<ContributionsSource>;
        let order;
        posts?.forEach((post) => {
            const contributions = data[post]?.[0].properties?.contributions[mmt];
            sources = data[post]?.[0].properties?.contributionsDetails.sources;
            order = data[post]?.[0].properties?.contributionsDetails.order;
            const timelineContributions = data[post]?.[0].properties?.timelineContributions[mmt];
            const totalContribution = timelineContributions.reduce((a, b) => a + b, 0);
            total += totalContribution;
            Object.keys(contributions)?.forEach((v) => {
                let value = 0;
                timelineContributions?.forEach((k, index) => {
                    value += +(k * contributions[v][index]).toFixed(2);
                });
                const oldValue = summary[v] ?? 0;
                summary[v] = oldValue + Math.round(value);
            });
        });

        if (sources) {
            Object.values(sources)?.forEach((source) => {
                if (summary[source.id]) {
                    const colorIndex = order.findIndex((k) => k === source.id);
                    const value = Math.round(summary[source.id] / 100);
                    if (value) {
                        result.push({
                            source_id: source.id,
                            value,
                            label: source?.name,
                            color:
                                getColorByIndex(CONTRIBUTIONS_COLORS_SOURCES_ORDER, colorIndex) ??
                                '#f5f6f7',
                        });
                    }
                }
            });

            result.sort((a, b) => (a?.value > b?.value ? -1 : 1));
        }

        const datasets = [];
        const colors = result.map((v) => v.color);
        datasets.push({
            data: result.map((v) => Math.round(v.value)),
            backgroundColor: colors,
            hoverBackgroundColor: colors,
            hoverBorderColor: 'transparent',
        });

        return {
            labels: result.map((v) => v.label),
            datasets,
            colors,
            percents: result.map((v) => Math.round((v.value * 100) / total)),
        };
    }
}
