<div class="widget">
    <div class="widget-header">
        <div class="widget__title">
            {{ title }}
            <span class="widget__title-info"></span>
        </div>
        <div *ngIf="widgetLink" class="widget__link">
            <ng-container
                *ngTemplateOutlet="widgetLink"
            ></ng-container>
        </div>
    </div>
    <ng-content></ng-content>
</div>
