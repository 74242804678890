import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { EntitySelectorsFactory, QueryParams } from '@ngrx/data';
import { selectLoadingTimeline } from '@cityair/modules/core/store/selectors';
import { ControlPointForecast, ForecastConfig, getVangaScheme, Station } from '../models';
import { NEW_CONTROL_POINT_OBJ_TYPE } from '../constants';
import { ForecastState, IForecastState } from './reducers';
import { pipe } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import {
    COLOR_ZONE_BY_METEO_FORECAST,
    createBoundaries,
    DEMO_FORECAST_METEO_GROUPS,
    METEO_MMTS_FORECAST,
    MeasureScheme,
    ColorZone,
    isFalseNumber,
    WD,
} from '@libs/common';

import { DomainConfigType } from '@cityair/modules/map/components/mapbox/domain-tiles-player/domain-config.type';

import {
    transformMeteoForecastToBasicMmt,
    transformBasicMmtToForecast,
} from '@cityair/modules/forecast/utils/transform-meteo-mmt';
import {
    selectCurrentMeasureScheme,
    selectExtConfig,
    selectGroupId,
    selectMeasuresZones,
} from '@libs/shared-store';

export const forecastsSelectors = new EntitySelectorsFactory().create<ForecastConfig>('Forecasts');
export const selectForecasts = createSelector(
    forecastsSelectors.selectEntities,
    (entities) => entities
);
export const forecastsAvailableInterval = createSelector(selectForecasts, (entities) => {
    let max = null;
    let min = null;
    if (entities.length) {
        entities.forEach((item) => {
            if (!item.meteo) {
                if (!max && !item.meteo) {
                    max = item.end;
                }
                if (!min) {
                    min = item.start;
                }
                max = new Date(item.end) > new Date(max) ? item.end : max;
                min = new Date(item.start) < new Date(min) ? item.start : min;
            }
        });

        return {
            minDate: min,
            maxDate: max,
        };
    }
});
export const stationSelectors = new EntitySelectorsFactory().create<Station>('Station');
export const getStations = createSelector(stationSelectors.selectEntities, (entities) => entities);
export const stationLoaded = createSelector(stationSelectors.selectLoaded, (entities) => entities);

export const controlPointSelectors = new EntitySelectorsFactory().create<ControlPointForecast>(
    'ControlPoint'
);
export const getControlPoints = createSelector(
    controlPointSelectors.selectEntityMap,
    (entities) => entities
);
export const selectControlPointsForecast = createSelector(
    controlPointSelectors.selectEntities,
    (entities) => entities
);
export const loadedControlPoints = createSelector(
    controlPointSelectors.selectLoaded,
    (value) => value
);

export const selectControlPointById = (id: number) =>
    createSelector(getControlPoints, loadedControlPoints, (entities, isLoaded) => {
        if (isLoaded) {
            return entities[id] ? entities[id] : null;
        }
    });

export const getControlPointValues = (point: ControlPointForecast) =>
    createSelector(currentForecastMmt, selectCurrentIndexForecast, (mmt, index) => {
        if (!isFalseNumber(index) && mmt && point) {
            const arr = point.timeseries?.values[mmt];
            return arr ? arr[index] : null;
        }
    });

export const forecastState = createFeatureSelector<IForecastState>('forecast');
export const selectForecastCore = createSelector(
    forecastState,
    (state: IForecastState): ForecastState => state?.core
);
export const selectDateRange = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.dateRange
);
export const currentForecastMmt = createSelector(
    selectForecastCore,
    (state: ForecastState) => state?.currentMmt
);
export const isActiveForecast = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.isActiveModule
);
export const showLayerOnMap = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.showLayerOnMap
);
export const isValidToken = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.isValidToken
);
export const isReloadControlPoint = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.updateControlPoint
);
export const selectControlPointError = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.controlPointsError
);
export const selectActivePoint = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.activePoint
);
export const selectListActive = createSelector(selectForecastCore, (state: ForecastState) => [
    state.activePoint?.id,
    state.activeStation?.id,
]);
export const selectErrorLoadList = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.errorLoadList
);
export const selectIsDisplayStationOnMap = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.displayStationOnMap
);
export const initQueryConfig = createSelector(
    selectExtConfig,
    selectGroupId,
    isValidToken,
    (extConfig, groupId, isValidToken) => {
        const isAvailable = extConfig?.showForecastModule ? true : false;
        if (groupId && isValidToken && isAvailable) {
            return groupId;
        }
    }
);
export const selectForecastConfig = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.forecastConfig
);

export const selectDatesForecast = createSelector(
    selectForecastCore,
    (state: ForecastState) => state?.datesControlPoints || state?.datesStation
);
export const selectDatesStationForecast = createSelector(
    selectForecastCore,
    (state: ForecastState) => state?.datesStation
);
export const selectCurrentIndexForecast = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.currentTimeIndex
);
export const selectForecastTime = createSelector(
    selectDatesForecast,
    selectCurrentIndexForecast,
    (dates, index) => {
        return new Date(dates[index]).getTime();
    }
);
export const selectForecastCurrentTime = pipe(
    select(selectForecastTime),
    distinctUntilChanged((prev, current) => prev === current)
);
export const selectForecastTimeRangeWithoutDistinct = createSelector(
    selectDatesForecast,
    (dates) => ({
        begin: new Date(dates[0]).getTime(),
        end: new Date(dates[dates.length - 1]).getTime(),
    })
);
export const selectForecastTimeRange = pipe(
    select(selectForecastTimeRangeWithoutDistinct),
    filter((v) => !isNaN(v.begin) || !isNaN(v.end)),
    distinctUntilChanged(
        (prev, current) => prev.begin === current.begin && prev.end === current.end
    )
);
export const selectCurrentTimeForecast = createSelector(
    selectDatesForecast,
    selectCurrentIndexForecast,
    (dates, index) => new Date(dates[index]).getTime()
);
export const selectCurrentTimeStrForecast = createSelector(
    selectDatesForecast,
    selectCurrentIndexForecast,
    (dates, index) => dates[index]
);
export const selectCurrentIndexStation = createSelector(
    selectCurrentTimeStrForecast,
    selectDatesStationForecast,
    (time, dates) => dates.findIndex((v) => v === time)
);

export const getStationsValues = (post: Station) =>
    createSelector(currentForecastMmt, selectCurrentIndexStation, (mmt, stationIndex) => {
        if (!isFalseNumber(stationIndex) && mmt && post) {
            const currentMmt = transformBasicMmtToForecast(mmt);
            const arr = post.data?.measurements?.[currentMmt]?.values;
            return arr ? arr[stationIndex] : null;
        }
    });
export const selectActiveStation = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.activeStation
);
export const selectNewCoordinates = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.coordinates
);
export const selectNewControlPoints = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.newControlPoint
);
export const selectEditControlPointId = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.editControlPointId
);
export const selectChartData = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.chartData
);
export const selectCurrentForecastConfig = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.currentConfig
);
export const selectIsHiddenLayerOnMap = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.isHiddenLayerOnMap
);
export const selectIsShowForecastLayerOnMap = createSelector(
    selectIsHiddenLayerOnMap,
    showLayerOnMap,
    (isHidden, isShow) => !isHidden && isShow
);
export const getControlPointsForMap = createSelector(
    selectControlPointsForecast,
    selectNewControlPoints,
    (controlPoints, newControlPoint) => {
        const result = [...controlPoints];
        if (newControlPoint) {
            result.push(newControlPoint);
        }

        return result;
    }
);
export const isDraggableControlPoint = (point: ControlPointForecast) =>
    createSelector(getControlPointsForMap, selectEditControlPointId, (controlPoints, editId) => {
        if (editId && editId === point.id) {
            return true;
        }
        const newControlPoint = controlPoints.find(
            (item) => item.obj === NEW_CONTROL_POINT_OBJ_TYPE
        );
        if (newControlPoint && point.id === null) {
            return true;
        }
        return false;
    });
export const selectForecastMapSettings = createSelector(
    selectExtConfig,
    selectLoadingTimeline,
    (extConfig, loadingTimeline) => {
        const forecastMapSettings = extConfig?.forecastMapSettings;
        if (forecastMapSettings && !loadingTimeline) {
            return forecastMapSettings;
        }
    }
);
export const selectForecastZone = createSelector(
    selectCurrentMeasureScheme,
    selectMeasuresZones,
    currentForecastMmt,
    (scheme, zones, currentMmt) => {
        const mmt = transformMeteoForecastToBasicMmt(currentMmt);
        if (mmt !== currentMmt) {
            return COLOR_ZONE_BY_METEO_FORECAST[currentMmt];
        }
        return zones[scheme][currentMmt] as ColorZone;
    }
);
export const selectForecastSchema = createSelector(
    selectCurrentMeasureScheme,
    currentForecastMmt,
    (scheme, currentMmt) => ({
        scheme: scheme,
        mmt: currentMmt,
    })
);
export const selectForecastSchemaZones = createSelector(
    selectCurrentMeasureScheme,
    currentForecastMmt,
    selectMeasuresZones,
    (scheme, currentMmt, zones) => {
        if (zones && scheme && currentMmt) {
            const mmt = transformMeteoForecastToBasicMmt(currentMmt);
            if (mmt !== currentMmt) {
                return currentMmt !== WD
                    ? {
                          scheme: scheme,
                          mmt: mmt,
                          zone: COLOR_ZONE_BY_METEO_FORECAST[currentMmt],
                      }
                    : null;
            }
            return {
                scheme: scheme,
                mmt: currentMmt,
                zone: zones[scheme][currentMmt] as ColorZone,
            };
        }
        return null;
    }
);
export const initCalendarData = createSelector(
    forecastsAvailableInterval,
    selectDateRange,
    (interval, dateRange) => {
        if (interval && dateRange) {
            const minDate = interval?.minDate;
            const maxDate = interval?.maxDate;
            return {
                dateRange: dateRange,
                minDate,
                maxDate,
            };
        }
    }
);
export const selectSpeciesList = createSelector(
    selectCurrentForecastConfig,
    (config) => config?.species_list ?? []
);
export const selectIsMeteo = createSelector(selectGroupId, currentForecastMmt, (groupId, mmt) => {
    return DEMO_FORECAST_METEO_GROUPS.includes(groupId) && METEO_MMTS_FORECAST.includes(mmt);
});
export const selectForecastDataForMap = createSelector(
    selectCurrentForecastConfig,
    selectGroupId,
    isActiveForecast,
    selectIsMeteo,
    selectForecasts,
    (config, groupId, isActive, isMeteo, configs) => {
        if (config && groupId && isActive) {
            /* if (isMeteo) {
                const meteoConfig = configs.find((v) => v.meteo);
                if (meteoConfig) {
                    const domain: DomainConfigType = {
                        slug: meteoConfig.domain.slug,
                        substances: METEO_MMTS_FORECAST,
                        coordinates: createBoundaries(meteoConfig.domain.bbox),
                        extent: meteoConfig.domain.bbox,
                        start: config.domain.start,
                        end: config.domain.end,
                    };
                    return { domain, groupId };
                }
            } else { */
            const domain: DomainConfigType = {
                slug: config.domain.slug,
                substances: config.species_list,
                coordinates: createBoundaries(config.domain.bbox),
                extent: config.domain.bbox,
                start: config.domain.start,
                end: config.domain.end,
            };
            return { domain, groupId };
            // }
        }
        return null;
    }
);
export const getParamsControlPoint = createSelector(
    selectGroupId,
    selectDateRange,
    currentForecastMmt,
    selectCurrentMeasureScheme,
    (groupId, dateRange, currentMmt, scheme) => {
        if (groupId && dateRange && currentMmt && scheme) {
            let params: QueryParams = {
                group_id: groupId,
                start: new Date(dateRange.startDate).toISOString(),
                end: new Date(dateRange.finishDate).toISOString(),
                species: currentMmt,
                h: '0',
                measure_scheme: getVangaScheme(scheme),
            };
            if (
                DEMO_FORECAST_METEO_GROUPS.includes(groupId) &&
                METEO_MMTS_FORECAST.includes(currentMmt)
            ) {
                params = { ...params, meteo: '1' };
            }
            return params;
        }
    }
);
export const getParamsStation = createSelector(
    selectGroupId,
    selectDateRange,
    currentForecastMmt,
    selectCurrentMeasureScheme,
    (groupId, dateRange, currentMmt, scheme) => {
        if (groupId && dateRange && currentMmt && scheme) {
            const mmt = transformMeteoForecastToBasicMmt(currentMmt);
            const params: QueryParams = {
                group_id: groupId,
                date__gt: new Date(dateRange.startDate).toISOString(),
                date__lt: new Date(dateRange.finishDate).toISOString(),
                packet_type: mmt,
                measure_scheme: scheme !== MeasureScheme.mpc ? scheme : MeasureScheme.default,
                concentration_in_mpc: (scheme === MeasureScheme.mpc).toString(),
                obj_filter: 'outdoor_post',
            };
            return params;
        }

        return null;
    }
);
export const selectMmtIsWD = createSelector(currentForecastMmt, (currentMmt) => currentMmt === WD);
export const getStationsForMapForecast = createSelector(
    getStations,
    selectIsDisplayStationOnMap,
    (posts, showOnMap) => (showOnMap ? posts : [])
);
