<div class="impact-sources scroll">
    <div class="sources-table">
        <div class="sources-table__body" *ngIf="sources$ | async as sources">
            <ng-container *ngIf="sources?.length else noDataTemp">
                <div
                    *ngFor="let source of sources"
                    class="source-item"
                    (click)="goToSource(source)"
                >
                    <div class="source-item__content">
                        <div class="source-item__title">{{ source.name }}</div>
                        <div class="source-item__params">
                            <div class="source-item__param">
                                <div class="source-item__param-title">{{ textImpact.sources.sourceCard.emissionIntensity}}</div>
                                <div class="source-item__param-value"
                                     *ngIf="activeRun$ | async as run">
                                    {{ source | impactSourceEmission : run : null : false | valueFormatNumber : 4 }} {{ textImpact.sources.sourceCard.modelingParamsUnit}}
                                </div>
                            </div>
                            <div class="source-item__param">
                                <div class="source-item__param-title">{{ textImpact.sources.sourceCard.type}}</div>
                                <div class="source-item__param-value"
                                     *ngIf="source | impactSourceType as type">
                                    {{ textImpact.sources.sourceCard.sourceType[type]}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="source-item__image"
                        [attr.data-source-id]="source.id"
                    ></div>
                </div>
            </ng-container>
            <ng-template #noDataTemp>
                <non-data-screen
                    class="screen-placeholders"
                    [text]="textImpact.sources.noData"
                ></non-data-screen>
            </ng-template>
        </div>
    </div>
</div>
