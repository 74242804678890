import { Pipe, PipeTransform } from '@angular/core';
import { DataEvent } from '@cityair/modules/impact/service/api-model-impact';

import { Feature } from '@libs/common';
import { CONTRIBUTIONS_COLORS_SOURCES_ORDER } from '@libs/common';

@Pipe({
    name: 'eventContribution',
})
export class EventContributionPipe implements PipeTransform {
    transform(data: Feature[], mmt): DataEvent[] {
        const result = [];
        if (data?.length) {
            const contributions = data?.[0].properties?.contributions[mmt];
            const timelineContributions = data?.[0].properties?.timelineContributions[mmt];
            const summarySources = {};
            const totalContribution = timelineContributions.reduce((a, b) => a + b, 0);
            Object.keys(contributions)?.forEach((v) => {
                let value = 0;
                timelineContributions?.forEach((k, index) => {
                    value += +(k * contributions[v][index]).toFixed(2);
                });
                summarySources[v] = value / totalContribution;
            });

            Object.keys(contributions).forEach((v) => {
                if (summarySources[v]) {
                    const source = data?.[0].properties?.contributionsDetails.sources[v];
                    const colorIndex = data?.[0].properties?.contributionsDetails.order.findIndex(
                        (k) => k === Number(v)
                    );
                    const value = Math.round((summarySources[v] * totalContribution) / 100);
                    if (value) {
                        result.push({
                            source_id: v,
                            value,
                            label: source?.name,
                            color: CONTRIBUTIONS_COLORS_SOURCES_ORDER[colorIndex] ?? '#f5f6f7',
                            percent: Math.round(summarySources[v]),
                        });
                    }
                }
            });
            result.sort((a, b) => (a?.value > b?.value ? -1 : 1));
        }
        return result;
    }
}
