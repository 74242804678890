import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImpactMainComponent } from '@cityair/modules/impact/components/impact-main.component/impact-main.component';
import { IMPACT_PAGES, IMPACTS_INIT_PAGE } from '@cityair/modules/impact/models';
import { ControlPointsComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/control-points.component';
import { ImpactCitiesComponent } from '@cityair/modules/impact/components/impact-main.component/impact-cities/impact-cities.component';
import { ImpactEditRunComponent } from '@cityair/modules/impact/components/impact-main.component/impact-runs/impact-edit-run/impact-edit-run.component';
import { ImpactSourcesComponent } from './components/impact-main.component/impact-sources/impact-sources.component';
import { ImpactSourceComponent } from './components/impact-main.component/impact-source/impact-source.component';
import { ImpactEventListComponent } from '@cityair/modules/impact/components/impact-main.component/impact-event-list/impact-event-list.component';
import { ImpactEventDetailComponent } from '@cityair/modules/impact/components/impact-main.component/impact-event-list/impact-event-detail/impact-event-detail.component';
import { ControlPointFormComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/impact-points/control-point-form/control-point-form.component';
import { ImpactPointsComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/impact-points/impact-points.component';
import { ImpactRegionsComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/impact-regions/impact-regions.component';
import { ImpactPostsComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/impact-posts/impact-posts.component';
import { ImpactRegionDetailComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/impact-regions/impact-region-detail/impact-region-detail.component';

export const impactRoutes: Routes = [
    {
        path: '',
        component: ImpactMainComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: IMPACTS_INIT_PAGE,
            },
            {
                path: IMPACT_PAGES.cities,
                component: ImpactCitiesComponent,
            },
            {
                path: IMPACT_PAGES.sources,
                component: ImpactSourcesComponent,
            },
            {
                path: IMPACT_PAGES.sources + '/:id',
                component: ImpactSourceComponent,
            },
            {
                path: IMPACT_PAGES.controlPoints,
                component: ControlPointsComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: IMPACT_PAGES.regions,
                    },
                    {
                        path: IMPACT_PAGES.regions,
                        component: ImpactRegionsComponent,
                    },
                    {
                        path: IMPACT_PAGES.points,
                        component: ImpactPointsComponent,
                    },
                    {
                        path: IMPACT_PAGES.posts,
                        component: ImpactPostsComponent,
                    },
                ],
            },
            {
                path: IMPACT_PAGES.controlPoints + '/' + IMPACT_PAGES.regions + '/:id',
                component: ImpactRegionDetailComponent,
            },
            {
                path:
                    IMPACT_PAGES.controlPoints + '/' + IMPACT_PAGES.points + '/' + IMPACT_PAGES.add,
                component: ControlPointFormComponent,
            },
            {
                path:
                    IMPACT_PAGES.controlPoints +
                    '/' +
                    IMPACT_PAGES.points +
                    '/' +
                    IMPACT_PAGES.edit +
                    '/:id',
                component: ControlPointFormComponent,
            },
            {
                path: IMPACT_PAGES.configs + '/' + IMPACT_PAGES.edit + '/:id',
                component: ImpactEditRunComponent,
            },
        ],
    },
];
export const eventsRoutes: Routes = [
    {
        path: '',
        component: ImpactMainComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: IMPACT_PAGES.list,
            },
            {
                path: IMPACT_PAGES.list,
                component: ImpactEventListComponent,
            },
            {
                path: IMPACT_PAGES.list + '/:id',
                component: ImpactEventDetailComponent,
            },
        ],
    },
];
@NgModule({
    imports: [RouterModule.forChild(impactRoutes)],
    exports: [RouterModule],
})
export class ImpactRoutingModule {}
