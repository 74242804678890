import * as moment from 'moment-timezone';
import { DateRangeNetwork } from '@cityair/modules/network/service/api-models';
import type { BBox } from 'geojson';
export const INIT_NETWORK_DATE: DateRangeNetwork = {
    start: moment().startOf('day').subtract(14, 'days').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
};
export const DOMAIN_BBOX: { [key: string]: BBox } = {
    '213': [82.2975, 54.5, 83.6567625, 55.4036],
    '266': [83.18325, 54.5058, 83.5042, 54.72977],
};
export const DENSITY_RESPONSE_ISKITIM = {
    data: {
        network_area_living: 34.5,
        network_area_total: 29.9,
        recommendations: ['increase_posts_count', 'optimize_posts_positioning'],
        stations_avg_min_distance: 3.1,
        stations_count: 5,
        stations_coverage: 0.42,
    },
    meta: { type: 'network_density_analysis', group: '266' },
};
export const DEFAULT_POST_COLOR = '#b8bfcc';
export const LIST_PRIORITY_LIMIT = 7;
export const PRIORITY_VALUE = {
    PM25: 130,
    CO: 3000,
    NO2: 160,
};
function effectNetwork(count, total) {
    return Math.round((count / total) * 100);
}

export const EFFECT_NETWORK: {
    [key: string]: { [key: string]: { posts: number; value: number; count: number } };
} = {
    '213': {
        PM25: { posts: 5, value: effectNetwork(5, 11), count: 11 },
        CO: { posts: 4, value: effectNetwork(4, 11), count: 11 },
        NO2: { posts: 2, value: effectNetwork(2, 11), count: 11 },
    },
    '266': {
        PM25: { posts: 1, value: effectNetwork(1, 5), count: 5 },
        CO: { posts: 1, value: effectNetwork(1, 5), count: 5 },
        NO2: { posts: 0, value: effectNetwork(0, 5), count: 5 },
    },
};
export const LABEL_VALUE = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
];
export const DATA_MODELING = {
    PM25: [
        [3, 2, 3, 2, 2, 3, 3, 3, 4, 4, 4, 3, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [10, 5, 3, 2, 2, 2, 3, 4, 4, 5, 5, 4, 3, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [7, 10, 8, 4, 3, 2, 3, 4, 5, 6, 7, 6, 5, 2, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [5, 7, 10, 10, 8, 4, 3, 4, 4, 6, 9, 11, 9, 5, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [12, 6, 6, 10, 12, 13, 9, 7, 5, 5, 8, 13, 16, 12, 8, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [12, 17, 9, 7, 9, 13, 17, 16, 13, 9, 10, 15, 23, 23, 21, 17, 2, 1, 1, 0, 0, 0, 0, 0, 0],
        [5, 10, 18, 17, 10, 9, 13, 19, 27, 30, 22, 19, 24, 35, 46, 24, 15, 6, 3, 2, 1, 1, 0, 0, 0],
        [3, 6, 13, 21, 24, 16, 16, 19, 29, 43, 58, 60, 43, 45, 59, 34, 25, 11, 9, 6, 4, 2, 1, 1, 1],
        [
            5, 8, 11, 15, 20, 23, 20, 17, 24, 34, 57, 99, 149, 138, 116, 66, 28, 8, 5, 4, 5, 5, 4,
            2, 1,
        ],
        [
            6, 7, 8, 13, 16, 19, 28, 38, 36, 45, 51, 77, 124, 188, 199, 134, 73, 12, 7, 4, 2, 2, 2,
            1, 1,
        ],
        [
            3, 4, 6, 10, 15, 21, 21, 30, 46, 57, 74, 84, 128, 161, 152, 198, 237, 33, 19, 15, 7, 4,
            3, 1, 1,
        ],
        [
            3, 4, 6, 7, 8, 14, 17, 25, 36, 57, 113, 184, 217, 75, 66, 83, 160, 106, 51, 44, 35, 19,
            11, 5, 2,
        ],
        [
            4, 4, 5, 5, 5, 7, 14, 24, 27, 33, 49, 61, 165, 85, 46, 65, 78, 82, 44, 21, 30, 39, 31,
            15, 9,
        ],
        [
            7, 6, 6, 12, 13, 14, 19, 23, 18, 24, 26, 24, 65, 141, 101, 80, 63, 46, 55, 21, 11, 16,
            19, 22, 14,
        ],
        [
            10, 10, 12, 14, 13, 21, 17, 7, 5, 6, 6, 19, 42, 79, 138, 102, 83, 63, 37, 46, 19, 7, 8,
            10, 13,
        ],
        [
            8, 15, 34, 69, 68, 22, 7, 7, 9, 8, 13, 16, 26, 43, 71, 77, 55, 48, 32, 32, 29, 15, 7, 5,
            7,
        ],
        [
            12, 19, 24, 66, 39, 22, 9, 4, 7, 15, 14, 19, 19, 23, 51, 45, 41, 33, 34, 20, 24, 17, 8,
            6, 4,
        ],
        [
            7, 6, 18, 34, 18, 21, 11, 8, 6, 10, 11, 17, 17, 13, 27, 42, 41, 30, 24, 22, 22, 17, 10,
            5, 3,
        ],
        [
            2, 6, 14, 19, 11, 12, 12, 8, 10, 8, 8, 13, 11, 10, 12, 24, 26, 36, 23, 16, 17, 17, 15,
            6, 3,
        ],
        [3, 6, 8, 11, 8, 8, 10, 9, 7, 8, 10, 10, 7, 6, 7, 12, 12, 21, 28, 18, 13, 15, 11, 10, 4],
        [3, 4, 6, 8, 7, 6, 7, 9, 7, 6, 10, 10, 5, 4, 4, 6, 7, 12, 18, 20, 16, 11, 11, 10, 7],
    ],
    CO: [
        [123, 49, 57, 25, 27, 51, 66, 74, 87, 100, 98, 29, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
            275, 164, 90, 53, 38, 35, 57, 74, 91, 111, 125, 90, 23, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
        ],
        [
            179, 257, 251, 147, 76, 59, 55, 68, 105, 126, 162, 169, 74, 3, 0, 5, 0, 0, 0, 0, 0, 0,
            0, 0, 0,
        ],
        [
            97, 147, 242, 302, 269, 164, 103, 85, 80, 133, 182, 277, 199, 40, 2, 23, 0, 0, 0, 0, 0,
            0, 0, 0, 0,
        ],
        [
            272, 116, 123, 222, 306, 397, 326, 236, 144, 114, 176, 298, 438, 197, 62, 74, 0, 0, 0,
            0, 0, 0, 0, 0, 0,
        ],
        [
            255, 383, 200, 154, 209, 309, 427, 495, 442, 262, 200, 255, 576, 427, 310, 273, 11, 0,
            0, 0, 0, 0, 0, 0, 0,
        ],
        [
            102, 186, 370, 387, 231, 213, 304, 475, 717, 860, 679, 385, 416, 857, 917, 394, 101, 0,
            0, 0, 0, 0, 0, 0, 0,
        ],
        [
            64, 108, 242, 394, 496, 368, 358, 439, 725, 1198, 1597, 1773, 1059, 1462, 1942, 691,
            495, 167, 136, 95, 48, 14, 0, 0, 1,
        ],
        [
            131, 194, 248, 332, 426, 491, 428, 366, 542, 841, 1543, 2628, 4388, 4674, 4057, 1824,
            678, 99, 61, 76, 90, 96, 86, 48, 25,
        ],
        [
            122, 143, 174, 283, 369, 445, 701, 920, 969, 1312, 1488, 2087, 3702, 5723, 5939, 4029,
            2121, 544, 298, 122, 46, 31, 23, 10, 6,
        ],
        [
            42, 66, 110, 200, 338, 516, 557, 731, 1090, 1320, 1631, 1973, 3122, 4049, 3853, 5182,
            5632, 923, 549, 504, 307, 138, 95, 50, 29,
        ],
        [
            33, 66, 95, 117, 168, 337, 403, 499, 726, 1145, 2475, 3959, 4430, 1985, 1417, 2216,
            3799, 2466, 1202, 1033, 874, 548, 343, 154, 71,
        ],
        [
            29, 35, 46, 67, 117, 167, 260, 423, 465, 675, 1092, 1291, 3486, 2028, 857, 1553, 1921,
            1909, 993, 463, 665, 891, 767, 405, 248,
        ],
        [
            48, 57, 73, 130, 209, 254, 270, 297, 344, 543, 562, 524, 1480, 2953, 2027, 1829, 1488,
            1134, 1272, 469, 207, 316, 417, 521, 355,
        ],
        [
            40, 52, 79, 74, 82, 98, 112, 108, 111, 174, 124, 373, 1002, 1633, 2787, 2085, 1828,
            1450, 870, 1041, 411, 122, 150, 210, 296,
        ],
        [
            16, 25, 34, 38, 33, 15, 10, 21, 74, 114, 247, 337, 640, 866, 1437, 1594, 1197, 1102,
            754, 733, 640, 302, 127, 86, 134,
        ],
        [
            4, 4, 4, 2, 2, 3, 6, 31, 75, 179, 208, 392, 431, 443, 1022, 920, 910, 720, 777, 460,
            547, 377, 166, 101, 79,
        ],
        [
            0, 0, 0, 0, 0, 0, 11, 31, 83, 174, 192, 381, 345, 239, 536, 845, 863, 649, 531, 493,
            508, 386, 225, 91, 46,
        ],
        [
            0, 0, 0, 0, 0, 2, 17, 34, 114, 126, 156, 314, 212, 174, 242, 498, 513, 765, 494, 363,
            377, 391, 328, 132, 44,
        ],
        [
            0, 0, 0, 0, 0, 4, 16, 57, 89, 94, 190, 228, 145, 101, 130, 243, 246, 428, 586, 373, 282,
            339, 260, 223, 87,
        ],
        [
            0, 0, 0, 0, 1, 4, 31, 50, 77, 75, 172, 180, 81, 68, 83, 127, 145, 258, 374, 411, 332,
            235, 245, 218, 148,
        ],
    ],
    NO2: [
        [4, 2, 6, 8, 8, 8, 8, 6, 5, 5, 9, 15, 5, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [5, 4, 2, 3, 7, 10, 10, 11, 11, 9, 9, 12, 16, 7, 4, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [3, 4, 6, 4, 3, 4, 8, 13, 14, 16, 16, 14, 24, 19, 8, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [5, 4, 3, 5, 5, 4, 4, 6, 11, 16, 22, 25, 31, 40, 20, 14, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        [7, 6, 5, 4, 7, 10, 8, 8, 9, 10, 15, 29, 40, 48, 56, 26, 4, 0, 0, 0, 0, 0, 0, 0, 0],
        [5, 7, 8, 6, 6, 9, 12, 15, 15, 17, 25, 56, 70, 93, 117, 88, 19, 15, 6, 3, 1, 0, 0, 0, 0],
        [
            3, 5, 10, 11, 10, 12, 16, 20, 30, 42, 48, 66, 94, 125, 175, 127, 120, 72, 32, 17, 11, 8,
            2, 0, 0,
        ],
        [
            3, 5, 8, 12, 13, 14, 16, 20, 25, 34, 54, 86, 121, 67, 50, 98, 83, 81, 67, 48, 28, 18,
            14, 11, 7,
        ],
        [
            3, 5, 8, 13, 14, 15, 19, 23, 31, 39, 54, 119, 192, 91, 85, 79, 27, 48, 39, 28, 28, 25,
            18, 11, 6,
        ],
        [
            3, 4, 6, 9, 11, 12, 12, 17, 20, 30, 39, 63, 66, 68, 82, 56, 29, 21, 36, 24, 15, 9, 11,
            10, 9,
        ],
        [3, 5, 6, 8, 8, 10, 8, 14, 13, 21, 28, 53, 51, 55, 61, 31, 22, 13, 18, 27, 16, 9, 6, 3, 3],
        [4, 6, 5, 6, 4, 5, 5, 14, 17, 23, 37, 49, 89, 49, 45, 23, 19, 11, 10, 18, 23, 11, 6, 3, 1],
        [
            10, 8, 12, 10, 5, 3, 8, 16, 18, 13, 18, 17, 20, 29, 58, 33, 24, 17, 11, 10, 15, 17, 8,
            4, 2,
        ],
        [
            19, 15, 11, 22, 13, 6, 22, 32, 7, 7, 5, 4, 13, 23, 44, 35, 20, 15, 12, 11, 10, 14, 11,
            6, 3,
        ],
        [31, 29, 31, 39, 36, 63, 43, 7, 2, 5, 4, 6, 9, 17, 29, 37, 23, 14, 10, 9, 7, 10, 10, 6, 3],
        [
            26, 50, 120, 257, 264, 82, 26, 22, 26, 14, 8, 5, 6, 13, 14, 14, 18, 15, 9, 6, 6, 6, 6,
            5, 3,
        ],
        [
            42, 66, 86, 251, 149, 82, 34, 13, 17, 23, 16, 10, 9, 11, 13, 8, 9, 11, 9, 6, 3, 5, 5, 5,
            3,
        ],
        [25, 19, 64, 124, 67, 77, 40, 26, 9, 7, 8, 10, 8, 8, 9, 9, 9, 7, 8, 6, 4, 3, 4, 4, 3],
        [8, 20, 48, 65, 40, 45, 42, 23, 18, 7, 4, 5, 5, 5, 4, 5, 9, 8, 7, 5, 5, 2, 2, 3, 3],
        [9, 19, 26, 38, 29, 27, 33, 22, 13, 13, 8, 4, 3, 3, 2, 3, 3, 6, 7, 6, 5, 3, 2, 2, 2],
        [8, 14, 18, 27, 23, 20, 22, 24, 12, 9, 10, 6, 4, 2, 1, 2, 1, 4, 5, 5, 4, 5, 3, 2, 2],
    ],
};
