import {
    createFeature,
    createReducer,
    createActionGroup,
    props,
    on,
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';

import { DateRangeImpact, ExcessStatistic, SourceImpact } from '../service/api-model-impact';
import { INIT_IMPACT_REGION_DATE, INIT_IMPACT_SOURCE_DATE } from '../consts';

import { HttpParamsOptions } from '@angular/common/http';
import { RegionApi } from '@libs/common';
import { getMockDataStatRegion } from '@cityair/modules/impact/utils';
import {
    selectActiveSources,
    selectRegions,
    selectSpeciesList,
} from '@cityair/modules/impact/store/impact.feature';

export const IMPACT_STATISTIC_FEATURE_KEY = 'impactStat';

export const ImpactStatActions = createActionGroup({
    source: 'ImpactStat',
    events: {
        'Set Region': props<{ payload: RegionApi }>(),
        'Update Date Range Source': props<{ payload: DateRangeImpact }>(),
        'Update Date Range Region': props<{ payload: DateRangeImpact }>(),
        'Set Mock Data Region': props<{ payload: { sources: SourceImpact[]; mmts: string[] } }>(),
        'Init Region Mmt': props<{ payload: string }>(),
        'Update Region Mmt': props<{ payload: string }>(),
        'Update Source Mmt': props<{ payload: string }>(),
        'Set Excess Statistics': props<{ payload: ExcessStatistic }>(),
        'Loading Stat for Region': props<{ payload: boolean }>(),
    },
});
interface ImpactStatState {
    dateRangeSource: DateRangeImpact;
    dateRangeRegion: DateRangeImpact;
    currentRegion: RegionApi;
    regionMmt: string;
    sourceMmt: string;
    isLoadingStatRegion: boolean;
    excessStatistics: ExcessStatistic;
}
const initialState: ImpactStatState = {
    dateRangeRegion: INIT_IMPACT_REGION_DATE,
    dateRangeSource: INIT_IMPACT_SOURCE_DATE,
    currentRegion: null,
    regionMmt: null,
    sourceMmt: null,
    isLoadingStatRegion: false,
    excessStatistics: null,
};

const impactSelector = createFeatureSelector<ImpactStatState>(IMPACT_STATISTIC_FEATURE_KEY);

export const impactStatFeature = createFeature({
    name: IMPACT_STATISTIC_FEATURE_KEY,
    reducer: createReducer(
        initialState,
        on(ImpactStatActions.setRegion, (state, { payload }) => ({
            ...state,
            currentRegion: payload,
        })),
        on(ImpactStatActions.updateDateRangeSource, (state, { payload }) => ({
            ...state,
            dateRangeSource: payload,
        })),
        on(ImpactStatActions.updateDateRangeRegion, (state, { payload }) => ({
            ...state,
            dateRangeRegion: payload,
        })),
        on(ImpactStatActions.loadingStatForRegion, (state, { payload }) => ({
            ...state,
            isLoadingStatRegion: payload,
        })),
        on(ImpactStatActions.updateRegionMmt, (state, { payload }) => ({
            ...state,
            regionMmt: payload,
        })),
        on(ImpactStatActions.initRegionMmt, (state, { payload }) => ({
            ...state,
            regionMmt: payload,
        })),
        on(ImpactStatActions.updateSourceMmt, (state, { payload }) => ({
            ...state,
            sourceMmt: payload,
        })),
        on(ImpactStatActions.setExcessStatistics, (state, { payload }) => ({
            ...state,
            excessStatistics: payload,
        })),
        on(ImpactStatActions.setMockDataRegion, (state, { payload }) => {
            if (payload !== null) {
                const excessStatistics = getMockDataStatRegion(payload?.sources, payload?.mmts);
                return { ...state, excessStatistics, isLoadingStatRegion: false };
            }
            return { ...state, isLoadingStatRegion: false };
        })
    ),
    extraSelectors: ({ selectDateRangeRegion, selectRegionMmt, selectCurrentRegion }) => ({
        selectStatsRegionParams: createSelector(
            selectDateRangeRegion,
            selectRegionMmt,
            selectCurrentRegion,
            (dateRange, mmt, region) => {
                if (mmt && dateRange && region) {
                    const params: HttpParamsOptions = {
                        fromObject: {
                            start_date: dateRange.start,
                            finish_date: dateRange.end,
                            species_id: mmt,
                        },
                    };
                    return { id: region.id, params };
                }

                return null;
            }
        ),
    }),
});

export const {
    selectDateRangeSource,
    selectDateRangeRegion,
    selectCurrentRegion,
    selectRegionMmt,
    selectSourceMmt,
    selectIsLoadingStatRegion,
    selectExcessStatistics,
    selectStatsRegionParams,
} = impactStatFeature;
