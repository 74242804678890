import { MeasureScheme } from '@libs/common';
import { HISTOGRAM_AQI } from '../consts/histogram-aqi.const';
import { IZA } from '../consts/iza.conts';
import { PDK } from '../consts/pdk.const';
import { ROOM } from '../consts/room.const';
import {
    AQI,
    HUM,
    PM10,
    PM25,
    SO2,
    CH2O,
    CO,
    CO2,
    O3,
    NO,
    H2S,
    NH3,
    NO2,
    TEMP,
    PRES,
    WDA,
    WVA,
    SUM,
    TSP,
    CS137,
    WVVA,
    NOISELEVEL,
    T,
    SP,
    R2,
    WD,
    WV,
    WDA_V,
    WVA_V,
} from '../consts/substance.consts';
import { AqiType } from '../enums/aqi.type';
import { StndAskPopupTexts } from '../types/stnd-ask-popup-texts';
import { E, N, NE, NW, S, SE, SW, W } from '@libs/common';

export const ugM = 'μg/m<sup>3</sup>';
export const mgM = 'mg/m<sup>3</sup>';
export const shareOfMPC = 'MPC';

// used to group the histogram coefficients
export const MEASURES = {
    [AQI]: 'points',
    [AqiType.indoor]: 'points',
    [AqiType.cityair]: 'points',
    [AqiType.instant]: 'points',
    [AqiType.epa]: 'points',
    [AqiType.CAPI]: 'points',
    [AqiType.R]: 'points',
    [AqiType.EU]: 'points',
    [AqiType.aqiIn]: 'points',
    [AqiType.aqiPm]: 'points',
    [AqiType.aqiNN]: 'points',
    [HISTOGRAM_AQI]: '% of time',
    [HUM]: '%',
    [PM10]: ugM,
    [PM25]: ugM,
    [SO2]: ugM,
    [CH2O]: ugM,
    [CO]: ugM,
    [CO2]: 'ppm',
    [O3]: ugM,
    [NO]: ugM,
    [H2S]: ugM,
    [NH3]: ugM,
    [NO2]: ugM,
    [TEMP]: '°C',
    [PRES]: 'mm Hg',
    [WDA]: '°',
    [WVA]: 'm/s',
    [SUM]: ugM,
    [WVVA]: 'm/s',
    [NOISELEVEL]: 'dBA',
    [T]: '°C',
    [SP]: 'mm Hg',
    [WD]: '°',
    [WV]: 'm/s',
    [WDA_V]: '°',
    [WVA_V]: 'm/s',
    [R2]: '%',
};

export const MEASURES_USA = {
    ...MEASURES,
    [TEMP]: '°F',
};

export const MEASURES_MG = {
    [AQI]: 'points',
    [AqiType.indoor]: 'points',
    [AqiType.cityair]: 'points',
    [AqiType.instant]: 'points',
    [AqiType.epa]: 'points',
    [AqiType.CAPI]: 'points',
    [AqiType.R]: 'points',
    [AqiType.EU]: 'points',
    [AqiType.aqiIn]: 'points',
    [AqiType.aqiNN]: 'points',
    [AqiType.aqiPm]: 'points',
    [HISTOGRAM_AQI]: '% of time',
    [HUM]: '%',
    [PM10]: mgM,
    [PM25]: mgM,
    [SO2]: mgM,
    [CH2O]: mgM,
    [CO]: mgM,
    [CO2]: 'ppm',
    [O3]: mgM,
    [NO]: mgM,
    [H2S]: mgM,
    [NH3]: mgM,
    [NO2]: mgM,
    [TEMP]: '°C',
    [PRES]: 'mm Hg',
    [WDA]: '°',
    [WVA]: 'm/s',
    [SUM]: mgM,
    [WVVA]: 'm/s',
    [NOISELEVEL]: 'dBA',
    [T]: '°C',
    [SP]: 'mm Hg',
    [WD]: '°',
    [WV]: 'm/s',
    [WDA_V]: '°',
    [WVA_V]: 'm/s',
    [R2]: '%',
};

export const MEASURES_MPC = {
    ...MEASURES,
    [PM10]: shareOfMPC,
    [PM25]: shareOfMPC,
    [SO2]: shareOfMPC,
    [CH2O]: shareOfMPC,
    [CO]: shareOfMPC,
    [O3]: shareOfMPC,
    [NO]: shareOfMPC,
    [H2S]: shareOfMPC,
    [NH3]: shareOfMPC,
    [NO2]: shareOfMPC,
    [SUM]: shareOfMPC,
};

export const UNITS = {
    '°C': '°C',
    '°F': '°F',
    'mm Hg': 'mm Hg',
    '%': '%',
    'µg/m³': ugM,
    'mg/m³': mgM,
    ppm: 'ppm',
    mV: 'mV',
    '°': '°',
    'm/s': 'm/s',
    'mm/min': 'mm/min',
    dBA: 'dBA',
};
export const WIND_DIRECTION = {
    [N]: 'N',
    [NE]: 'NE',
    [E]: 'E',
    [SE]: 'SE',
    [S]: 'S',
    [SW]: 'SW',
    [W]: 'W',
    [NW]: 'NW',
};
export const MEASURES_SCHEME = {
    [MeasureScheme.default]: MEASURES,
    [MeasureScheme.usa_default]: MEASURES_USA,
    [MeasureScheme.c_mmhg_mg]: MEASURES_MG,
    [MeasureScheme.mpc]: MEASURES_MPC,
};

export const AQI_DECL = ['point<i style="visibility: hidden;">s</i>', 'points', 'points', 'points'];
export const AQI_DECL2 = ['point', 'points', 'points', 'points'];

export const NAMES = {
    [AQI]: 'AQI',
    [AqiType.indoor]: 'IAQI',
    [AqiType.cityair]: 'cityair',
    [AqiType.instant]: 'AQI',
    [AqiType.epa]: 'EPA',
    [AqiType.CAPI]: 'CAPI',
    [AqiType.R]: 'AQI-R',
    [AqiType.EU]: 'AQI-EU',
    [AqiType.aqiIn]: 'AQI',
    [AqiType.aqiNN]: 'AQI-NN',
    [AqiType.aqiPm]: 'AQI',
    [IZA]: 'API',
    [HISTOGRAM_AQI]: '',
    [HUM]: 'RH',
    [PM10]: 'PM10',
    [PM25]: 'PM2.5',
    [SO2]: 'SO<sub>2</sub>',
    [CO]: 'CO',
    [CO2]: 'CO<sub>2</sub>',
    [O3]: 'O<sub>3</sub>',
    [NO]: 'NO',
    [H2S]: 'H<sub>2</sub>S',
    [NH3]: 'NH<sub>3</sub>',
    [NO2]: 'NO<sub>2</sub>',
    [CH2O]: 'CH<sub>2</sub>O',
    [TEMP]: 'T',
    [PRES]: 'P',
    [PDK]: 'MPC',
    [SUM]: 'Sum',
    [TSP]: 'TSP',
    [WDA]: 'WD',
    [WVA]: 'HWV',
    [CS137]: 'CS<sup>137</sup>',
    [WVVA]: 'VWV',
    [NOISELEVEL]: 'Noise',
    [T]: 'T',
    [SP]: 'P',
    [WD]: 'WD',
    [WV]: 'WV',
    [WDA_V]: 'WD',
    [WVA_V]: 'WV',
    [R2]: 'RH',
};
export const MMT_LONG_NAMES = {
    [TEMP]: 'Temperature ',
    [HUM]: 'Humidity',
    [PRES]: 'Pressure',
    [WDA]: 'Wind Direction',
    [WVA]: 'Horizontal Wind Velocity',
    [WVVA]: 'Vertical Wind Velocity',
    [T]: 'Temperature ',
    [R2]: 'Humidity',
    [SP]: 'Pressure',
    [WD]: 'Wind Direction',
    [WV]: 'Horizontal Wind Velocity',
};

export const AQI_THREE_TEXTS = ['Safe', 'Satisfactory', 'Dangerous'];
export const AQI_THREE_TEXTS2 = [
    'Clean air',
    'Moderate air pollution',
    'High level of air pollution',
];

export const CAPI_GRADES = ['0 – 4', '5 – 6', '7 – 13', '> 14'];
export const CAPI_THREE_TEXTS = [
    'Низкое загрязнение',
    'Повышенное загрязнение',
    'Высокое загрязнение',
    'Очень высокое загрязнение',
];

export const TOOLTIPS = {
    ...NAMES,
    [AQI]: 'Air quality index - Air Quality Index',
    [PM25]: 'Particulate Matter - particles with a diameter of 2.5 µm or less',
    [PM10]: 'Particulate Matter - particles with a diameter of 10 µm or less',
    [CO2]: 'Carbon dioxide',
    [TEMP]: 'Temperature',
    [PRES]: 'Pressure',
    [HUM]: 'Humidity',
};

export const COMMON = {
    actions: 'Actions',
    today: 'today',
    yesterday: 'yesterday',
    error: 'Error',
    apply: 'Apply',
    save: 'Save',
    doNotSave: 'Don’t save',
    cancel: 'Cancel',
    close: 'Close',
    closeChart: 'Close chart',
    delete: 'Delete',
    gas: 'Gases',
    more: 'More',
    back: 'Back',
    placeholderDate1: 'Start date of the period',
    placeholderDate2: 'End date of the period',
    browserNotSupport: 'Your browser is not supported',
    byTheCity: 'by ',
    pm2: 'PM2.5',
    pm10: 'PM10',
    cities: 'Cities',
    city: 'City',
    location: 'Location',
    allWorld: 'Worldwide',
    allCities: 'All cities',
    airQuality: 'Air Quality.',
    pdkMr: '',
    pdkSs: '',
    logOut: 'Sign out',
    noGroupPermission: "You don't  have access to this monitoring network",
    now: 'Now',
};

export const TIME_NAMES = {
    month: 'Month',
    week: 'Week',
    days3: '72 hours',
    watches24: '24 hours',
    min5: '5 min',
    min20: '20 min',
    hour1: '1 hour',
    day1: '1 day',
};
export const REPORTS_TIME_NAMES = {
    source: '1 min',
    '5m': '5 min',
    '20m': '20 min',
    '1h': '1 hour',
    '1d': '1 day',
};
export const REPORTS_TIME_NAMES_TOOLTIPS = {
    source: 'Only available when one site is selected',
    '5m': '',
    '20m': '',
    '1h': '',
    '1d': '',
};
export const REPORTS_UNITS_NAMES = {
    default: ugM,
    c_mmhg_mg: mgM,
};
export const TIME_NAMES_TOOLTIP = {
    min5: 'Available for observation period less than 3 days',
    min20: 'Available for observation period less than 12 days',
    hour1: 'Available for observation period less than 36 days',
    day1: '',
};
export const STATION_BLOCK = {
    returnScale: 'Reset scale',
    dataOnPollutants: 'Air parameters',
    addToComparison: 'Compare',
    compare: 'Comparison mode',
    removeCompare: 'Remove from comparison',
    addCompare: 'Add to compare',
    type: {
        mo: 'CityAir Monitor',
        myMo: 'CityAir Monitor',
        station: 'CityAir Monitor',
        control_point: 'Calculation results',
        OpenAQ: 'OpenAQ',
        city: '',
    },
    noData: 'No data available for the selected time interval',
    noDataForMmt: 'There is no selected measurement data for the selected time interval',
    averageCity: 'Сity average',
    add: 'Add',
    select: 'Select',
    sourceTypeName: {
        48: 'Atmos Monitor',
        46: 'Magic Air Monitor',
    },
};

export const TEXT_DESCRIPTION = {
    infoBlock: ['Temperature', 'Humidity', 'Pressure'],
    airQualityTitle: 'Air Quality Index',
    airQuality: `The air quality is evaluated<br>
                according to the Air Quality Index:<br>
                from 1 (low risk) to 10 (very high risk)`,
    capiAirQuality: `Комплексный индекс загрязнения атмосферы`,
};

export const CHART_OPTIONS = {
    lang: {
        loading: 'Loading...',
        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        shortMonths: [
            'Jan',
            'Feb',
            'March',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ],
        exportButtonTitle: 'Export',
        contextButtonTitle: 'Export',
        printButtonTitle: 'Print',
        rangeSelectorFrom: 'From',
        rangeSelectorTo: 'to',
        rangeSelectorZoom: 'Period',
        downloadPNG: 'Download in PNG',
        downloadJPEG: 'Download in JPEG',
        downloadPDF: 'Download in PDF',
        downloadSVG: 'Download in SVG',
        printChart: 'Print the chart',
        fullscreen: 'Fullscreen',
        resetZoom: 'Reset scale',
        resetZoomTitle: 'Reset the chart scale to 100%',
    },
};

export const MARKET = {
    text: 'The public map is now available in the new app',
    airvoice: 'AirVoice',
    linkApple: 'https://apps.apple.com/app/airvoice/id1484315084',
    linkGoogle: 'https://play.google.com/store/apps/details?id=com.cityair.airvoice',
};

export const WEEKDAY = {
    mo: 'Mo',
    tu: 'Tu',
    we: 'We',
    th: 'Th',
    fr: 'Fr',
    sa: 'Sa',
    su: 'Su',
};

export const DOWNLOAD_POPUP = {
    title: 'Primary data',
    period: 'Select date range',
    all: 'All monitoring sites',
    all_devices: 'All Monitor',
    several: 'Selected monitoring sites',
    several_devices: 'Selected monitors',
    select: 'Select monitoring sites',
    select_devices: 'Select monitors',
    select_type: 'Select format',
    selected: 'Selected items: ',
    download: 'Download',
    dateRange: 'Data interval',
    unitsMeasurements: 'Units of measurement',
    error14day: 'Time interval for download may not exceed 14 days at once',

    filenameDataMO: 'AQM_data',
    filenameDataDevice: 'monitor_data',
    filenameDiagnostic: 'monitor_diagnostic_data',
    filenameReport: 'AQM_report_TZA4',
    error: 'Sorry, something went wrong. Please try again later or contact our customer service.',
};

export const GMT_VALUE = [
    [-12, '(GMT -12:00) Eniwetok, Kwajalein'],
    [-11, '(GMT -11:00) Midway Island, Samoa'],
    [-10, '(GMT -10:00) Hawaii'],
    [-9, '(GMT -9:00) Alaska'],
    [-8, '(GMT -8:00) Pacific Time (US & Canada)'],
    [-7, '(GMT -7:00) Mountain Time (US & Canada)'],
    [-6, '(GMT -6:00) Central Time (US & Canada), Mexico City'],
    [-5, '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima'],
    [-4, '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz'],
    [-3, '(GMT -3:00) Brazil, Buenos Aires, Georgetown'],
    [-2, '(GMT -2:00) Mid-Atlantic'],
    [-1, '(GMT -1:00 hour) Azores, Cape Verde Islands'],
    [0, '(GMT) Western Europe Time, London, Lisbon, Casablanca'],
    [1, '(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris'],
    [2, '(GMT +2:00) Kaliningrad, South Africa'],
    [3, '(GMT +3:00) Moscow, Saint Petersburg'],
    [4, '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi'],
    [5, '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent'],
    [5.5, '(GMT +5:30) Gurgaon, Harayana, India'],
    [6, '(GMT +6:00) Almaty, Dhaka, Colombo'],
    [7, '(GMT +7:00) Novosibirsk, Krasnoyarsk, Kemerovo'],
    [8, '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong'],
    [9, '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk'],
    [10, '(GMT +10:00) Eastern Australia, Guam, Vladivostok'],
    [11, '(GMT +11:00) Magadan, Solomon Islands, New Caledonia'],
    [12, '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka'],
];

export const PROFILE = {
    network: 'Monitoring Network',
    post: {
        zero: 'monitoring sites',
        one: 'monitoring site',
        two: 'monitoring sites',
        few: 'monitoring sites',
        many: 'monitoring sites',
        other: 'monitoring sites',
    },
    support: 'Support',
    phone: ['+44 (0)20 8089 9027', '+44&nbsp;(0)20&nbsp;8089&nbsp;9027'],
    email: 'info@cityair.io',
    privicyPolicy: { text: 'Privacy Policy', url: 'https://cityair.global/privacy-policy/' },
    copyright: 'CityAir. All rights reserved',
    fondText: (isMobile) =>
        isMobile
            ? 'The research is carried out<br/> by CityAir LLC with grant support <br/> from the Skolkovo Foundation'
            : 'The research is carried out by CityAir LLC<br/> with grant support from the Skolkovo Foundation',
};

export const ADMIN_PANEL = {
    listDevices: 'Service',
    listOM: 'Monitoring sites',
    dashboard: 'Dashboard',
    OM: 'Sites',
    eventList: 'Event List.<br>Page under construction',
    notlistDevices: '.<br>Monitor list is empty',
    notlistOM: '.<br>Monitoring sites list is empty',
    notlistUsers: '.<br>User list is empty',
    noteventList: '',
    notcityCard: '',
    notcoefficientView: '',
    coefficientView: 'Calibration factors',
    listUsers: 'Users',
    configPanel: 'Settings',
    cityCard: 'Analytics',
    plumes: 'CityAir.Plume',
    impact: 'Impact',
    indoor: 'Indoor',
    reports: 'Reports',
    forecast: 'Forecast',
    notindoor: '',
    buttonAddMO: 'Add new monitoring site',
    buttonAddUser: 'Add new user',
    notifications: 'Notification center',
    notnotifications: '',
    postsAndDevices: 'Networks',
    back: 'Back',
    analysis: 'Network analysis',
    analysisEvents: 'Events analysis',
    network: 'Network efficiency',
    events: 'Events analysis',
};

export const ANALYTICS_PAGE = {
    labelCitySelector: 'Network',
    searchPlaceholder: 'Search by location',
};

export const SERVICE_PAGE = {
    devices: 'Monitors',
};

export const LIST_OM = {
    objects: 'Monitoring Network',
    objectsView: 'Monitoring site',
    filterForName: 'Filter by name',
    notPublic: 'Not Publish',
    public: 'Publish',
    offline: 'Offline',
    online: 'Online',
    clearFilter: 'Clear All Filters',
    clearSearch: 'Clear search input',
    countStation: 'Count',
    owner: 'Owner',
    operator: 'Operator',
    observer: 'Observer',
    observer_at: 'AQMS',
    selectOm: 'Click to assign Monitoring sites <br> to this user',
    changesSaved: 'Changes saved successfully',
    checkAll: 'Select all',
    uncheckAll: 'Uncheck all',
    noRole: 'No access',
    worked: 'Nominal operating mode',
    notWorked: 'Offline',
    batWorked: 'On battery',
    batLow: 'Low battery',
    noData: 'No data',
    noDevices: 'No connected monitors',
    noPosts: 'No Monitoring sites available',
    noFound: 'No Monitoring sites found',
    noFoundDescription: 'Your query returned no results, try searching with different keywords',
};

export const POPUP_DELETE: StndAskPopupTexts = {
    title: 'Delete monitoring site',
    body: 'Do you really want to delete this monitoring site?',
    cancel: COMMON.cancel,
    cancelNew: COMMON.cancel,
    accept: COMMON.delete,
};

export const POPUP_DELETE_USER: StndAskPopupTexts = {
    title: 'Delete user',
    body: 'Do you really want to delete this user?',
    cancel: COMMON.cancel,
    accept: COMMON.delete,
};

export const POPUP_THREE_QUESTIONS: StndAskPopupTexts = {
    title: 'You have unsaved changes',
    body: 'Вы действительно хотите покинуть окно редактирования? Внесенные изменения не сохранятся',
    accept: 'Save the settings',
    cancel: 'Continue editing',
    extraAction: COMMON.doNotSave,
};

export const EDIT_STATION = {
    accommodationObjects: 'Monitoring sites',
    edit: 'Edit',
    delete: COMMON.delete,
    editing: 'Edit Station Properties',
    name: 'Name',
    public: 'Publish',
    publicName: 'Display Map',
    coordinates: 'Coordinates',
    description: 'Description',
    timezone: 'Time Zone',
    saveData: 'Record Data',
    downloadXLSData: 'Download Data',
    downloadReport: 'Download Report',
    documentation: 'Documentation',
    guideOperationName: 'Manual.pdf',
    guideOperationLink:
        'https://cdn.mycityair.ru/docs/en/CityAir_User_Manual_Monitoring_System.pdf',
    viewSite: 'Read Online',
    stationScheme: 'Station Design.pdf',
    cancel: COMMON.cancel,
    save: COMMON.save,
    technicalInfo: 'Technical Specifications',
    model: 'Model',
    versionH: 'Hardware Version',
    versionS: 'Software Version',
    startTimeStation: 'Station Start Time',
    lastTimeStation: 'Last Packet Time',
    scheduledMaintenance: 'Next Service Date',
    linkSpecification: 'https://cdn.mycityair.ru/docs/Station Design.pdf',
    textareaPlaceholderAdmin: 'Description. Press “Edit” to add a description.',
    textareaPlaceholder: 'Description.',
    openChart: 'Show plot',
    error: 'Could not complete the update. Please contact support',
    listDevices: 'Monitor',
    partOf: 'Is part of the following monitoring sites',
};

export const LIST_DEVICES = {
    selectDevice: 'Select monitor',
    selectDevices: 'Select monitors',
    info: 'Information',
    name: 'Name',
    lat: 'Latitude',
    lng: 'Longitude',
    timezone: 'Timezone',
    location: 'Locality',
    listDevices: 'Monitor List',
    startDate: 'Install date',
    service: 'Service Date',
    descriptionSection: `This tab shows the list of monitoring equipment available<br>to you: CityAir monitoring sites, trace gas measurement<br>extensions G1/G2, and other equipment`,
    connected: 'Monitoring sites',
    notConnected: 'Station is not attached to a monitoring station',
    loadPrimaryData: 'Raw data download',
    loadReport: 'Report download',
    loadLog: 'Health log',
    paramDeviceWork: 'Monitor Parameters',
    interval: 'Sampling interval',
    saveData: 'Recording status',
    savingData: 'Recording measurements',
    off: 'off',
    on: 'on',
    min: ' min',
    serialNumber: 'Serial number',
    type: 'Type',
    online: 'online',
    offline: 'offline',
    condition: 'Condition',
    relatedDevices: 'Related monitors',
    since: 'since',
    noDevices: 'No monitors available',
    noFound: 'No monitors found',
    equipmentNotAttached: `Equipment&nbsp;is&nbsp;not&nbsp;assigned&nbsp;to&nbsp;object`,
    deviceNoPost: `Equipment&nbsp;is&nbsp;not&nbsp;assigned&nbsp;to&nbsp;object`,
    noConnectedDevices: `No&nbsp;connected&nbsp;monitors`,
};

export const USER_ACCESS_TYPE = ['No access', 'Administrator', 'Operator', 'Observer', 'Service'];

export const LIST_USERS = {
    listUsers: 'Users',
    roleUser: 'User roles',
    deleteUser: 'Deleting a user',
    deleteUserAction: 'Delete from group',
    deleteUserTitle: (login) => `Are you sure you want to delete user ${login}?`,
    deleteUserDecs: (login) => `${login} will no longer have access to your CityAir account`,
    addUsers: 'Add user',
    editUsers: 'Edit User',
    login: 'Login',
    userEmail: 'Email',
    loginExistError: 'User with this login already exists',
    mail: 'e-mail',
    emailInGroup: 'This email is used in the current monitoring network',
    newUserDecs: (email) => `A login invitation will be sent to <b>${email}</b>`,
    minLength: (min) => `Minimum is {min}`,
    required: 'Required value',
    noPostsMsg: 'There is no monitoring sites',
    incorrectEmail: 'Incorrect email',
    invalidLogin: 'Incorrect login',
    access: 'Access level',
    accessLevel: 'Access level',
    lastLoginTableHeader: 'Last entrance',
    user: 'User',
    add: 'Add',
    create: 'Create',
    operator: `Operators can see the data and manage monitoring sites`,
    observer: `Observers can only see data from the monitoring sites available to them`,
    noSelect: 'Not selected',
    accessSelect: 'Access level',
    whenSaving: 'Changes saved',
    whenAdd: 'User added',
    whenCreate: 'Account activation instructions will be sent to specified email address',
    createAccount: (email) => `The invitation was sent to ${email}`,
    noUsers: 'No users yet',
    actionSendEmailPassword: 'Send an email to reset password',
    you: 'You',
    accessPopUp: {
        title: 'Differentiation of user access rights',
        postDetails: 'View list',
        fullAccess: 'full access',
        closeAccess: 'access denied',
        partialAccess: 'partial access',
        moduleNames: {
            users: 'System users',
        },
    },
};

export const MODELS = {
    accommodationObjects: 'Monitoring sites',
    simulation: 'Simulation',
    info: `Attention: mathematical simulation works in experimental mode`,
    info2: `This means that now we are scrupulously comparing the results <br> of the mathematical simulation with the data obtained from CityAir stations in this region. <br>
        A bit later, the accuracy of the calculations will become close to the real air pollution indexes`,
    noData: 'No mathematical simulation for this area at the moment',
    title: 'Mathematical simulation results',
    overlay: 'Heatmap',
    contours: 'Contour',
    off: 'Off',
    on: 'On',
    border: 'Simulation area border',
};

export const CITY_CARD = {
    intro: [
        'From the creators of AirVoice',
        'We are happy to share knowledge about air quality in different cities of the world',
        'This map is provided with data from the CityAir global platform',
        'Monitoring networks',
    ],
    backBtnCityScreen: 'Back to the list',
    backBtn: ['Back to the list of the cities', 'Back to the World Map'],
    openCity: 'Look into the city',
    goOver: 'Look into',
    goOverCity: 'Zoom into the city',
    close: 'close',
    tooltip: [
        'Air quality index ranges from 1 (very clean air) <br> to 10 (extremely polluted air)',
        'AQI Distribution',
        'Average AQI value within 24 hrs (entire observations history)',
        'Average AQI value by days of the week (entire observations history)',
    ],
    since2: ['from ', ' to date'],
    nowInCity: 'Now in the city',
    tabs: ['Monitoring sites', 'City profile'],
    sorting: ['Cities', 'Sites'],
    cityProfile: 'Air quality profile',
    seasons: ['Year', 'Winter', 'Spring', 'Summer', 'Autumn'],
    charts: [
        'AQI statistics for the entire period of observations',
        'Average air quality by hour of day',
        'Average air quality by day of week',
    ],
    noData: 'No data available for the selected time interval',
    dataSources: 'Data sources',
    noDataSources: 'No data sources',
    noConnectedDevices: 'No connected monitors',
};

export const COEFFICIENT_VIEW = {
    title: 'Calibration factors',
    content: [
        `The dust calibration of the CityAir analyzer is based on the results of collocated measurements used to  determine two factors, PCF (Photometric Calibration Factor) and SCF (Size Calibration Factor). It is recommended to use gravimetric measurements with selective impactors or an  equivalent method as a reference.`,
        `The calibration  coefficients are determined in such a manner that the following relationships hold true:`,
        `PM2.5<sup>Ref</sup> = PCF ⋅ PM2.5<sup>CityAir</sup>`,
        `PM10<sup>Ref</sup> = SCF<sup>3</sup> ⋅ PM10<sup>CityAir</sup> + (PCF - SCF<sup>3</sup> ) ⋅ PM2.5<sup>CityAir</sup>`,
        `where  PM<sup>Ref</sup> are the results of determining the concentration of suspended  particles by the gravimetric method with selective impactors for PM2.5 and PM10 respectively and PM<sup>CityAir</sup> are the results from the CityAir analyzer for the same period of time.`,
    ],
    current: 'Current PCF:',
    new: ['Set a new factor:', 'PCF', 'Enter value'],
    scf: 'Current SCF<sup>3</sup>:',
    scfComment:
        'SCF can only be adjusted when synchronous gravimetric measurements of both PM2.5 and PM10 are available.',
    description:
        'The calibration factors apply  only to the data accessible via the monitoring sites. The primary data received from the monitoring monitors remains unaltered. You can download the primary data from your user cabinet or via the API.',
    popup: ['You are about to change the calibration factors', 'New PCF:'],
    errorPcf: 'To set PCF outside the common calibration range, please contact CityAir Service',
};

export const QUALITY_CURRENT = [
    [
        'Breathe deeply, the air is&nbsp;clear',
        'Throw open the windows!',
        'Good conditions for outdoor sports!',
    ],

    ['Not bad. But be&nbsp;cautious!', 'You may take a&nbsp;walk. But not a&nbsp;long one'],
    [
        'Danger! Air is&nbsp;dirty!',
        'Be&nbsp;careful, to&nbsp;stay outside may be&nbsp;unsafe',
        'We&nbsp;do&nbsp;not recommend going out',
        'The darkness that came from the Mediterranean Sea covered the city hated by&nbsp;the procurator&hellip;',
        'Can&rsquo;t lean on&nbsp;this air yet. Won&rsquo;t see a&nbsp;pint in&nbsp;your hand though',
    ],
];

export const QUALITY_FORECAST = {
    'now<last': [['Gonna be better'], ['Gonna be better'], ['Gonna be better']],
    'now=last': [['Gonna be the same'], ['Gonna be the same'], ['Gonna be the same']],
    'now>last': [['Gonna be worse'], ['Gonna be worse'], ['Gonna be worse']],
};

export const LOGIN_WINDOWS = {
    welcome: `Welcome to&nbsp;CityAir`,
    toolsFor: 'Tools for configuring your monitoring network',
    enterNewPass: 'Enter new password below',
    newPass: 'New password',
    confNewPass: 'Confirm new password',
    wrongNewPass: 'Password contains characters that cannot be used',
    passwordsDontMatch: "These passwords don't match",
    warningNewPass: `<strong>Hint</strong>: the password should be&nbsp;at&nbsp;least seven characters long. To&nbsp;make it&nbsp;stronger, use upper and lower case letters, numbers, and symbols.`,
    resetPass: 'Reset password',
    techSup: 'Tech support',
    passLevel: ['', 'too weak', 'medium', 'medium', 'strong', 'strong'],
    wrong: 'Wrong login or password',
    wrongMail: 'Wrong email',
    wrongCode: 'Wrong verification code',
    cityscreenSignIn: 'Sign in to CityAir',
    login: 'Login',
    logout: 'Logout',
    password: 'Password',
    forgot: 'Forgot password?',
    sign: 'Sign in',
    forgotYourPass: 'Forgot Your Password?',
    pleaseConfirm: `Please, confirm your email address below and we&nbsp;will send you a verification code`,
    emailAddress: 'Email address',
    sendReset: 'Next',
    backToSign: 'Back to Sign In',
    language: 'Language',
    en: 'English',
    ru: 'Russian',
    passUpd: 'Password Updated',
    yourPassHasBeen: `Your password has been changed successfully. Use your new password to&nbsp;sign&nbsp;in`,
    check: 'Check Your Email',
    pleaseChk: `Please, check the email for instructions to&nbsp;reset your password`,
    info: `+44 (0)20 8089 9027<br>info@cityair.io`,
    licenseStr1:
        'License of&nbsp;Federal Service for Hydrometeorology and Environmental Monitoring of&nbsp;Russia',
    licenseStr2: '№1692822 Р/2019/3773/100/Л as of 26.04.2019',
    licenseStr3: `©${new Date().getFullYear()} CityAir. All rights reserved`,
    resetPassTitle: 'Reset Password',
    weHaveSent: `We&nbsp;have sent you a&nbsp;verification code, please check your email address`,
    verCode: 'Verification code',
    next: 'Next',

    mobileIsNotAvailable: 'Mobile version is not available',
    suggestionForMobileClients: 'Please use CityAir on your laptop or desktop computer',
    suggestionForMobileClientsNoBranding: 'Please switch to your laptop or desktop computer',

    toolsFor_sakhalin: 'Ministry of Ecology of Sakhalin Region',
    clientSignIn: 'Sign in to your account',

    errorLink: 'You already have a CityAir account. Enter or recover your username and password',
};

export const REQUEST_ERRORS = {
    unrecognizedError: 'Unrecognized error',
    tryAgainLater: 'Please try again later',
    clientOrNetworkError: 'A client-side or network error occurred',
};

export const LOGIN_NORNICKEL = {
    contactSupport: 'Support',
    supportTel: '8 (800) 600-78-08',
    supportAvailable: 'Mon-Fri 9:00 a.m. to 6:00 p.m. (MSK)',
    contactFeedback: 'Leave feedback at',
    feedbackEmail: 'feedback@mail.city.online',
    contactHelp: 'Help',
    helpEmail: 'help@mail.city.online',
    copyright: `© 2008-${new Date().getFullYear()} Norilsk Nickel`,
};

export const NOTIFICATIONS = {
    allMonitoringPostsSelected: 'All sites are selected',
    accommodationObjects: 'Monitoring sites',
    back: 'Back',
    settingNotification: 'Manage notifications',
    eventList: 'Event feed',
    eventsNotFound: 'Events not found',
    eventsNotFoundHint: 'Try to use other key words or clear<br /> currently applied filters',
    all: 'All',
    measures: 'Measurements',
    service: 'Service',
    forecasts: 'Forecast',
    plumes: 'Plumes',
    earlier: 'Earlier events',
    collapse: 'collapse',
    searchResultEmpty: 'Matches not found',
    subscribeSuggestion: 'Configure notifications',
    eventsHelpMessageMeasurements:
        'Set up alerts for exceeding concentration limits to keep up with important events',
    eventsHelpMessageService: 'Set up service alerts to keep up with the important events',
    addSubscription: 'Add a notification',
    activeSubscriptions: 'Active notifications',
    addSubscriptionHint: 'Click “Add a notifications” to set up a new notification',
    measuresDescription1:
        'Get notifications when concentrations exceed the specified level at selected monitoring sites',
    measuresDescription2: 'Press “Save” if supplied default settings below are satisfactory',
    measuresDescription3: 'If you want to change the suggested notification settings, press “Edit”',
    serviceDescription1:
        'Receive notifications about hardware failures and other service information',
    serviceDescription2: 'Press “Save” if you like default settings',
    serviceDescription3: 'If you want to change default notification settings, press “Edit”',
    serviceHelpText:
        'If you want to unsubscribe from notifications, remove your email from the notification settings',
    emailListInputLabel: 'Email (you can enter several emails separated by commas)',
    noEventsAvailable: 'No events yet',
    moNotFoundOrDeleted: 'Site not found or deleted',
    subscriptionsPdkTooltip: {
        title: 'ПДКмр — максимальная разовая предельно&nbsp;допустимая концентрация',
        description: 'В соответствии с РД 52.04.667-2005',
        text: [
            'от 0 до 1 ПДК — низкое загрязнение',
            'от 2 до 4 ПДК — повышенное загрязнение',
            'от 5 до 10 ПДК — высокое загрязнение',
            'более 10 ПДК — очень высокое',
        ],
    },

    allPostMeasure: 'All sites. Measurements',
    allPost: 'all sites',
    allStation: 'all monitors',
    parameterForNotif: 'Notification values',
    levelExcess: 'Exceedance level',
    recipients: 'Notification recipients',
    edit: 'Edit',
    save: COMMON.save,
    cancel: COMMON.cancel,
    deleteSubscription: 'Delete notifications',
    addToEventFeed: 'Show in event feed',
    removeFromEventFeed: 'Remove from event feed',
    newSubscription: 'New notification',
    editSubscription: 'Edit notifications',
    activateSubscription: 'Activate notifications',
    deactivateSubscription: 'Deactivate notifications',
    titleSubscription: 'Notification name',
    substances: 'Values',
    selectPosts: 'Select sites from the list or on the map',
    selectPostsSmall: 'Select sites',
    selectAll: 'Select all',
    add: 'Add',
    controlledSubstances: 'Parameters to be monitored',
    excessLevel:
        'The level of exceedance of the maximum allowable concentration at which notifications will be sent',
    advancedSetup: 'Advanced setup',
    getToBasicSetup: 'Return to the basic setup',
    moreThan: 'more than',
    showMore: 'more',
    selected: {
        zero: 'selected',
        one: 'selected',
        two: 'selected',
        few: 'selected',
        many: 'selected',
        other: 'selected',
    },
    selectedStation: {
        zero: 'selected',
        one: 'selected',
        two: 'selected',
        few: 'selected',
        many: 'selected',
        other: 'selected',
    },
    noneSelected: 'Sites are not selected',
    post: {
        zero: 'sites',
        one: 'site',
        two: 'sites',
        few: 'sites',
        many: 'sites',
        other: 'sites',
    },
    station: {
        zero: 'monitors',
        one: 'monitor',
        two: 'monitors',
        few: 'monitors',
        many: 'monitors',
        other: 'monitors',
    },
    setManually: 'Set manually (μg/m3)',
    notifyPeriodically: 'Notify about each measurement interval (20 min)',
    notifyEvent: 'Notify only about the start and end of the event',
    Slack: 'Slack',
    SMS: 'SMS',
    Email: 'Email',
    specifyFew: 'Specify separating by comma',
    dailyReport: 'Daily reports',
    whenSaving: '* Saving this will trigger sending of the text message',
    emailsNotSpecified: 'not specified',
    searchMoPlaceholderText: 'Search by monitoring site',
    stationSelectionPrompt: 'Select monitors',
    postsFilterPlaceholder: 'Find sites by name',
    serviceNotifications: 'Service notifications',
    notificationsFromEquipment: 'Notification settings',
    lackOfData: 'Absence of data for more than',
    lackOfPower: 'Outage for more than',
    verificationExpires: 'Verification expires in',
    timeIntervalMinutes: {
        zero: 'minutes',
        one: 'minute',
        two: 'minutes',
        few: 'minutes',
        many: 'minutes',
        other: 'minutes',
    },
    timeIntervalHours: {
        zero: 'hours',
        one: 'hour',
        two: 'hours',
        few: 'hours',
        many: 'hours',
        other: 'hours',
    },
    moreDays: {
        zero: 'days',
        one: 'day',
        two: 'days',
        few: 'days',
        many: 'days',
        other: 'days',
    },
    timeIntervalDays: {
        zero: 'days',
        one: 'day',
        two: 'days',
        few: 'days',
        many: 'days',
        other: 'days',
    },
    timeIntervalMonths: {
        zero: 'months',
        one: 'month',
        two: 'months',
        few: 'months',
        many: 'months',
        other: 'months',
    },
    timeIntervalYears: {
        zero: 'years',
        one: 'year',
        two: 'years',
        few: 'years',
        many: 'years',
        other: 'years',
    },
    locationSelection: 'Locality selection',
    point: 'Point',
    region: 'Region',

    statusActive: 'Active',
    statusDeactivated: 'Deactivated',
    statusActive2: 'Active',
    statusNotActive: 'Deactivated',
    statusDeactivated2: 'Остановлено',
    excessLevelUnits: 'MPCsc',
    excessLevelReport: 'Excess level of MPCsc',
    qmax: 'q<sub>М</sub>',

    errMsgTitleRequired: 'Title must be filled out',
    errMsgValueMustBeGreaterThanZero: 'Value must be greater than zero',
    errMsgValueMustBeIntegerNumber: 'Integer value must be specified',
    errMsgIncorrectEmails: 'Incorrect emails list format',
    errMsgMissingEmail: 'At least one email address must be specified',
    errMsgPost: 'At least one sites must be specified',
    error: 'Error',

    tooltipEventIcon: {
        measurements: 'Measurement notifications',
        service: 'Service notifications',
    },

    tooltipEventTitle: 'Set notification',

    tooltipSettingsHintValues:
        'You can set the absolute maximum permissible concentration (MPC) for the selected parameters',

    tooltipSettingsHintDailyReport:
        'All information about excess concentrations<br> in the daily report (detail: 20 min)',
    tooltipDeviceDailyReport: 'Daily report on monitor status updates',

    tooltipDurationLabel: ['Continue', 'Ended'],

    duration: 'duration',

    popupDelete: {
        title: 'You are to delete the notification. Please, confirm',
        body: 'When you delete the notification, the entire history of excess concentrations will be cleared. Before that you can save it to your monitor (see notification settings)',
        cancel: COMMON.cancel,
        accept: COMMON.delete,
    },

    popupDownloadFeetHistory: {
        title: 'Download history',
        body: 'Select the period of history to download',
        cancel: COMMON.cancel,
        accept: 'Download',
        period: 'Specify the period',
    },

    durationDescription: {
        begin: 'Began',
        continue: 'Continues',
        expect: 'Expecting',
    },

    notificationAlertLabel: {
        pdk: { None: '', Begin: 'Excess', Continue: 'Excess', End: 'End of exceedance' },
        nodata: {
            None: '',
            Begin: 'No data',
            Continue: 'No data',
            End: 'Data transmission restored',
        },
        nopower: { None: '', Begin: 'No power', Continue: 'No power', End: 'Power restored' },
    },

    alertTag: {
        endOfExcess: 'Окончание превышения',
        excess: 'Excess',
        thereIsNoData: 'No data',
        dataTransferRestored: 'Передача данных воcстановлена',
        noPowerSupply: 'Нет питания',
        powerRestored: 'Питание восстановлено',
        online: 'Online',
        offline: 'Offline',
        onBattery: 'On battery',
        onNetworks: 'Connected',
    },

    statusTexts: {
        pdk: ['Concentration exceeded', 'The concentration of the substance are back to normal'],
        nodata: ['No data available for more than', 'Data transmission is restored'],
        nopower: ['No power supply for more than ', 'Power supply has been restored'],
    },

    showFirstEvent: 'show the beginning',
    eventsFilterPrompt: 'Notification name, monitor, station or parameter',
    analysisEvent: {
        buttonText: 'Event Analysis',
    },
};

export const CONFIG = {
    title: 'Settings',
    coeff: 'Calibration factors',
    token: 'API access keys',
    history: 'Activity history',
    notifications: 'Notifications',
    settings: 'User settings',
    users: 'Users',
    searchToken: 'Search for API key',
    settingsGroup: 'Group settings',
    settingGroupTitles: {
        map: 'Map settings',
        coef: 'Correction factors',
        startModule: 'Start module',
        defaultPost: 'Diptych post selection',
    },
    mapSettings: {
        centerZoom: {
            title: 'Default map position',
            info: 'Defines the initial position of the map when the module is first loaded or updated',
            description:
                'Move the map to the desired position. Adjust the zoom level using the mouse wheel.',
            labelZoom: 'Zoom',
            labelLng: 'Center (latitude)',
            labelLat: 'Center (longitude)',
        },
        bounds: {
            title: 'Map boundaries',
            info: 'Defines the visible area of the map.',
            description: 'Map boundaries are not set',
            setBoundsTitle: 'Edit',
            newBoundsTitle: 'Set',
            deleteBtn: 'Delete new boundary map',
            infoTooltip: 'To change the boundaries, drag the edges of the rectangle',
            notExist:
                'Draw boundaries on the map by clicking on the map and selecting the desired area with the mouse. To adjust the boundaries, drag the edges of the rectangle.',
        },
        errorCenter:
            'The center of the map does not fall within the new map boundaries. Please correct the map boundaries or change the center.',
        errorPosts: 'Not all monitoring posts fall within the new map scope.',
    },
    startModuleSetting: {
        info: 'Automatically opens when you go to the site',
        label: 'Start module',
        noSelect: 'Not installed',
    },
    noFoundToken: 'no data',
    tokenTableHeader: {
        name: 'Name',
        status: 'Status',
        key: 'Token',
        lastDate: 'Last use date',
        createDate: 'Create date',
        count: 'Number of requests',
    },
    coeffTooltip: `Local calibration factor changes only the data, which forms<br>
 the graphs from the monitoring sites. Primary data,  received <br>
 from the monitors remains unchanged and is available<br>
  for downloading in your personal account and through the API`,

    tokenTooltip: `Unique identifiers (tokens) are designed to facilitate <br>
    interaction with CityAir platform servers <br>
    without using the Web interface, i.e., via API.`,

    historyTooltip: `The activity history displays information about the history<br>
    of logins to your personal account. If you suspect that someone<br>
    has gained unauthorized access to your account, we recommend<br>
    that you change your password and contact Client Support.`,

    measureSchemeDescriptionAWS: `Currently, the selection of displaying temperature in Fahrenheit is only available
    when <b>μg/m3</b> is selected. If <b>mg/m3</b> is selected, the temperature will be displayed in Celsius. We are working on fixing this functionality.`,

    saveDescription: '<b>Attention:</b> after saving the settings, the page will reload',

    units: 'Units of measurement',
    timezoneTitle: 'Time zone',
    langTitle: 'System language',
    autoTimezoneLabel: 'Automatic (browser time zone)',
    searchPlaceholderTzText: 'Search timezone',
    browserTokenItems: ['Last use:', 'Date of creation:', 'Number of requests:'],
    closeToken: 'End session',
    activeToken: 'Current session',
    closedToken: 'Completed session',
    closeAll: 'Close all active sessions',

    active: 'active',

    rename: 'Rename',
    close: 'Delete',
    countTokens: 'Active keys',
    create: 'Create key',

    titlePopupCreate: 'Create key',
    titlePopupClose: 'Are you sure you want to permanently delete this API key?',
    titlePopupRemove: (name) =>
        `Are you sure you want to permanently delete this API key "${name}"?`,
    titlePopupRename: 'Rename the key',

    applyCreate: 'Create',
    applyClose: 'Delete',
    applyRename: 'Rename',

    closeDescription: 'Key: ',
    createDescription: 'Enter an API key name',
    createKeyTitle: 'Adding a key',
    createKeyLabel: 'Key name',
    copySuccess: 'API key copied',
    copy: 'Copy key',

    language: 'Language',
    timeZone: 'Time Zone',
};

export const PLUMES = {
    runResults: 'Model results',
    runConfiguration: 'Model configurations',
    controlPoints: 'Sampling points',
    resultsAt: 'at',
    addControlPoint: 'Add sampling point',
    addSource: 'Add source',
    nowOnMap: 'Now on the map',
    noRunSelected: 'Please select a run',
    forecastOn: 'Forecast for the period',
    createControlPointTitle: 'New sampling point',
    editControlPointTitle: 'Edit sampling point*',
    editRunConfigurationTitle: 'Edit model configuration',
    latitude: 'Latitude',
    longitude: 'Longitude',
    title: 'Name',
    value: 'Value',
    runConfigurationName: 'Model configuration name',
    runConfigurationStatus: ['Running', 'Stopped'],
    interval: 'Interval',
    intervalUnits: 'min',
    editRunConfigurationAction: 'Edit model configuration',
    stopRunConfigurationAction: 'Deactivate model configuration',
    startRunConfigurationAction: 'Activate model configuration',
    nameRequiredError: 'Name is required.',
    maxLength: (max: number) => `Name must be less than ${max} characters`,
    sourcesListTitle: 'Sources',
    runInterval: 'Launch interval',
    day: 'Days',
    hour: 'Hours',
    min: 'Minutes',
    runIntervalDescription: 'The model is run',
    noRunsAvailable: 'Results not available yet',
    selectedPeriodNoRunsAvailable: 'No runs for the selected period',
    popupDelete: {
        title: 'Delete Control Point',
        body: 'Are you sure you want to delete control point “%s”?',
        cancel: COMMON.cancel,
        accept: COMMON.delete,
    },
    timeIntervalHours: ['hour', 'hours', 'hours'],
    every: {
        zero: 'every',
        one: 'every',
        two: 'every',
        few: 'every',
        many: 'every',
        other: 'every',
    },
    previousRun: 'Previous run',
    nextRun: 'Next run',
    newControlPointName: 'New control point',
    infoTextCreate:
        'To add a sampling point*, click on the map and move the marker.\n' +
        'To set a more precise geographic position based on coordinates, fill in the fields below.',
    infoTextEdit:
        'To change the geographic location of the sampling point, move the marker on the map.\n' +
        'To set a more precise geographic position based on coordinates, fill in the fields below.',
    coordinates: 'Geographic position',
    noControlPoints: 'No data',
    noRegions: 'No data',
    noStations: 'No data',
    unitMetr: 'm',
    windLayerButtonText: 'Wind animation',
    quickWindButton: 'Wind',
    tooltipWIndButton: {
        byHeight: 'Wind animation is available <br/>on the ground layer only',
        byPlayer: 'Not available in <br/>animation mode plumes',
    },
    expandControlPoint: 'Сollapse',
    contributionTitle: 'Source contributions',
    noDataControlPoint: 'No data for control point.',
    noContributes: 'No source contributions were recorded.',
    errorLoadContribution: 'Data not load. Please try again later or contact our customer service.',
    otherChartLabel: 'Other',
    switchPostButton: {
        off: 'Display monitoring<br/> sites on the map',
        on: 'Do not display <br/>monitoring sites on the map',
    },
    runResultLabelPeriod: 'Period',
    showSources: 'Show',
    contribution: {
        titlePost: 'Station',
        titleRun: 'Model results',
    },
    cardRun: {
        interval: 'Start model',
        duration: 'Forecast for',
        wind: 'Field of wind',
        windOn: 'on',
        windOff: 'off',
        measures: 'Measures',
    },
};

export const DASHBOARD = {
    dashboardTitle: 'Monitoring Network Status',
    refreshData: 'Refresh',
    availabilityStatsTitle: 'Availability',
    monthlyStatsTitle: 'Statistics for',
    packetsMonitorTitle: 'Data from Monitors',
    packets: {
        zero: 'packets',
        one: 'packet',
        two: 'packets',
        few: 'packets',
        many: 'packets',
        other: 'packets',
    },
    lowBattery: 'No Power',
    lowSignal: 'Low Signal',
    noPackets: 'No Data',
    showInMonitor: 'Show packets',
    dataTransmissionTitle: 'Data Transmission',
    dataTransmissionStatus: ['offline', 'online'],
    powerSupplyTitle: 'Power Supply',
    powerSupplyStatus: ['powered by battery', 'powered by power supply'],
    monthlyStatsChartTitle: 'Data from All Monitors',
    dataReceived: 'Data Received',
    deviceMonitorLegend: {
        dataReceived: 'Received data',
        hasMissingData: 'Has missing data',
        poweredByBattery: 'Powered by battery',
        lowGMSSignal: 'Low GSM signal',
    },
    availabilityTooltipText: 'Last packet has been received <br/>at',
    monthlyStatsTooltipText: 'Overall ratio of the received data <br/>from all monitors',
    selectedTimeIntervalLabel: 'Time interval',
    selectedDevicesLabel: 'Monitors',
    columnLabels: {
        device: 'Monitor',
        monitoringObject: 'Locality',
        status: 'Status',
        packetsRate: 'Packets received per hour',
    },
    searchDevicePlaceholderText: 'Search monitor',
    selectAllDevicesFilter: 'Select all',
    resetDevicesFilter: 'Reset filter',
    notSelectedStatusFilter: 'Not selected',
};

export const POSTS_AND_DEVICES = {
    postAndDevices: 'Monitoring sites and monitors',
    addPost: 'Add a monitoring site',
    viewInformation: 'View details',
    loadPrimaryData: 'Raw data download',
    downloadDataFromPost: 'Download',
    downloadDataFromPosts: 'Download',
    downloadTZA4Report: '',
    tza4: '',
    edit: 'Edit',
    replaceDevice: 'Replace monitor',
    deletePost: 'Remove monitoring site',
    dataDevice: 'monitor data',
    noMonitoringPosts: 'No monitoring sites yet',
    noDevices: 'No monitors',
    unfortunatelyNothing: 'Unfortunately there is nothing',
    searchPost: 'Search by site, monitor, or locality',
    searchDevice: 'Search site or monitor',
    post: 'Monitoring site',
    posts: 'Monitoring site',
    postsMonitoring: 'Monitoring site',
    location: 'Locality',
    aqi: 'AQI',
    device: 'Monitor',
    devices: 'Monitors',
    type: 'Type',
    state: 'Condition',
    nameOnMap: 'Name on the map',
    coordinate: 'Geographic position',
    timeZone: 'Time zones',
    dataSources: 'Data sources',
    actionHistory: 'History of activities',
    users: 'Users',
    documentation: 'Documentation',
    showMore: 'Show more',
    showLess: 'Show less',
    administrator: 'Administrator',
    operator: 'Operator',
    power: 'Power',
    dataTransfer: 'Data Transmission',
    info: 'Information',
    online: 'Online',
    offline: 'Offline',
    relatedDevices: 'Connected monitors',
    name: 'Name',
    description: 'Description',
    locationOnTheMap: 'Geographic position',
    createPost: 'Add new monitoring site',
    editPost: 'Edit monitoring site',
    newMO: 'New monitoring site',
    editMo: 'Edit monitoring site',
    dataFromPost: 'Data from monitoring sites',
    intervalData: 'Averaging interval',
    units: 'Units of measurement',
    pightsOfPDK: '',
    loadingDataForThePrevious: 'Loading data from previous period may take some time',
    format: 'Format',
    allAdministratorsAndOperators:
        'All administrators and operators have access to&nbsp;data from the monitoring site',
    asWellAsTheFollowing: ', as&nbsp;well as&nbsp;the following observers:',
    accessToData: 'Access rights',
    formError: {
        maxLength: (max: number) =>
            `The maximum length has been exceeded, value must not exceed ${max} characters`,
        required: 'Required value',
    },
};

export const INDOOR = {
    [ROOM]: 'Room',
    selectRoom: 'Select a room to display information',
    iaq: 'IAQI - indoor air quality index',
    search: 'Search',
    comfort: 'Comfort',
    statTitle: 'Summary for all offices',
    safety: 'Safety',
    title1: 'Good air, time',
    title2: 'IAQI’',
    emptyPosts: 'You do not have permission to view the monitoring sites',
    moMapMobile:
        'Dashboard mode is not yet available in the mobile version, but we are already working on it',
    emptyStatInfo: 'No data for the specified period',
    popupComfort: `Here we&nbsp;calculate the percentage of&nbsp;time during the work day when the air in&nbsp;the space was comfortable and contributed to&nbsp;productive work and <nobr>well-being</nobr>.
<br /><br />
Comfort is&nbsp;determined by&nbsp;the temperature, humidity and carbon dioxide levels.`,
    popupSafety: `Here we&nbsp;show summary statistics based on&nbsp;the IAQI in&nbsp;the rooms you manage: the percentage of&nbsp;time that air quality was high, as&nbsp;well as&nbsp;the average index per day.
<br /><br />
To&nbsp;calculate IAQI we&nbsp;use <nobr>real-time</nobr> data on&nbsp;the concentrations of&nbsp;PM2.5, PM10 and carbon dioxide CO2.`,
};
export const INDOOR_WIDGET_STATUS = {
    statusAQI: [
        'Air quality is good. The air is clean and fresh, comfortable for productive work.',
        'Air quality is moderate. Requires attentions.',
        'Air quality is critically poor. Requires immediate actions.',
    ],
    offlineStatus: 'Monitoring site is temporarily offline',
    notSettings: 'Widget is not configured. Please contact technical support',
    noData: 'No data',
};
export const ANALYTICS_COMPONENT = {
    tooltip: (index: number, measure: string) =>
        [
            `Share of each ${measure} value range in the period`,
            `Average ${measure} values by hour of day (for the entire period of observations)`,
            `Average ${measure} values by day of week (for the entire period of observations)`,
        ][index],
};

export const FEEDBACK = {
    leaveFeedback: 'Leave us feedback',
    formTitle: 'Please share your thoughts with us',
    formDescription:
        'We appreciate your feedback as it can help us understand how to better serve you.',
    formPlaceholder: 'Message',
    submit: 'Send',
    requiredField: 'This field is required',
    successTitle: 'Thank you! Your message has been sent.',
    successMessage: 'Your feedback will help us improve our service.',
};

export const AQI_DETAILED_INFO = {
    tooltip:
        'Air Quality Index ranging from 1 (low risk) to 10<br/> (very high risk). Depicts air quality at the current<br/> moment.',
    gotoFAQ: 'Learn more about AQI',
    title: 'Instant AQI',
    description: 'Air quality at the current moment',
    legendTitle: 'Possible values for the Instant AQI',
    dangerLevelLow: 'safe for everyone',
    dangerLevelMedium:
        'tolerable, but people with cardiovascular diseases, allergies and asthma might have negative reactions',
    dangerLevelHigh: 'unsafe for anyone',
    selectAqi: 'Only one of the air quality indexes<br>can be displayed on the graph',
};
export const REPORTS = {
    mainTitle: 'Reports',
    analiticTitle: 'Analitycs',
    reportsListTitle: 'Reports',
    compareTitle: 'Compare Data',
    searchText: 'Search report',
    settingsAutoText: 'Set up a report schedule',
    createReportTitle: 'Generate a report',
    historyListTitle: 'History of reports',
    checkAsReadTitle: 'Mark all as read',
    filter: 'Filter',
    hTableHeaderFormat: 'Format',
    hTableHeaderName: 'Name',
    hTableHeaderCreate: 'Create date',
    hTableHeaderUser: 'Creator',
    hTableHeaderStatus: 'Status',
    hTableHeaderComment: 'Comment',
    hDetailHeader: 'Site monitoring',
    CREATED: 'Created',
    QUEUED: 'In queue',
    IN_PROGRESS: 'Formatting',
    ERROR: 'Errors',
    READY: 'Ready',
    autoGenerate: 'Auto generate',
    clearFilter: 'Clear filter',
    emptyReport: 'Reports not found',
    generateReport: 'Create',
    noFoundKind: 'The report form not found',
    returnReportButtonText: 'Back to report list',
    noFoundKindReturn: '',
    noReportByPeriod: 'There are no reports for the selected period',
    filterPeriod: 'Create date',
    formCreate: {
        name: 'Name',
        nameError: 'Name is required.',
        postMoError: 'Required value',
        calendar: 'Report interval',
        commentPlaceholder: 'Comment',
        maxLength: (field: string, max: number) => {
            if (field === 'name') {
                return `Report name must be less than ${max} characters`;
            } else if (field === 'comment') {
                return `Comment must be less than ${max} characters`;
            }

            return `Field must be less than ${max} characters`;
        },
        max: (field: string, max: number) => `Field must be less than ${max}`,
        min: (field: string, min: number) => `Field must not be less than ${min}`,
        measure: 'The selected substance is not measured at the specified monitoring sites',
        errorTitle: 'Error',
        tryAgain: 'Please, try again',
        search: {
            timezone: 'Search timezone',
        },
    },
    keyParams: {
        available_formats: 'Format',
        measure: 'Measures',
        timezone: 'Timezone',
        year: 'Select year',
        fileupload: 'File upload',
        include_mpc: 'Include MPC',
    },
    measure: 'Measure',
    titleDeletePopup: (name: string) => `Are you sure you want to delete report «${name}»?`,
    titleDeleteCommentPopup: (name: string) =>
        `Are you sure you want to delete a comment on the report «${name}»?`,
    titleRenamePopup: 'Rename report',
    titleEditComment: 'Editing a comment to a report',
    titleAddComment: 'Adding a comment to a report',
    addComment: 'add',
    bodyDeletePopup: '',
    cancel: COMMON.cancel,
    accept: COMMON.delete,
    reports: {
        zero: 'reports',
        one: 'report',
        two: 'reports',
        few: 'reports',
        many: 'reports',
        other: 'reports',
    },
    countReportText: (count: number) => `Found: ${count} `,
    morePostText: (count: number) => `${count} more `,
    messages: {
        ERROR: {
            title: 'Error',
            text: (name: string) => `An error occurred while generating the «${name}» report`,
        },
        READY: {
            title: (name: string) => `Report «${name}» is ready`,
        },
        IN_PROGRESS: {
            title: (name: string) => `Report «${name}» is generated`,
        },
    },
    messagesEdit: {
        ERROR: {
            title: 'Error',
            text: (name: string) => `An error occurred while saving report the «${name}» report.`,
        },
        READY: {
            title: 'Report saved successfully',
        },
    },
    messageDeleteSuccess: 'Report deleted successfully',
    messageDeleteError: 'Error when deleting a report',
    rename: 'Rename',
    edit: 'Edit',
    save: 'Save',
    totalReports: 'Total reports',
    error403: 'Permission denied',
    error401: 'Error generating report. Try reloading the page',
    noPostsMsg: 'There is no monitoring sites data to generate a report',
    reportDetails: {
        step: 'Step',
        resultProcessingHeader: 'Report generation results',
        tooltipOutputFile: 'Download report details',
        statusCode: {
            parsing_excel: 'Analysis of input data',
            prepare_concentrations: 'Data preparation',
            generate_report_images: 'Generate charts',
            generate_output_file: 'Generate report',
            getting_data: 'Retrieving data for monitoring sites',
            generate_file: 'Generating file',
        },
        statusText: {
            Done: 'Success',
            Error: 'Error',
            error: 'Error',
        },
        noData: 'No data',
    },
    demo: {
        buttonExamplePdf: 'Annual report.pdf',
        buttonExampleXlsx: 'quality report.xlsx',
        showText: 'Examples of reports:',
        contact:
            'If you would like to automate the reporting process, please contact your CityAir Project Manager or use the contacts below:',
        desc: 'CityAir.Basic software has the ability to create any form of reports to solve your problems',
        tagXlsx: 'Report on the quality of communication with PAK devices',
        xlsxPeriod: 'Period',
    },
    download: {
        period: 'Period',
        fileNameDefault: 'Post_data',
    },
    fileUpload: {
        uploadText1: 'Drag and Drop the file here or ',
        uploadText2: 'click ',
        uploadText3: 'to upload',
        availableFormat: 'Available format for download: ',
        availableFormats: 'Available formats for download: ',
        status: {
            loading: 'Loading',
            done: 'File downloaded',
        },
        error: {
            incorrectFormat: 'Данный формат файла не поддерживается.',
            loadError: 'Возникли ошибки во время загрузки',
        },
    },
    confirmRedirectPopUp: {
        title: 'Data downloading is available in the new "Reports" module',
        body: 'Soon this functionality will finally move to the module. Start using it right now!',
        accept: 'Go',
        cancel: 'Cancel',
    },
};
export const FORECAST = {
    mainTitle: 'CityAir.Forecast',
    controlPointsTitle: 'Points',
    addControlPoint: 'Add sampling point',
    editControlPoint: 'Edit sampling point',
    newControlPoint: 'New sampling point',
    nameLabel: 'Name',
    latLabel: 'Latitude',
    lngLabel: 'Longitude',
    errorRequired: 'Required value',
    nameRequiredError: 'Name is required.',
    minError: 'Min value',
    maxError: 'Маx value',
    maxLength: (max: number) => `Name must be less than ${max} characters`,
    titleDeletePopup: (name: string) => `Are you sure you want to delete control point «${name}»?`,
    bodyDeletePopup:
        'You can re-create the control point later, the data in it will be loaded from the moment the calculations were started',
    cancel: COMMON.cancel,
    accept: COMMON.delete,
    deleteList: 'Delete control point',
    domainError: 'Control point coordinates outside the computational domain',
    edit: EDIT_STATION.edit,
    errorTitle: 'Error',
    tryAgain: 'Please, try again',
    noData: 'No data',
    errorListLoad: 'Something wrong, please try again later',
    errorLoadList404: 'Something wrong, please contact technical support',
    newControlPointName: 'New control point',
    infoTextCreate:
        'To add a sampling point*, click on the map and move the marker.\n' +
        'To set a more precise geographic position based on coordinates, fill in the fields below.',
    infoTextEdit:
        'To change the geographic location of the sampling point*, move the marker on the map.\n' +
        'To set a more precise geographic position based on coordinates, fill in the fields below.',
};

export const ONBOARDING = {
    doNotShowAgain: "Don't show again",
    before: 'Before',
    after: 'After',
    newMapStyle: {
        title: "Don't miss a chance to explore new CityAir detailed mapping.",
        description: [
            'Now you can see the terrain, forests, fields, swamps, farms and city boundaries on the maps.',
        ],
        imgUrl: 'onboarding/new-map-style-07-2022/new-map-style-en-after.jpg',
        imgUrlCompare: 'onboarding/new-map-style-07-2022/new-map-style-en-before.jpg',
    },
};
export const INFO_MESSAGE = {
    title: 'Error',
    internetOff: 'No network connection.',
    serverError: 'The server is temporarily unavailable, please try again later.',
    notFoundObject: 'Resource does not exist',
    notFoundPost: 'You do not have access to the selected monitoring site',
    tokenExpired: 'Your token has expired. Please reload the page.',
    errorQualityDataTimeline: 'Error occurred when loading data',
    Internal_Error: 'Unhandled internal exception',
    Bad_Request: 'Bad request',
    Invalid_UserId: "User ID is invalid or doesn't exist",
    Access_Denied: 'Access denied',
    Not_Found: 'Resource does not exist',
    Update_Success: 'Changes saved successfully',
    Create_Success: 'New object successfully created',
    Create_Error: 'Errors occurred while saving data',
    Delete_Success: 'Object deleted successfully',
    Delete_Error: 'You cannot delete. Please contact technical support',
    Delete_Post_Error: 'You cannot delete this monitoring site. Please contact technical support',
    update_user_success: LIST_USERS.whenSaving,
    create_user_success_when_add: LIST_USERS.whenAdd,
    create_user_success_when_create: LIST_USERS.whenCreate,
    delete_user_success: 'User deleted successfully',
    no_group: 'You have no active groups',
    windLoadError: 'Requested wind field data not found',
    no_forecast_config: 'No found any config. Please contact technical support',
};

export const mmtMapSelectorTooltip = 'Select the parameter to be displayed on the map';

export const QUALITY_DATA = {
    tooltip: {
        noData: 'Data not available',
        tempMaxValue: (val: string) =>
            `Data is uncertain due to unstable weather conditions <br/>T higher than +${val} °C<br/>`,
        tempMinValue: (val: string) =>
            `Data is uncertain due to unstable weather conditions <br/>T lower than ${val} °C<br/>`,
        tempGradient: (val: string) =>
            'Data from gas sensors can be<br/> incorrect due to abrupt temperature change',
        phMaxValue: (val: string) =>
            `Data is uncertain due to unstable weather conditions <br/>Humidity higher than  ${val}% <br/>`,
        phGradient: (val: string) => 'Limited validity of data on gas concentrations',
        mmtMaxValue: (val: string) => {
            const br = val.length > 30 ? '<br/>' : '';
            return `More than 50% percent of <br/>${val} ${br} values in the averaging interval<br/> exceed the upper limit of <br/>the measurement range<br/>`;
        },
        mmtMinValue: (val: string) => {
            const br = val.length > 30 ? '<br/>' : '';
            return `More than 50% percent of <br/>${val} ${br} values in the averaging interval<br/>are below the lower limit of<br/> the measurement range<br/>`;
        },
        mmtMinMaxValue: (val: string) =>
            `The value of ${val} is out of <br/> measurement limits<br/>`,
    },
    tooltipLink: 'More details',
    tooltipHeader: {
        warning: 'Warning!',
        unverifiedData: 'Unverified data',
    },
    timelineLabel: 'Data quality',
};
export const QUALITY_DATA_INFO = {
    aboutPercentData: (val: number) =>
        `The data is marked as invalid if the number of packets included in the calculation is greater than 50%`,
    deviceUseHeader: 'Terms of Use:',
    noData: {
        [TEMP]: `Ambient temperature outside the measurement range of sensors and equipment operating conditions`,
        [HUM]: `Humidity outside the measurement range of sensors and equipment operating conditions`,
    },
    [TEMP]: (val: string) =>
        `Ambient temperature (${val}°C) outside the measurement range of sensors and equipment operating conditions`,
    tempGradient: (val: string) =>
        'Gas sensor data may be incorrect<br/> due to sudden temperature changes',
    [HUM]: (val: number) =>
        `Humidity ${val.toFixed(
            1
        )}% outside the measurement range of sensors and equipment operating conditions`,
    phGradient: (val: string) => 'Reliability of data on gas concentrations is reduced',
    deviceInfo: {
        [TEMP]: {
            title: ' - ambient temperature',
            value: (min, max) => `oт ${min}°C до +${max}°C`,
        },
        [HUM]: {
            title: ' - relative humidity of the environment, % no more',
            value: (val) => `${val}`,
        },
    },
    menuItems: {
        dataQuality: 'Data quality',
        termsForUse: 'Equipment operating conditions',
        mainProperty: 'Basic metrological characteristics',
        markersData: 'Data marking',
        intervalMarkers: 'Marking ranges',
        conditionsMarkers: 'Criteria for marking N-minute averaging intervals',
        markersReports: 'Marking in reports',
    },
    sections: {
        dataQuality:
            'For any equipment, there are certain environmental conditions under which the obtained data are recognized as metrologically relevant, which means these data can be trusted. These conditions include temperature and humidity ranges. The limits of these ranges are specified in the description of the type of measuring equipment in the Russian measurement system',
        links: {
            cityair: 'to CityAir monitoring site',
            gModule: 'to G extension modules',
            dust: 'to Dust',
        },
        termsForUse: {
            tempTitle: 'Ambient temperature range',
            tempValue: (min, max) => `from ${min}°C to +${max}°C`,
            phTitle: 'Ambient relative humidity',
            phValue: (val) => `not exceeding ${val}%`,
            presTitle: 'Atmospheric pressure',
            presValue: 'from 84 to 106.7 kPa',
        },
        mainProperty: {
            tableHeader: {
                mmt: 'Measured parameter',
                interval: 'Indication range, mg/m<sup>3</sup>',
                interval1: 'Measurement ranges, mg/m3<sup>3</sup>',
                mainAccuracy: 'Basic tolerance, %',
                sub1: 'reduced<sup>1</sup>',
                sub2: 'relative',
            },
            titleMmt: {
                NO2: 'Nitrogen dioxide',
                O3: 'Ozone',
                CO: 'Carbon monoxide',
                H2S: 'Hydrogen sulfide',
                SO2: 'Sulfur dioxide',
            },
            textLimit: 'Permissible response time limit Т0.9Д — 180 s',
            textAccuracy:
                '<sup>1)</sup> The reduced error is normalized to the upper value of the measurement range.',
        },
        markersData: {
            header: 'There are several key data markers:\n',
            listHeader1: 'Warning',
            listHeader2: 'Missing data',
            listLi: [
                'The value for the measured gas has exceeded the upper limit of the measurement range',
                'The values for all measured gases may be uncertain due to out-of-limit weather parameters (T, RH)\n',
                'The values for all measured gases may be uncertain due to a sharp change in the gradient (increase/decrease rate of values) of weather parameters (T, RH)]',
            ],
        },
        intervalMarkers: {
            titleAllData: 'Marking all data',
            titleMmt: 'Marking a specific parameter',
            tempData: {
                title: 'Temperature (T)',
                minValue: (val: number) =>
                    `<b>${val}</b>< - all parameters are marked with a warning "Unverified data. T below ${val}°C"`,
                maxValue: (val: number) =>
                    `<b>+${val}</b> - all parameters are marked with a warning "Unverified data. T higher than +${val}°C"`,
                gradient:
                    '<b>% change in T gradient%</b> - all parameters are highlighted with a warning "Unstable weather conditions. Limited validity of data on gas concentrations"',
            },
            humData: {
                title: 'Humidity (RH)',
                maxValue: (val: number) =>
                    `<b>${val}%</b> - all parameters are highlighted with a warning "Unverified data. Humidity is higher than ${val}%"`,
                gradient:
                    '<b>% RH gradient change%</b> - all parameters are highlighted with a warning "Unstable weather conditions. Limited validity of data on gas concentrations”',
            },
            mmtData: {
                tableHeader: {
                    mmt: 'Pollutant',
                    min1: 'Lower limit, mg/m<sup>3</sup>',
                    max1: 'Upper limit, mg/m<sup>3</sup>',
                    min2: 'Lower limit, µg/m<sup>3</sup>',
                    max2: 'Upper limit µg/m<sup>3</sup>',
                    text: 'Warning text',
                },
            },
            text: (mmt: string) =>
                `Warning! More than 50% percent of ${mmt} values in the averaging interval exceed the upper limit of the measurement range.`,
            imgExampleCritical: 'Example',
        },
        conditionsMarkers: {
            text: (val: number) =>
                `The general criteria are that if 50% of data per an averaging interval is uncertain then all data from this interval are marked as uncertain. For example, if data from two or more 5-minute intervals is uncertain within a 20-minute interval then the entire 20-minute interval is marked as uncertain. If data from two or three 20-minute intervals are uncertain in a 1-hour interval, then the entire 1-hour interval is marked as uncertain. If data from 12 or more 1-hour intervals is uncertain then the entire 24-hour interval is marked as uncertain.`,
            list: {
                min5: (val: number) =>
                    `5-minute averaging: if 3 or more data packets meet the conditions, then a warning appears for this 5-minute interval`,
                min20: (val: number) =>
                    `20-minute averaging: if 2 or more 5-minute intervals meet the conditions, then a warning appears for this 20-minute interval`,
                hour: (val: number) =>
                    `1-hour averaging: if 2 or more 20-minute intervals meet the conditions, then a warning appears for this 1-hour interval`,
                day: (val: number) =>
                    `24-hour averaging: if 12 or more hours meet the conditions, then a warning appears for this 24-hour interval`,
            },
        },
        markersReports:
            'In all downloaded files with data cells with warnings are marked with a color with an explanation.',
    },
};
export const ANALYSIS = {
    mainTitle: 'Network analysis',
    pagesName: {
        summary: 'Summary',
        links: 'Links',
        posts: 'Sites',
    },
    summary: {
        header: 'Network density',
        density: ['Very Low', 'Low', 'Medium', 'High'],
        recommendTextTitle: 'It is recommended',
        recommendByKey: {
            increase_posts_count: 'to increase the number of monitoring posts',
            optimize_posts_positioning: 'to optimize the location of monitoring posts',
        },
        infoAttention: 'Sites monitoring with the lowest correlation on the network',
        nameHeader: 'Monitoring site',
        distHeader: 'To nearest post',
        byPeriod: 'over period',
        averageDist: 'Average minimum distance between posts',
        squareText: (value) => `on ${value} km<sup>2</sup>`,
        popup: {
            desc: '% of existing monitoring sites from the optimal (calculated) number of monitoring posts',
            subTitle: 'Average minimum distance between monitoring posts',
            subDesc: 'Allows you to evaluate the relative position of monitoring posts',
            increaseValue: ['<40%', 'from 40% to 60%', 'from 60% to 80%', '>80%'],
            averageStatus: ['Very Low', 'Low', 'Average', 'High'],
            averageDist: ['>4km', 'from 4km to 3km', 'from 3km to 2km', '<2km'],
        },
    },
    posts: {
        searchPlaceholder: 'Search site monitoring',
        noFoundPost: 'No found site monitoring',
    },
    correlation: {
        title_date: 'Data Correlation',
        titleDetail: 'Correlation with other sites monitoring',
        titleWind: 'Wind field',
        titleWindDesc: 'Wind direction frequency per day, (%)',
        filter: {
            all: 'All',
            max: 'Highest Correlation',
            min: 'Lowest Correlation',
        },
        sortHeader: {
            name: 'Name',
            dist: 'Distance',
            cor: 'CC',
        },
        unitsDist: ['m', 'km'],
        dateNames: {
            month: 'Month',
            quarter: 'Three Month',
            halfYear: 'Half Year',
            year: 'Year',
        },
        onMMt: 'on',
        chartTitleMmt: (mmt) => `Measurements from monitoring sites (${mmt})`,
        chartTitleCorrelation: 'Correlation coefficient',
        infoPopUp: {
            title: 'How the correlation coefficient is calculated',
            content:
                'Pollutant concentrations are influenced by many factors. Different factors have different impact duration and territorial coverage. In order to assess how different the conditions are in different nodes of the monitoring network, a statistical distance is calculated between the nodes - a measure of how close the nodes of the network are as objects characterized by a history of observations. <b>The correlation coefficient is used as a quantitative measure of statistical distance.</b> ',
            content1:
                'Coefficient of correlation (CC) between two series of observations X and Y is a number between -1 and 1. CC is equal to 1 if there is a rigid linear relationship between the series of observations: Y = a + b X, with constant values of a and b and b > 0.',
            title2: 'For a qualitative assessment of the strength of the connection, the Chaddock scale is used: ',
            title3: 'Negative values indicate an inverse relationship: if X goes up, then Y goes down, and vice versa.',
            values: [
                '-1.0<br/>-<br/>-0.9',
                '-0.9<br/>-<br/>-0.7',
                '-0.7<br/>-<br/>-0.5',
                '-0.5<br/>-<br/>-0.3',
                '-0.3<br/>-<br/>-0.1',
                '-0.1<br/>-<br/>0.1',
                '0.1<br/>-<br/> 0.3',
                '0.3<br/>-<br/> 0.5',
                '0.5<br/>-<br/>0.7',
                '0.7<br/>-<br/> 0.9',
                '0.9<br/>-<br/>1.0',
            ],
            valuesLegend: [
                '-1,0 - -0,9 ',
                '-0,9 - -0,7',
                '-0,7 - -0,5',
                '-0,5 - -0,3',
                '-0,3 - -0,1',
                '-0,1 - 0,1',
                '0,1 -  0,3',
                '0,3 -  0,5',
                '0,5 - 0,7',
                '0,7 -  0,9',
                '0,9 - 1,0',
            ],
            labels: [
                'Strong\n' + 'negative',
                'High negative',
                'Noticeable\n' + 'negative',
                'Moderate\n' + 'negative',
                'Weak\n' + 'negative',
                'Absent',
                'Weak\n' + 'positive',
                'Moderate\n' + 'positive',
                'Noticeable\n' + 'positive',
                'High\n' + 'positive',
                'Strong\n' + 'positive',
            ],
        },
        shortLabel: 'CC',
        notFoundCorrelationData: 'No correlation data found.',
        back: 'To list of sites monitoring',
        noData: 'No data',
        goToLinks: 'Go to link',
        emptyDataByPeriod: 'No data to calculate the correlation for the selected period.',
    },
    mapLabelInfoPin: 'Recommended network connection zone',
    closeChart: 'Close links',
};
export const ANALYSIS_EVENTS = {
    mainTitle: 'Events analysis',
    registerEventsTitle: 'Register events',
    registerEvents: {
        titleHeader: {
            oftenMmt: (currentYear) => `Most often ${currentYear} year was exceeded`,
            avarageDuration: (currentYear) =>
                `Average duration of incidents in ${currentYear} year`,
            numbersofIncident: (currentYear) =>
                `Number of days in ${currentYear} when network excesses were detected`,
        },
        tableHeader: {
            mmt: 'Parameter',
            value: 'Value',
            name: 'Monitoring site',
            period: 'Start / End',
            periodStart: 'Start',
            periodEnd: 'End',
            forecast: 'End forecast',
            duration: 'Duration',
            veracity: 'Veracity',
            mmtLine: 'Measurements',
            forecastLine: 'Neural net calculation',
            status: 'Status',
            all: 'All',
        },
        searchText: 'Search by site',
        clearText: 'Clear filters',
        pdkMr: 'MPC',
        actions: {
            analysis: 'Analysis',
        },
        statusText: {
            NEW: 'New',
            WAIT: 'Waiting',
            CANCEL: 'Cancel',
            ACCEPT: 'Accept',
        },
        statusTextCounter: {
            NEW: 'New',
            WAIT: 'Waiting',
            CANCEL: 'Cancel',
            ACCEPT: 'Accept',
        },
        typeByEndText: {
            NEW: 'New',
            END: 'Completed',
        },
        typeByEndTextCounter: {
            NEW: 'New',
            END: 'Completed',
        },
        total: 'Total',
        noEventsByPeriod: 'No incidents found for the selected period',
        clearFilter: 'Clear',
    },
    downloadEventsButton: 'Download',
    analysisEvent: {
        buttonText: 'Event Analysis',
        tableHeader: {
            mmt: 'Parameter',
            value: 'Value',
            name: 'Monitoring site',
            period: 'Start / End',
            forecast: 'End forecast',
            duration: 'Duration',
            veracity: 'Veracity',
            mmtLine: 'Measurements',
            forecastLine: 'Neural net calculation',
        },
        cardTitle: {
            mainStatistics: 'General Statistics',
            postMmts: 'Measurements from monitoring sites',
            correlationData: 'Data correlation',
            windData: 'Wind field',
            correlationMap: 'Correlation with other monitoring sites',
            searchSource: 'Search source',
            averageDurations: 'Average duration of excess in the last quarter',
            numbersByDay: 'Number of exceedances by hours of the day in the last quarter',
            concentration: (mmt) => `Concentration by ${mmt} cumulative total`,
            correlationTable: 'Data correlation table',
            comments: 'Discussion',
        },
        statisticTabs: {
            100: 'All sites',
            90: '90% sites',
            50: '50% sites',
            20: '20% sites',
        },
        barChartLabels: ['< 30 min', '0.5 — 1 h.', '1 — 2 h.', '2 — 4 h.', '4 — 8 h.', '> 8 h.'],
        barChartNoDataText: 'ЗThere were no exceedances recorded for the selected period',
        WHOChartLabels: [
            'WHO — intermediate indicator № 2',
            'WHO — intermediate indicator № 3',
            'WHO — intermediate indicator № 4',
        ],
        statisticsText: (mmt) =>
            `Number of days in the last year when ${mmt} was exceeded in the network`,
        statisticAverageResult: 'Most exceedances on posts last up to 4 hours',
        statisticAverageResultByIndex: [
            'Most network outages at posts last up to 30 minutes',
            'Most network outages at posts last up to an hour',
            'Most network outages at posts last up to 2 hours',
            'Most network outages at posts last up to 4 hours',
            'Most network outages at posts last up to 8 hours',
            'Most network outages at posts last more than 8 hours',
        ],
        statisticDaysNumberResult:
            'The main peak of exceedances falls on the interval from 8:00 to 12:00',
        statisticDaysNumberResultByIndex: (index) =>
            `The main peak of exceedances falls on the interval from ${index}:00 to ${
                index + 1
            }:00`,
        searchButtonText: 'Find source',
        notEnded: 'not completed',
        finishChartLabel: 'End',
        correlationTabsName: {
            month: 'Month',
            quarter: 'Quarter',
            halfYear: 'Half a year',
            year: 'Year',
        },
        eventTypeTitle: 'Event Type',
        eventTypeName: {
            global: 'Global event.',
            local: 'Local event.',
        },
        eventTypeDesc: {
            global: 'A large number of sites monitoring fixed excesses, most likely this is a global event. For example, forest fires, dust transport from the desert, or a strong release from a source.',
            local: 'Less than half of the stations on the network record excesses, most likely this is a local event.',
        },
        forecastEndTitle: 'End forecast',
        resultExpert: 'Expert decision',
        doneResultTitle: 'Approved',
        userRole: {
            ecolog: 'Ecologist',
            mainEcolog: 'Chief Ecologist',
        },
        dataCorrChartLabel: 'for 1 months before incident',
        dataCorrChartLabelLastDay: 'for 24 hours before incident',
        windDescriptionLabel: 'Wind direction frequency per day,',
        actions: {
            asResult: 'Select as result',
            add: 'Add to log',
            cancel: 'Dismiss event',
            addComment: 'Add comment',
        },
        analysisEvent: 'Analysis Event',
        analysisResults: 'Analysis results',
        collapse: 'Hide',
        expand: 'Show',
        excess: {
            zero: 'excesses',
            one: 'excess',
            two: 'excesses',
            few: 'exceedances',
            many: 'exceedances',
            other: 'exceedances',
        },
    },
    more: 'more',
    monitoring: 'monitoring',
    notEnded: 'not completed',
};
export const MAP_STYLE_SELECTOR = {
    title: 'Maps',
    labels: {
        cityair: 'CityAir',
        satellite: 'Satellite',
        yandex: 'Yandex.Map',
        osm: 'Scheme',
        outdoor: 'Landscape',
    },
};
export const MAP_CONTROL = {
    title: {
        default: 'Data by period',
        nowOnMap: 'Now on the map',
        forecast: 'Forecast for',
    },
    on: 'on',
    labels: {
        pollutant: 'Pollutant',
        meteo: 'Meteo',
    },
};
export const IMPACT = {
    cities: {
        title: 'City',
        currentDate: 'Now in city:',
    },
    cityEnvChars: {
        title: 'Characteristics of the urban environment',
    },
    networkDensity: {
        title: 'Network Density',
        linkMoreText: 'More',
        headers: {
            name: 'Districts',
            posts: 'Posts',
            area: 'Area',
            density: 'Density',
        },
        density: ['Very Low', 'Low', 'Medium', 'High'],
        monitoring: 'monitoring',
        squareText: (value) => `on ${value} km<sup>2</sup>`,
    },
    emissionSources: {
        title: 'Emission sources',
        link: 'Go to the sources',
    },
    cityDistricts: {
        title: 'City regions',
        link: 'Go to control points',
    },
    airQualityCity: {
        title: 'Air quality in the city',
    },
    sources: {
        title: 'Sources',
        backToSources: 'To the list of sources',
        labels: {
            objTitle: 'Source name',
        },
        filters: {
            period: 'Period',
            measure: 'Measurement',
        },
        noData: 'No sources',
        noFoundSource: 'Not found',
        sourceCard: {
            sourceType: {
                point: 'Point',
                square: 'Square',
            },
            emissionIntensity: 'Emission intensity',
            titleSource: 'Source parameters',
            nameSource: 'Source name',
            type: 'Type',
            height: 'Height',
            square: 'Area',
            titleParams: 'MPE emission parameters',
            formTube: 'Mouthhead shape',
            numberPDV: 'MPE no.',
            estimatedEmissions: 'Estimated emissions',
            estimatedEmissionsUnit: 't/yr',
            modelingParamsUnit: 'g/sec',
            modelingParams: 'Modeling parameters',
            influenceStatistics: 'Influence Statistics',
            avarageContribution: 'Average duration of influence of the source for',
        },
    },
    mapObjectTitle: {
        posts: 'Site monitoring',
        points: 'Control points',
        sources: 'Sources',
        regions: 'Regions',
    },
    regions: {
        fields: {
            name: 'Title',
        },
    },
    region: {
        backTitle: 'To the list of regions',
        subtitle: 'Region',
        statisticTitle: 'Exceedance statistics',
        noStatsRegion: 'There is no data on excess statistics',
        otherLabel: 'Other',
        titleChartExceed: 'Influence of sources on the area by',
        titleChartExceedFor: 'for',
        titleChartAverageMmt: ' Average calculated concentration of pollutants for ',
        titleChartMax: 'Maximum calculated one-time concentration',
        titleChartNumberExceed:
            'Number of exceedances of calculated concentrations above the threshold (1 MPCmr) for ',
        noChartExceed: 'No influence of sources registered',
        noChartAverageMmt: 'No data on average concentration',
        noChartNumberExceed: 'No exceedances registered',
    },
    controlPoints: {
        buttonAdd: 'Add',
        noControlPoints: 'No data',
        noRegions: 'No data',
        noStations: 'No data',
        tabs: {
            regions: 'Regions',
            points: 'Control points',
            posts: 'Sites',
        },
        sites: {
            subTitle: 'Site monitoring',
        },
    },
    eventList: {
        emptyDataEvents: 'No episodes recorded for the selected period',
        filterLabelAll: 'All',
        headerStart: 'Start',
        details: {
            backTitle: 'Back to incident list',
            postsTitle: 'List of posts',
            postHeader: 'Monitoring post',
            postsHeader: 'Monitoring posts',
            maxHeader: 'Max concentration',
            mmtHeader: 'Measurement',
            mainImpactHeader: 'Contribution of sources to the event',
            mainImpactDescription: 'Contribution of sources to the recorded excess by',
            mainImpactDescriptionFor: 'by',
            posts: {
                zero: 'monitoring sites',
                one: 'monitoring site',
                two: 'monitoring sites',
                few: 'monitoring sites',
                many: 'monitoring sites',
                other: 'monitoring sites',
            },
            showMoreBtn: 'More',
            closeBtn: 'Close',
            printRtn: 'Print',
            mainImpact: 'Main Impact',
            repeatHeader: 'Exceedance Repeat',
            allSourceHeader: 'All Sources in Event',
            correlationChartHeader: 'Data Correlation',
            threeMonthLabel: '3 Months',
            lastDayLabel: 'Last 24 Hours',
        },
    },
    chartNoContribution: 'No contributions from inventoried sources were recorded.',
};
export const NETWORK = {
    mapControl: {
        title: 'Settlement period',
    },
    priorityHeader: 'Priority areas for placement of monitoring posts',
    postHeader: 'Average estimated concentration in the area of installation of monitoring posts',
    name: 'Name',
    hasPost: 'There is',
    neighborPost: 'monitoring a neighboring area',
    periodTitle: 'Average for the period',
    monitoring: 'monitoring',
    polygonName: 'Region',
    showMore: `Show more areas`,
    collapse: 'Collapse',
    areas: {
        zero: 'areas',
        one: 'area',
        two: 'areas',
        few: 'areas',
        many: 'areas',
        other: 'areas',
    },
};
