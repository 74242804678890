<div class="impact-cities scroll">
    <ng-container *ngIf="store.select(selectCurrentCityData) | async as cityData">
        <div class="city__header">
            <div class="city__header-content">
                <div class="city__title">{{ (paramsForLocality$ | async)?.cityName }}</div>
                <div class="city__time">
                    {{ textImpact.cities.currentDate }}
                    <span class="city__time_bold">{{ currentDate() }}</span>
                </div>
            </div>
            <value-circle
                class="city__result"
                [value]="cityData | dataForLocalityWidget: 'instant'"
                [color]="measureZones.getColor(cityData | dataForLocalityWidget: 'instant', AQI)"
            ></value-circle>
        </div>
        <div class="city__mmts">
            <mmt-item
                *ngFor="let mmt of (paramsForLocality$ | async)?.cityMmts"
                [id]="mmt.id"
                [title]="TEXTS.NAMES[mmt.id]"
                [value]="mmt.id === 'AQI' ? (cityData | dataForLocalityWidget: 'instant') : (cityData | dataForLocalityWidget : mmt.id)"
                [unit]="mmt.id === 'AQI' ? getAQIunit(cityData | dataForLocalityWidget: 'instant') : TEXTS.MEASURES_MG[mmt.id]"
            ></mmt-item>
        </div>
    </ng-container>
    <ng-container *ngIf="impactSettings$ | async as impactSettings">
        <div class="city__image" *ngIf="impactSettings?.cityImage as cityImage">
            <img
                [attr.src]="getAsset('impact/' + cityImage)"
                alt="City"
            />
        </div>
        <div class="city__widgets">
            <widget
                class="city__widget widget__env-chars"
                [title]="textImpact.cityEnvChars.title"
            >
                <div class="env-chars__items">
                    <div class="env-chars__item" *ngIf="impactSettings?.cityPopulation as population">
                        <div class="env-chars__item-label">Население</div>
                        <ng-container  *ngIf="population else noDataTemp">
                            <div class="env-chars__item-value">{{ population | cityPopulation : currentLang }} жителей</div>
                        </ng-container>
                        <ng-template #noDataTemp>
                            <div class="env-chars__item-value">не задано</div>
                        </ng-template>
                    </div>
                    <div class="env-chars__item" *ngIf="impactSettings?.citySquare as citySquare">
                        <div class="env-chars__item-label">Площадь территории</div>
                        <ng-container  *ngIf="citySquare else noDataTemp">
                            <div class="env-chars__item-value">{{ citySquare | valueFormatNumber : 1 }}  км²</div>
                        </ng-container>
                        <ng-template #noDataTemp>
                            <div class="env-chars__item-value">не задано</div>
                        </ng-template>
                    </div>
                </div>
            </widget>
        </div>
    </ng-container>
    <div class="city__widgets">
        <widget *ngIf="networkDensityData$ | async as networkDensity" class="network-density__common-data"
            class="city__widget widget__network-density"
            [title]="textImpact.networkDensity.title"
        >
            <div class="network-density__content">
                <div class="network-density__summary">
                    <div class="network-density__summary-result">{{ getStatusByData(networkDensity) }}</div>
                    <div class="network-density__summary-detail">
                        {{postCountText(networkDensity.stations_count)}} {{textImpact.networkDensity.monitoring}} <span [innerHtml]="textImpact.networkDensity.squareText(formatSquare(networkDensity.network_area_total))"></span>
                    </div>
                </div>
                <div class="network-density__average-percent">
                    <span>{{ networkDensity.stations_coverage * 100 }}</span>%
                </div>
            </div>
            <div class="network-density__region-table">
                <div class="network-density__region-table_header">
                    <shared-sort-list-item
                        class="region-column__title"
                        (click)="setRegionSortingCb('name')"
                        [sortingDirection]="sortRegionField === 'name' ? sortRegionDirection : 0"
                        [text]="textImpact.networkDensity.headers.name"
                    ></shared-sort-list-item>
                    <shared-sort-list-item
                        class="region-column__posts"
                        (click)="setRegionSortingCb('post_ids')"
                        [sortingDirection]="sortRegionField === 'post_ids' ? sortRegionDirection : 0"
                        [text]="textImpact.networkDensity.headers.posts"
                    ></shared-sort-list-item>
                    <shared-sort-list-item
                        class="region-column__area"
                        (click)="setRegionSortingCb('region_square')"
                        [sortingDirection]="sortRegionField === 'region_square' ? sortRegionDirection : 0"
                        [text]="textImpact.networkDensity.headers.area"
                    ></shared-sort-list-item>
                    <shared-sort-list-item
                        class="region-column__density"
                        (click)="setRegionSortingCb('density')"
                        [sortingDirection]="sortRegionField === 'density' ? sortRegionDirection : 0"
                        [text]="textImpact.networkDensity.headers.density"
                    ></shared-sort-list-item>
                </div>
                <div class="network-density__region-table_body" *ngIf="regions$ | async as regions">
                    <ng-container *ngFor="let region of regions | orderByRegions : sortRegionField : sortRegionDirection; index as i">
                        <div 
                            *ngIf="i < 3 || showAllRegions"  
                            class="network-density__region-item"
                        >
                            <div class="region-column__title region-column__title-value">{{ region?.name }}</div>
                            <div class="region-column__posts region-column__posts-value">{{ region?.post_ids?.length }}</div>
                            <div class="region-column__area region-column__area-value">{{ region?.region_square | valueFormatNumber : 0}}</div>
                            <div class="region-column__density region-column__density-value"> {{ region | impactDensityRegion | valueFormatNumber : 0}} %</div>
                        </div>
                    </ng-container>
                </div>
                <div
                    *ngIf="!showAllRegions" 
                    class="show-more" 
                    (click)="showAllRegions = !showAllRegions"
                >
                    <span>Смотреть все</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                        <path d="M1 1L5 5L9 1" stroke="#95A0B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </widget>
        <ng-container *ngIf="store.select(selectActiveSources) | async as sources">
             <widget *ngIf="sources?.length"
                class="city__widget widget__emission-sources"
                [title]="textImpact.emissionSources.title"
                [widgetLink]="sourceLink"
            >
                <ng-template
                    #sourceLink
                >
                    <div
                        class="widget-link"
                        (click)="goToSource()"
                    >{{ textImpact.emissionSources.link }}</div>
                </ng-template>
                <ng-container  *ngIf="activeRun$ | async as run">
                    <ng-container
                            *ngIf="sources | impactSourceEmissionTotal : run as emissionValue">
                        <div class="emission-total">
                            <div class="emission-total__item">
                                <div class="label">Всего</div>
                                <div class="emission-total__item-value">{{ sources?.length }}</div>
                            </div>
                            <div class="emission-total__item">
                                <div class="label">Объём выбросов</div>
                                <div class="emission-total__item-value">
                                   ~ {{ emissionValue | impactTotalEmission | valueFormatNumber : 1 }}
                                   {{ textImpact.sources.sourceCard.estimatedEmissionsUnit }}
                                </div>
                            </div>
                        </div>
                        <div class="emission-spices">
                            <div class="label">Вещества</div>
                            <div class="emission-spices__list">
                                <div
                                    *ngFor="let mmt of run?.species_list"
                                    class="mmt"
                                    [innerHTML]="textNames[mmt] ?? mmt"
                                ></div>
                            </div>
                            <div *ngIf="showAllMmt"  class="emission-spices__details">
                                <div *ngFor="let mmt of run?.species_list" class="detail">
                                    <div class="name" [innerHTML]="textNames[mmt] ?? mmt"></div>
                                    <div class="value">
                                        <span class="number">{{ emissionValue[mmt] / 10000 | valueFormatNumber : 1 }}</span>
                                        <span class="unit">{{ textImpact.sources.sourceCard.estimatedEmissionsUnit }}</span>
                                    </div>
                                    <div class="percent">{{ emissionValue | impactEmissionPercentByTotal : mmt | valueFormatNumber : 0 }}%</div>
                                </div>
                            </div>
                            <div
                                class="show-more"
                                (click)="showAllMmt = !showAllMmt"
                            >
                                @if(!showAllMmt){
                                    <span>Смотреть по веществам</span>
                                }
                                @else {
                                    <span>Скрыть</span>
                                }
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                    <path d="M1 1L5 5L9 1" stroke="#95A0B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </widget>
        </ng-container>
        <ng-container *ngIf="regions$ | async as regions">
            <widget *ngIf="regions?.length"
                class="city__widget widget__city-districts"
                [title]="textImpact.cityDistricts.title"
                [widgetLink]="cityDistrictsLink"
            >
                <ng-template
                    #cityDistrictsLink
                >
                    <div
                        class="widget-link"
                        (click)="goToControlPoints()"
                    >
                        {{ textImpact.cityDistricts.link }}
                    </div>
                </ng-template>
                <div class="districts__subtitle">Топ-5 по сумме вклада источников</div>
                <city-district-chart
                    [data]="dataChart"
                    [labels]="labelChart"
                    [colors]="colorsChat"
                ></city-district-chart>
            </widget>
        </ng-container>
        <widget 
            class="city__widget widget__air-quality-city"
            [title]="textImpact.airQualityCity.title"
        >
            <div class="air-quality__charts">
                <shared-ui-analytic
                    class="charts-wrapper"
                    [selectedCity]="currentCity"
                    [isLoading]="isLoadingSummary$ | async"
                    [distributionSummary]="distributionSummary$ | async"
                    [isDemo]="false"
                    [showCitySelector]="false"
                ></shared-ui-analytic>
            </div>
        </widget>
    </div>
</div>
