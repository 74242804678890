import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SOURCES_TYPE_BY_ID } from '@libs/common';

@Component({
    standalone: true,
    selector: 'cityair-impact-source-pin',
    templateUrl: './impact-source-pin.component.html',
    styleUrls: ['./impact-source-pin.component.less'],
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactSourcePinComponent implements OnChanges {
    @Input() name: string;
    @Input() id: number;
    @Input() isCollapse = false;
    type = 'factory'; // factory, farma,tube, trash
    SOURCES_TYPE_BY_ID = SOURCES_TYPE_BY_ID;
    ngOnChanges(changes: SimpleChanges): void {
        this.type = SOURCES_TYPE_BY_ID[this.id] ?? 'factory';
    }
}
