import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { tap, of, forkJoin } from 'rxjs';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { Store } from '@ngrx/store';
import {
    AuthService,
    BasicApiService,
    hideHtmlLoader,
    selectCurrentGroup,
    selectGroups,
    selectMyRole,
    selectUserScheme,
    SharedCoreActions,
    SharedGroupActions,
    SharedPostActions,
    StorageService,
    StoreSettingsPart,
    VangaAuthService,
} from '@libs/shared-store';
import {
    ADMIN_ID,
    copyObj,
    DEFAULT_MAP_STYLES,
    Group,
    GROUP_ID,
    GroupExtConfigName,
    IBasicResponse,
    InfoMessage,
    LANGUAGE,
    MAP_STYLE_TYPE_STORAGE_KEY,
    MapStyleType,
    MeasureScheme,
    measureZones,
    setSavedLang,
    User,
    ZONES,
} from '@libs/common';
import * as moment from 'moment-timezone';
import { GroupFeaturesService } from '../services/group-features.service';

@Injectable()
export class SharedStoreEffects {
    constructor(
        private store: Store,
        private actions$: Actions,
        private basicApi: BasicApiService,
        private authService: AuthService,
        private vangaAuthService: VangaAuthService,
        private storageService: StorageService,
        private groupFeaturesService: GroupFeaturesService
    ) {}

    loadUserInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedCoreActions.init),
            switchMap((props) => this.basicApi.getUser()),
            switchMap((response: IBasicResponse) => {
                const actions = [];
                const user = response?.data as User;
                if (user?.settings) {
                    // check current lang settings
                    if (user.settings.language && LANGUAGE !== user.settings.language) {
                        setSavedLang(user.settings.language);
                        location.reload();
                    }
                    const scheme = user.settings?.use_pdk_shares
                        ? MeasureScheme.mpc
                        : user.settings.measure_scheme;
                    actions.push(SharedCoreActions.setMeasureScheme({ payload: scheme }));
                    if (
                        user?.settings.timezone === null ||
                        user?.settings?.timezone === 'default'
                    ) {
                        moment.tz.setDefault();
                    } else {
                        moment.tz.setDefault(user.settings.timezone);
                    }
                }

                actions.push(SharedCoreActions.setUserInfo({ payload: response }));
                return actions;
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                hideHtmlLoader();
                const errorAction = getActionBasicError(errorResponse, true);
                return of(errorAction);
            })
        )
    );

    loadGroupsInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedCoreActions.setUserInfo),
            switchMap((props) => this.basicApi.getGroups()),
            map((response: IBasicResponse) => {
                const list = response?.data as Group[];
                if (list && list.length) {
                    return SharedGroupActions.setGroupList({ payload: response });
                } else {
                    hideHtmlLoader();
                    return SharedCoreActions.setInfoMessage({
                        payload: {
                            id: new Date().valueOf(),
                            messageKey: 'Access_Denied',
                            fullScreen: true,
                        },
                    });
                }
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                hideHtmlLoader();
                const errorAction = getActionBasicError(errorResponse, true);
                return of(errorAction);
            })
        )
    );

    setDefaultGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedGroupActions.setGroupList),
            withLatestFrom(this.store.select(selectGroups), this.store.select(selectCurrentGroup)),
            switchMap(([actionData, groupList, currentGroup]) => {
                const actions = [];

                if (!currentGroup?.id && groupList?.length) {
                    const savedGroupId = this.storageService.get(GROUP_ID);
                    const group = groupList?.find((g) => g.id === savedGroupId) ?? groupList[0];
                    const extConfig = group?.ext_config ?? {};
                    this.groupFeaturesService.setFeatures(extConfig);
                    // style for Map
                    const mapStylesTypes = extConfig?.mapStyleSelector ?? DEFAULT_MAP_STYLES;
                    if (mapStylesTypes?.length) {
                        const savedMapStylesType =
                            (this.storageService.get(MAP_STYLE_TYPE_STORAGE_KEY) as MapStyleType) ??
                            extConfig.mapSettings?.type;
                        if (savedMapStylesType && mapStylesTypes.includes(savedMapStylesType)) {
                            actions.push(
                                SharedCoreActions.setMapStyleType({ payload: savedMapStylesType })
                            );
                        }
                    } else if (
                        extConfig.mapSettings?.type &&
                        extConfig.mapSettings?.type in MapStyleType
                    ) {
                        actions.push(
                            SharedCoreActions.setMapStyleType({
                                payload: extConfig.mapSettings.type,
                            })
                        );
                    }

                    actions.push(SharedGroupActions.setCurrentGroup({ payload: group }));
                    actions.push(SharedGroupActions.groupInfoLoad({ groupId: group?.id }));
                }

                return actions;
            })
        )
    );

    loadInfoGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedGroupActions.groupInfoLoad),
            withLatestFrom(this.store.select(selectCurrentGroup), this.store.select(selectMyRole)),
            filter(([props, currentGroup]) => !!currentGroup),
            switchMap(([props, currentGroup, myRole]) => {
                const query = [
                    this.basicApi.getAllPost(props.groupId),
                    this.basicApi.getAllLocality(props.groupId),
                    this.basicApi.getAllDevices(props.groupId),
                ];
                return forkJoin(query).pipe(
                    switchMap((res: any) => {
                        const actions = [];
                        hideHtmlLoader();
                        actions.push(SharedPostActions.setAllPosts({ payload: res[0]?.data }));
                        actions.push(
                            SharedCoreActions.setGroupInfo({ payload: res, currentGroup, myRole })
                        );
                        return actions;
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        hideHtmlLoader();
                        const errorAction = getActionBasicError(errorResponse);
                        return of(errorAction);
                    })
                );
            })
        )
    );

    loadUsersInit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedGroupActions.groupInfoLoad),
            withLatestFrom(this.store.select(selectMyRole)),
            filter(([_, myRole]) => myRole !== null && myRole?.id === ADMIN_ID),
            map(() => SharedCoreActions.loadUsersList())
        )
    );

    loadUsersList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedCoreActions.loadUsersList),
            withLatestFrom(this.store.select(selectCurrentGroup), this.store.select(selectMyRole)),
            filter(([_, group, myRole]) => group !== null && myRole?.edit_user),
            tap(() => 
                this.store.dispatch(SharedCoreActions.setIsLoadingUsers({ payload: true }))
            ),
            switchMap(([props, group]) =>
                this.basicApi.getUsers(group.id).pipe(
                    map((response: IBasicResponse) => SharedCoreActions.setUsersList({ payload: response })),
                    catchError((errorResponse: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(errorResponse);
                        return of(
                            errorAction,
                            SharedCoreActions.setUsersList({ payload: { data: [], meta: null } }),
                            SharedCoreActions.setIsLoadingUsers({ payload: false })
                        );
                    })
                )
            )
        )
    );

    saveUserSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedCoreActions.updateUserSettings),
            switchMap((action) =>
                this.basicApi.updateUserSettings(action.payload).pipe(
                    switchMap((response) => {
                        location.reload();
                        return [];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        }
                    })
                )
            )
        )
    );

    setCurrentMapStyleType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedCoreActions.setMapStyleType),
            map((action) => {
                this.storageService.set(MAP_STYLE_TYPE_STORAGE_KEY, action.payload);
                return SharedCoreActions.noop();
            })
        )
    );

    prepareSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedCoreActions.setGroupInfo),
            withLatestFrom(this.store.select(selectUserScheme)),
            switchMap(([data, scheme]) => {
                const actions = [];
                const obj: StoreSettingsPart = {};
                const extConfig = data.currentGroup?.ext_config ?? {};
                if (extConfig) {
                    const pinsDataFormat = extConfig[GroupExtConfigName.pinsDataFormat];
                    if (pinsDataFormat) {
                        const zones = copyObj(ZONES);
                        zones[pinsDataFormat.measureScheme][pinsDataFormat.type] = {
                            color: pinsDataFormat.colors,
                            zone: pinsDataFormat.zones,
                        };

                        obj.measuresZones = zones;
                        actions.push(SharedCoreActions.setMeasureZoneGroup({ payload: obj }));
                    }
                }
                measureZones.setScheme(scheme);

                if (!data.myRole) {
                    actions.push(
                        SharedCoreActions.setInfoMessage({
                            payload: {
                                id: new Date().valueOf(),
                                messageKey: 'Access_Denied',
                                positionX: 'left',
                                positionY: 'bottom',
                                iconClass: 'error',
                                duration: 10000,
                                showCloseIcon: true,
                                size: 'lg',
                            },
                        })
                    );
                }

                return actions;
            })
        )
    );

    refreshToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedCoreActions.vangaTokenRefresh),
            switchMap((action) =>
                this.vangaAuthService.getVangaToken().pipe(
                    map((result) => {
                        if (result?.access) {
                            this.vangaAuthService.setAccessToken(result.access);
                            return SharedCoreActions.vangaTokenUpdated();
                        }
                    }),
                    catchError((error) => of(SharedCoreActions.vangaTokenUpdateError()))
                )
            )
        )
    );

    logOut$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SharedCoreActions.logOut),
                tap(() => this.authService.logOut(false, true)),
                tap(() => this.vangaAuthService.removeAccessToken())
            ),
        {
            dispatch: false,
        }
    );
}

export function getShowInfoParams(key, isCritical?): InfoMessage {
    if (isCritical) {
        return {
            messageKey: key,
            fullScreen: true,
        } as InfoMessage;
    }
    return {
        id: new Date().valueOf(),
        messageKey: key,
        positionX: 'left',
        positionY: 'bottom',
        iconClass: 'error',
        duration: 10000,
        showCloseIcon: true,
        size: 'lg',
    } as InfoMessage;
}

export function getActionBasicError(errorResponse: HttpErrorResponse, isCritical?) {
    const status = errorResponse?.status;
    if (status === 0) {
        return SharedCoreActions.noop();
    } else if (!status || status >= HttpStatusCode.InternalServerError) {
        const params = getShowInfoParams('serverError', isCritical);
        return SharedCoreActions.setInfoMessage({ payload: params });
    } else if (status === HttpStatusCode.Unauthorized) {
        return SharedCoreActions.logOut();
    } else if (status === HttpStatusCode.NotFound) {
        const params = getShowInfoParams('Not_Found', isCritical);
        return SharedCoreActions.setInfoMessage({ payload: params });
    } else if (status === HttpStatusCode.Conflict) {
        const params = getShowInfoParams('Create_Error', isCritical);
        return SharedCoreActions.setInfoMessage({ payload: params });
    } else {
        const error =
            errorResponse?.error?.InternalErrorCode ??
            errorResponse?.error.meta?.error?.internal_error_code;
        const params = getShowInfoParams(error, isCritical);
        return SharedCoreActions.setInfoMessage({ payload: params });
    }
}
