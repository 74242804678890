import { Pipe, PipeTransform } from '@angular/core';
import { SourceImpact } from '../service/api-model-impact';
import { ImpactSourceType } from '../models';

@Pipe({ name: 'impactSourceType' })
export class ImpactSourceTypePipe implements PipeTransform {
    transform(source: SourceImpact): ImpactSourceType {
        const bbox = source.bbox.map((v) => +v.toFixed(2));
        if (bbox[0] === bbox[2] && bbox[1] === bbox[3]) {
            return 'point';
        } else {
            return 'square';
        }
    }
}
